<template>
<div>
    <v-btn v-if="user !== undefined" class="d-inline d-sm-none" small icon @click="logOut">
        <!--v-icon small>fal fa-sign-out</v-icon-->
        <v-img src="/img/icon/odhlasit.png" max-height="24px" contain></v-img>
    </v-btn>
    <v-btn v-if="user !== undefined" class="d-none d-sm-inline" plain @click="logOut">
        <v-img src="/img/icon/odhlasit.png" class="mr-2" max-height="24px" contain></v-img>

        {{ $t('Odhlásit se') }}
    </v-btn>
</div>
</template>

<script>
export default {
    name: "LogOut",
    computed: {
        user() {
            return this.$store.state.user;
        },
    },

    methods: {
        logOut() {
            this.$store.dispatch("logOut");
            this.$router.push({ name: "Home" });
        },
    },
};
</script>

<style>
</style>