<template>
    <v-btn style="padding-right: 0;" v-if="user !== undefined" plain :to="{ name: 'Profile' }" exact>
        <span class="d-sm-inline d-none">{{ user.nickName }}</span> 
        
        <v-avatar class="user ml-0 ml-sm-3" size="30" color="primary" :class="{ woman: user.sex === 'female', man: user.sex !== 'female' }">
            <v-img v-if="user.profilePicture" :src="profilePictureSource"></v-img>
        </v-avatar>
    </v-btn>
</template>

<script>
import { URL } from "@/store/index";

export default {
    name: "ProfileButton",
    computed: {
        user() {
            return this.$store.state.user;
        },

        profilePictureSource() {
            return URL + this.user.profilePicture;
        }
    },
};
</script>

<style>
</style>