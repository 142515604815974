<template>
    <v-container>
        <v-card>
            <v-card-title class="d-flex justify-space-between">
                <span>{{ $t('Kontakt') }}</span>

                <v-btn text icon :to="{ name: 'Home' }" exact>
                    <v-icon> fal fa-times </v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text v-if="lang == 'cs'">
                <p>Máte dotazy ohledně fungování e-see-you?
                </p>
                <p>Máte tipy na zlepšení fungování e-see-you?</p>
                <p>Zaregistrovali jste porušení pravidel e-see-you jako například falšené profily, zneužití
                    uživatelských fotografií apod?</p>
                <p>Všechny tyto dotazy směrujte prosím na adresu info(zavinač)e-see-you.cz (<a @click="copy">kopírovat
                        e-mail do schránky</a>).</p>
            </v-card-text>

            <v-card-text v-if="lang == 'en'">
                <p>Do you have questions about how e-see-you works?
                </p>
                <p>Do you have tips to improve the functioning of e-see-you?</p>
                <p>You have registered a violation of e-see-you rules such as fake profiles, abuse
                    user photos etc?</p>
                <p>Please direct all these questions to the address info(at)e-see-you.cz (<a @click="copy">copy mail to clipboard</a>).</p>
            </v-card-text>

        </v-card>
    </v-container>
</template>
  
<script>
export default {
    name: 'Contact',

    data: (() => ({
        lang: localStorage.getItem('language')
    })),
    created() {
        // Na prase ale to nikdo nepozná :D
        setInterval(() => {
            let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'cs';
            this.lang = lang;
        }, 1);
    },

    methods: {
        copy() {
            navigator.clipboard.writeText('info@e-see-you.cz');
            this.$root.$emit('notification', {
                message: this.$t("Úspěšně zkopírováno do schránky!"),
                duration: 6
            });
        }
    }
}
</script>