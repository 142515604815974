<template>
  <v-container style="height: 50vh;">
    <div style="margin: 0 12px;">
        <span class="text-h6 font-weight-black">{{ $t('Nastavení') }}</span>
        <v-select
            class="mt-2"
            v-model="selectedLanguage"
            :items="languages"
            item-text="text"
            item-value="value"
            :label="$t('Jazyk')"
            @change="changeLanguage"
        ></v-select>
        <v-btn text style="width: 100%; border: 1px solid black; color: black; margin-top: 12px;" :to="{ name: 'Terms' }">{{ $t('Všeobecné podmínky') }}</v-btn>
        <v-btn text  style="width: 100%; border: 1px solid black; color: black; margin-top: 12px;" :to="{ name: 'Contact' }">{{ $t('Kontakt') }}</v-btn>
        <v-btn text  style="width: 100%; border: 1px solid red; color: red; margin-top: 12px;" @click="logOut">{{ $t('Odhlásit se') }}</v-btn>
    </div>
  </v-container>
</template>

<script>
import { URL } from "@/store/index";
import LogOut from "../components/layout/LogOut.vue";

export default {
  name: "Settings",
  components: { LogOut },
  data() {
    return {
        languages: [
            { text: 'Čeština', value: 'cs' },
            { text: 'English', value: 'en' },
            // Add more languages as needed
        ],
        selectedLanguage: '',
        currentLanguage: 0
    };
  },

  created() {
    // Set the initial value of selectedLanguage from localStorage
    const storedLanguage = localStorage.getItem('language');
    this.selectedLanguage = storedLanguage ? storedLanguage : this.languages[0].value;
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  methods: {
    getPictureSource(picture) {
      return URL + picture;
    },
    changeLanguage() {
      this.$i18n.locale = this.selectedLanguage;
      localStorage.setItem('language', this.selectedLanguage);
    },
    logOut() {
        this.$store.dispatch("logOut");
        this.$router.push({ name: "Home" });
    }
  },
};
</script>

<style>

</style>