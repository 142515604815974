<template>
    <transition-group tag="div" name="list-complete" class="notifications">
        <div class="notification list-complete-item" :class="'n-' + notification.type" v-for="notification in visibleNotifications" :key="notification.id">
            <div class="man"></div>
            <p>
                {{ notification.message }}
            </p>
            <button class="close" @click="close(notification.id)"><i class="fal fa-times"></i></button>
        </div>
    </transition-group>
</template>

<script>
export default {
    name: "Notifications",
    data() {
        return {
            visibleNotifications: [],

            waitingNotifications: [],
        }
    },

    mounted() {
        this.$root.$on('notification', (data) => {
            const notification = {
                id: Math.random().toString(36).substr(2, 9),
                message: data.message,
                duration: data.duration,
                type: data.type || "success",
                removeAt: new Date().getTime() + data.duration * 1000,
            };

            if (this.visibleNotifications.length < 3) {
                this.visibleNotifications.push(notification);
            } else {
                this.waitingNotifications.push(notification);
            }
        });

        setInterval(() => {
            this.visibleNotifications = this.visibleNotifications.filter((notification) => {
                if (notification.removeAt < new Date().getTime()) {
                    console.log("Removing notification")
                    return false;
                }

                return true;
            });

            while (this.visibleNotifications.length < 3 && this.waitingNotifications.length > 0) {
                const nextNotification = this.waitingNotifications.shift();
                nextNotification.removeAt = new Date().getTime() + nextNotification.duration * 1000;

                this.visibleNotifications.push(nextNotification);
                console.log("Adding next notification from next queue");
            }
        }, 300);
    },

    methods: {
        close(id) {
            this.visibleNotifications = this.visibleNotifications.filter((notification) => {
                if (notification.id === id) {
                    return false;
                }

                return true;
            });
        }
    }
};
</script>

<style lang="scss">
.notifications {
    padding: 0;
    position: fixed;
    bottom: 65px;
    z-index: 300000;
    right: 5px;

    width: 350px;
    height: auto;

    display: flex;
    flex-direction: column-reverse;
    gap: 25px;

    .notification {
        position: relative;
        background-color: rgba(0, 0, 0, 0.80);
        color: white;
        font-weight: 500;
        border-radius: 0px;
        height: 100px;
        padding-left: 140px;
        display: flex;
        align-items: center;

        &.n-success {
            color: #809419;
        }

        &.n-error {
            color: #f15b5b;
        }

        .man {
            position: absolute;
            top: -30px;
            left: 0px;

            background-image: url("/img/man-notification.png");
            background-size: contain;
            background-repeat: no-repeat;
            width: 130px;
            height: 130px;
        }

        p {
            margin-bottom: 0;
            padding-right: 40px;
        }

        button.close {
            position: absolute;
            top: 0;
            right: 0;
            background-color: rgb(39, 39, 39);
            border: none;
            padding: 10px;
            cursor: pointer;
            width: 40px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                font-size: 18px;
                color: #fff;
            }

            transition: 0.2s ease-in-out all;

            &:hover {
                background-color: rgb(29, 29, 29);
                transition: 0.2s ease-in-out all;
            }
        }
    }
}

.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
  position: absolute;
}
.list-complete-enter {
  opacity: 0;
  transform: translateY(300px);
}
.list-complete-leave-to {
    opacity: 0;
    transform: translateX(300px);
}
.list-complete-leave-active {
  position: absolute;
}

@media (max-width: 992px) {
    .notifications {
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
    }
    .notification {
        background-color: black !important;
        margin-right: 0;
    }
}
</style>