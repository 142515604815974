<template>
    <div class="pa-0 height-100">
        <v-row class="height-100 remove-row" align="center">
            <v-col cols="12" md="3" class="side-container white moveable">
                <!--
                <v-row class="side remove-row">
                    <v-col cols="12">
                        <p>
                            Filtrujte oblast vyhledávání na zobrazné mapě a
                            zároveň i termíny setkání osob.
                        </p>
                        <p>
                            Pro zobrazení pokročilých možností vyhledávání se přihlašte.
                        </p>
                    </v-col>
                </v-row> -->

                <v-row v-if="!$vuetify.breakpoint.mdAndUp" class="side remove-row esy-box" style="margin-bottom: 24px;">
                    <v-col cols="12">
                        <p class="label date">{{ $t('Kde') }}</p>
                    </v-col>

                    <v-col cols="12">
                        <p class="mb-1">{{ $t('Místo setkání') }}:</p>
                        <v-autocomplete :placeholder="$t('Zadejte adresu')" :value="value" :items="virtualItems"
                                        :search-input.sync="searchQuery" :loading="loading"
                                        :hide-no-data="loading || !searchQuery"
                                        item-text="main_text" item-value="place_id" return-object outlined
                                        hide-details no-filter
                                        :no-data-text="$t('Žádné výsledky')" @input="selected"
                                        background-color="white">
                            <template v-slot:item="data">
                                <template>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.main_text"></v-list-item-title>
                                        <v-list-item-subtitle
                                                v-html="data.item.secondary_text"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>

                <v-row class="side remove-row esy-box">

                    <v-col cols="12">
                        <p class="label date">{{ $t('Od') }}:</p>
                    </v-col>

                    <v-col cols="12" md="12" class="py-0">
                        <p class="mb-1">{{ $t('Termín setkání') }}:</p>
                        <v-menu ref="menu" v-model="menu" :close-on-content-click="true" transition="scale-transition"
                            offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field placeholder="12. 10. 2017" readonly :value="formatDate" outlined
                                    v-bind="attrs" hide-details v-on="on" background-color="white">
                                </v-text-field>
                            </template>
                            <v-date-picker v-model="date" :max="
    new Date(
        Date.now() -
        new Date().getTimezoneOffset() *
        60000
    )
        .toISOString()
        .substr(0, 10)
" min="1950-01-01"></v-date-picker>
                        </v-menu>
                    </v-col>
                    
                    <v-col cols="12" class="py-0 pt-3 pt-md-2">
                        <p class="mb-1">{{ $t('Čas setkání') }}:</p>
                        <v-row no-gutters>
                            <v-col cols=12 md=7 class=pa-0>
                                <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :return-value.sync="time"
                                    transition="scale-transition" offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="time" placeholder="12 : 32" readonly outlined v-bind="attrs"
                                            v-on="on" background-color="white" hide-details></v-text-field>
                                    </template>
                                    <v-time-picker v-if="menu2" v-model="time" format="24hr"
                                        @click:minute="$refs.menu2.save(time)">
                                    </v-time-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row class="side remove-row esy-box">

                    <v-col cols="12">
                        <p class="label date">{{ $t('Do') }}:</p>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <p class="mb-1">{{ $t('Termín setkání') }}:</p>
                        <v-menu ref="menu" v-model="menu3" :close-on-content-click="true" transition="scale-transition"
                            offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field placeholder="12. 10. 2017" readonly :value="formatDate2" outlined
                                    v-bind="attrs" hide-details v-on="on" background-color="white">
                                </v-text-field>
                            </template>
                            <v-date-picker v-model="date2" :max="
    new Date(
        Date.now() -
        new Date().getTimezoneOffset() *
        60000
    )
        .toISOString()
        .substr(0, 10)
" min="1950-01-01"></v-date-picker>
                        </v-menu>
                    </v-col>
                   
                    <v-col cols="12" class="py-0 pt-3 pt-md-2">
                        <p class="mb-1">{{ $t('Čas setkání') }}:</p>
                        <v-row no-gutters>
                            <v-col cols=12 md=7 class=pa-0>
                                <v-menu ref="menu4" v-model="menu4" :close-on-content-click="false" :return-value.sync="time2"
                                    transition="scale-transition" offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="time2" placeholder="12 : 32" readonly outlined v-bind="attrs"
                                            v-on="on" background-color="white" hide-details></v-text-field>
                                    </template>
                                    <v-time-picker v-if="menu4" v-model="time2" format="24hr"
                                        @click:minute="$refs.menu4.save(time2)">
                                    </v-time-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row style="margin: 0; padding: 0;">
                    <v-col cols="12" style="padding: 24px; padding-top: 12px; padding-bottom: 72px;">
                        <v-btn style="width: 100%;" block color="green" dark x-large class="font-weight-bold"
                            @click="search">
                            {{ $t('Vyhledat') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="d-none d-md-block height-100 map-side pa-0 attachTo" cols="12" md="9" ref="map-holder">
                <div ref="map" class="map-holder"></div>
            </v-col>

            <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                <v-card class="attachToMobile">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>{{
                                hideTip ? $t('Výsledky hledání') : $t('Vyberte oblast hledání')
                            }}
                        </v-toolbar-title>

                        <v-spacer></v-spacer>
 
                        <v-btn icon color="white" @click="dialog = false;">
                            <v-icon>fal fa-times</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <div class="h-100 map-overlay" ref="map-dialog"></div>

                    <div v-if="hideTip === false"
                         style="background: rgba(0, 0, 0, .75); color: white; padding: 12px; position: absolute; top: 56px; left: 0; width: 100%; z-index: 999;">
                        {{
                            $t('Po potvrzení vyhledávání se zobrazí výsledky pouze pro tu oblast, kterou nyní vidíte a nastavíte.')
                        }}
                    </div>

                    <div v-if="!searchDialog" style="bottom: 50px; left: 0; position: absolute; z-index: 999;">
                        <v-btn color="success" dark absolute bottom left fab @click="dialog = false" elevation="8">
                            <v-icon>fal fa-check</v-icon>
                        </v-btn>
                    </div>

                    <!--
                    <div style="bottom: 50px; left: 0; position: relative">
                        <v-btn color="success" dark absolute bottom left fab @click="dialog = false" elevation="8">
                            <v-icon>fal fa-check</v-icon>
                        </v-btn>
                    </div> -->
                </v-card>
            </v-dialog>

            <!-- NOVÝ DIALOG -->
            <v-dialog v-model="searchDialog" :fullscreen="!$vuetify.breakpoint.mdAndUp" persistent hide-overlay scrollable max-width="550px"
                      :attach="$vuetify.breakpoint.mdAndUp ? '.attachTo' : '.attachToMobile'">
                <v-card v-if="searchInDialog && holderMode === 'search'">
                    <v-card-title
                            class="white--text pa-2"
                            :class="{
                            blue: searchInDialog.user.sex != 'female',
                            red: searchInDialog.user.sex == 'female',
                          }"
                          style="display: flex; flex-wrap: nowrap; width: 100%;"
                    >
                        <div style="display: flex; align-items: center; width: calc(100% - 36px);">
                            <v-avatar size="64" color="green" class="mr-3 user"
                                      :class="{ woman: searchInDialog.user.sex === 'female', man: searchInDialog.user.sex !== 'female' }">
                                <v-img
                                        v-if="searchInDialog.user.profilePicture"
                                        :src="getPictureSource(searchInDialog.user.profilePicture)"
                                ></v-img>
                            </v-avatar>
                            <span style="width: 100%; display: block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                                {{ holderMode == 'search' ?  searchInDialog.user.nickName : 'Zpět na hledání' }}
                            </span>
                        </div>

                        <v-spacer></v-spacer>

                        <v-btn icon color="white" @click="searchDialog = false">
                            <v-icon>fal fa-times</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-divider></v-divider>

                    <!--
                    <v-card-title class="px-5 text-body-1">
                        <v-row>
                            <v-col cols="8">
                                <p class="mb-1"><b>{{ $t('Místo setkání') }}</b></p>

                                <p>{{ searchInDialog.address }}</p>
                            </v-col>
                            <v-col cols="4">
                                <p class="mb-1"><b>{{ $t('Datum setkání') }}</b></p>

                                <p>{{ searchInDialog.seenAt.fromNow() }}</p>
                            </v-col>
                        </v-row>
                    </v-card-title> -->
                    <v-card-text style="height: 300px;" class="px-5 text-body-1 moveable">
                        <p class="mt-4 mb-1"><b>{{ $t('Místo setkání') }}</b></p>
                        <p>{{ searchInDialog.address }}</p>
                        <p class="mb-1"><b>{{ $t('Datum setkání') }}</b></p>
                        <p>{{ searchInDialog.seenAt.fromNow() }}</p>

                        <p class="mb-1"><b>{{ $t('Popis protějšku') }}</b></p>

                        <p>{{ searchInDialog.description }}</p>


                        <p class="mb-1"><b>{{ $t('Situace při setkání') }}</b></p>

                        <p>{{ searchInDialog.situation }}</p>


                        <p class="mb-1"><b>{{ $t('Zpráva') }}</b></p>

                        <p>{{ searchInDialog.message }}</p>

                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="align-center esy-popup-actions">
                        <p class="mb-0"><small>{{ $t('Počet zobrazení') }}: {{ searchInDialog.views }}</small></p>
                        <v-spacer></v-spacer>

                        <v-btn color="green" large class="font-weight-black response-button" @click="logIn">
                            {{ $t('Odpovědět na hledání') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>

                <v-card v-if="holderMode === 'profile'">
                    <v-card-title
                            class="white--text pa-2"
                            :class="{
                            blue: searchInDialog.user.sex != 'female',
                            red: searchInDialog.user.sex == 'female',
                        }"
                    >

                        <div class="clickable-holder" @click="holderMode = holderMode == 'search' ? 'profile' : 'search'">
                        <v-avatar size="64" color="green" class="mr-3 user"
                                  :class="{ woman: searchInDialog.user.sex === 'female', man: searchInDialog.user.sex !== 'female' }">
                            <v-img
                                    v-if="searchInDialog.user.profilePicture"
                                    :src="getPictureSource(searchInDialog.user.profilePicture)"
                            ></v-img>
                        </v-avatar>
                        <span>{{ holderMode == 'search' ? searchInDialog.user.name.replace(/\([^)]*\)/g, '').trim() : 'Zpět na hledání' }}</span>
                        </div>

                        <v-spacer></v-spacer>
                        <!--
                        <v-btn style="margin-right: 12px; color: white; background: transparent; box-shadow: none;" variant="text" @click="holderMode = 'search'">
                            {{ $t('Zpět na hledání') }}
                        </v-btn> -->
                        <v-btn icon color="white" @click="searchDialog = false">
                            <v-icon>fal fa-times</v-icon>
                        </v-btn>
                    </v-card-title>

          
                        <v-container style="padding: 20px;">
             
                            <v-list>
                                <v-list-item style="padding:0; min-height: 32px;">
                                    <v-list-item-title>
                                        <b>{{ $t('Pohlaví') }}: </b> {{ searchInDialog.user.sex === "female" ?
                                        $t('žena') :  $t('muž')
                                        }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item style="padding:0; min-height: 32px;">
                                    <v-list-item-title>
                                        <b>{{ $t('Jméno') }}: </b> {{ searchInDialog.user.name }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item style="padding:0; min-height: 32px;">
                                    <v-list-item-title>
                                        <b>{{ $t('Věk') }}: </b> {{ today.diff(searchInDialog.user.birthDate,
                                        "year")
                                        }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item style="padding:0; min-height: 32px;">
                                    <v-list-item-title>
                                        <b>{{ $t('Bydliště') }}: </b> {{ searchInDialog.user.city }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item style="padding:0; min-height: 32px;">
                                    <v-list-item-title>
                                        <b>{{ $t('Aktuální stav') }}: </b>
                                        <v-chip color="green">{{ $t('Aktivní') }}</v-chip>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                     

                            <v-row class="my-0 mt-3 py-0">
                                <v-col cols="12" sm="auto" class="py-0">
                                    <p class="mb-0"><b>{{ $t('Členem od') }}: </b> {{
                                            searchInDialog.user.createdAt.format("DD. MM. YYYY HH:mm:ss")
                                        }}
                                    </p>
                                </v-col>
                                <v-col cols="12" sm="auto" class="py-0">
                                    <p class="mb-0">
                                        <b>{{ $t('Poslední přihlášení') }}: </b>
                                        {{ searchInDialog.user.lastAuth.format("DD. MM. YYYY HH:mm:ss") }}
                                    </p>
                                </v-col>
                            </v-row>
                        </v-container>
          

                    <v-divider />

                    <v-card-text>
                        <v-container style="height: 100%; padding-top: 20px; padding-left: 0; padding-right: 0;">
                            <div class="moveable" style="height: 100%;">
                                <div class="image-holder" style="width: 80px; height: 80px; float: left; margin-right: 6px; margin-bottom: 6px;">
                                    <img @click="zoomedImage = getPictureSource(searchInDialog.user.profilePicture); imageDialog = true;" style="object-fit: cover;" :src="getPictureSource(searchInDialog.user.profilePicture)">
                                </div>

                                <div class="image-holder" style="width: 80px; height: 80px; float: left; margin-right: 6px; margin-bottom: 6px;" v-for="(picture, i) in searchInDialog.user.pictures" :key="i">
                                    <img @click="zoomedImage = getPictureSource(picture); imageDialog = true;" style="object-fit: cover;" :src="getPictureSource(picture)">
                                </div>
                            </div>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
            
        </v-row>
        <!-- Obrázek -->
        <v-dialog v-model="imageDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card class="esy-card-parent">
                <v-toolbar dark color="black" class="esy-card-toolbar">
                    <v-toolbar-title>{{ $t('Obrázek') }}</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn @click="zoomedImage = null; imageDialog = false;" icon color="white">
                        <v-icon>fal fa-times</v-icon>
                    </v-btn>
                </v-toolbar>

                <div class="esy-card-body h-100" style="display: flex; align-items: center; justify-content: center;">
                    <img :src="zoomedImage" style="background-color: black; object-fit: contain;">
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment';
import {URL} from "@/store/index";

export default {
    name: "SearchNewLimited",

    computed: {
        user() {
            return this.$store.state.user;
        },
        formatDate() {
            return this.date
                ? moment(this.date).format("D. M. YYYY")
                : "";
        },
        formatDate2() {
            return this.date2
                ? moment(this.date2).format("D. M. YYYY")
                : "";
        },
        virtualItems() {
            if (this.items.length === 0 && this.value) {
                return [this.value];
            } else {
                return this.items;
            }
        },
    },

    data: () => ({
        today: moment(),
        zoomedImage: null,
        imageDialog: false,

        date: moment().subtract(4, 'days').format("YYYY-MM-DD"),
        time: "00:00",
        menu: undefined,
        menu2: undefined,

        date2: moment().format("YYYY-MM-DD"),
        time2: "23:59",
        menu3: undefined,
        menu4: undefined,

        dialog: false,

        map: undefined,
        markers: [],

        searchInDialog: undefined,
        searchDialog: false,

        holderMode: 'search',

        autocomplete: undefined,
        geocoder: undefined,
        items: [],
        searchQuery: "",
        value: "",
        loading: false,
        hideTip: false,
    }),

    mounted() {
        if (this.user) {
            this.$router.push({ name: "Homepage" });
            return;
        }

        setTimeout(() => {
            const map = new google.maps.Map(this.$refs.map, {
                zoom: 12,
                center: {
                    lat: 50.0743105,
                    lng: 14.4163701,
                },
                streetViewControl: false,
                disableDefaultUI: true,
                gestureHandling: "greedy",
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                    {
                        "featureType": "poi",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    /*
                    {
                        "featureType": "administrative",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    }, */
                    {
                        "featureType": "landscape.natural",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                ]
            });

            this.map = map;
            this.autocomplete =
                new window.google.maps.places.AutocompleteService();
            this.geocoder = new window.google.maps.Geocoder();
        }, 600);
    },

    methods: {

        selected(item) {
            if (!item) return;

            this.hideTip = false;
            
            // Odmaže markery
            this.markers.forEach(marker => marker.setMap(null));
            this.markers = [];

            const place_id = item.place_id;
            this.geocoder.geocode({placeId: place_id}, (data) => {
                const location = data[0].geometry.location;

                if (this.$vuetify.breakpoint.mdAndDown) this.dialog = true;
                const loc = {
                    lat: location.lat(),
                    lng: location.lng(),
                };

                this.map.setCenter(loc);
                this.marker.setPosition(loc);
                this.map.setZoom(16);
            });
        },
        
        getPictureSource(picture) {
            return URL + picture;
        },

        
        async search() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.dialog = true;
            }

            this.hideTip = true;

            const bounds = this.map.getBounds() ? this.map.getBounds().toJSON() : {};

            const searches = await this.$store.dispatch("loadSearch", {
                lan: this.map.getCenter().lat(),
                lon: this.map.getCenter().lng(),
                radius: this.map.getBounds() ? Math.max(
                    1,
                    Math.abs(bounds.north - bounds.south) / 2,
                    Math.abs(bounds.east - bounds.west) / 2
                ) : 1,
                from: moment(
                    `${this.date} ${this.time}`,
                    "YYYY-MM-DD HH:mm"
                ).toISOString(),
                to: moment(
                    `${this.date2} ${this.time2}`,
                    "YYYY-MM-DD HH:mm"
                ).toISOString()
            });

            if (searches.length == 0)
                this.$root.$emit('notification', {
                    message: this.$t("Bohužel nebylo nic nalezeno."),
                    duration: 5
                });

            this.markers.forEach(marker => marker.setMap(null));
            this.markers = [];

            for (const s of searches) {
                const search = {
                    ...s,
                    seenAt: moment(s.seenAt),
                    user: {
                        ...s.user,
                        birthDate: moment(s.user.birthDate),
                        createdAt: moment(s.user.createdAt),
                        lastAuth: moment(s.user.lastAuth),
                    }
                };
                const marker = new google.maps.Marker({
                    position: {
                        lat: search.lan,
                        lng: search.lon,
                    },
                    map: this.map,
                    icon:
                        search.searchGender === "female" // Prohozeny markery
                            ? "/img/map-marker-men.png"
                            : "/img/map-marker.png",
                });

                this.markers.push(marker);

                marker.addListener("click", () => {
                    this.searchDialog = true;
                    this.searchInDialog = search;
                });
            }
        },

        async logIn() {
            this.$root.$emit("openLoginDialog", false);
        },
    },

    watch: {
        dialog: function () {
            this.$nextTick(() => {
                if (this.dialog) {
                    // move this.$refs.map element to this.$refs.map-dialog
                    this.$refs["map-dialog"].innerHTML = "";
                    this.$refs["map-dialog"].append(this.map.getDiv());
                } else {
                    this.$refs["map-holder"].innerHTML = "";
                    this.$refs["map-holder"].append(this.map.getDiv());
                }
            });
        },
        user: async function(oldValue, newValue) {
            if (oldValue) {
                if(!this.searchInDialog) return;

                // Create chat
                this.$nextTick(async () => {
                    await this.$store.dispatch("createChat", {
                        message: "Ahoj!",
                        search: this.searchInDialog.id,
                    });
                    this.$router.push({ name: 'ChatList' });
                });
            }
        },
        searchQuery: function () {
            if (!this.searchQuery || this.searchQuery.trim().length === 0) return;
            if (this.addressTimeout) clearTimeout(this.addressTimeout);

            this.addressTimeout = setTimeout(() => {
                this.loading = true;
                this.autocomplete.getPlacePredictions(
                    {
                        input: this.searchQuery,
                    },
                    (prediction, status) => {
                        if (status === "OK") {
                            this.items = prediction.map((item) => {
                                return {
                                    main_text:
                                    item.structured_formatting.main_text,
                                    secondary_text:
                                    item.structured_formatting
                                        .secondary_text,
                                    place_id: item.place_id,
                                };
                            });
                        }

                        this.loading = false;
                    }
                );
            }, 500);
        }
    },
};
</script>

<style scoped lang="scss">
.v-dialog__container {
    display: unset;
}

.v-dialog__content {
    position: absolute;
}

.side-container, .map-side {
    height: calc(100vh - 48px);
}

.esy-box {
    background: #EAEAEA;
    padding: 30px 20px !important;
    margin: 24px 24px !important;
    border-radius: 8px;
}

.map-side {
    position: relative;
    height: calc(100vh - 48px);

    .backToPosition {
        position: absolute;
        bottom: 60px;
        left: 20px;
    }
}

.moveable {
    overflow-x: auto;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #f3f3f3;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #7a9704;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.side-container {
    padding: 0;
    //padding-bottom: 45px;
    //height: calc(100vh - 48px);
    height: calc(100vh - 48px) !important;
    overflow-y: auto;
    //height: 100%;
    //display: flex;
    flex-direction: column;
    justify-content: center;

    >.row {
        display: flex;
        flex-grow: 0;
        ;
    }

    >* {
        padding: 12px 24px;
    }

    > :not(.moveable) {
        flex-shrink: 0;
    }
}

.remove-row {
    margin: 0;
}

.side .col-12 {
    padding: 5px;
}

.side p {
    font-size: 1em;
    text-align: left;
}

.height-100 {
    min-height: 100%;
}

.map-holder {
    height: calc(100vh - 48px) !important;
    cursor: url("/img/cross.png"), pointer;
}

.v-dialog .map-holder {
    height: calc(100vh - 56px);
}

.v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
    background-color: transparent !important;
}

.map-icon {
    width: 30px;
    height: 30px;
    margin-left: 10px;
}

.label {
    font-size: 18px !important;
    font-weight: 700;
    line-height: 1.25rem;
    letter-spacing: 0.0178571429em !important;
    font-family: "Catamaran", sans-serif !important;
    margin-bottom: 10px !important;

    &.date {
        margin-bottom: 0px !important;
    }
}



.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;

    display: flex;
    justify-content: center;
    align-items: center;

    .size-0 {
        position: absolute;
        width: 90%;
        height: 90%;

        border: 9px solid red;
    }

    .size-1 {
        position: absolute;
        width: 85%;
        height: 85%;

        border: 6px solid red;
    }

    .size-2 {
        position: absolute;
        width: 80%;
        height: 80%;

        border: 3px solid red;
    }
}

.esy-popup-actions {
    flex-direction: column;
    padding-bottom: 24px !important;
    gap: 6px;
}

.esy-popup-actions button {
    width: 100%;
}
</style>