<template>
    <v-container>
        <v-card>
            <v-card-title class="d-flex justify-space-between">
                <span>{{ $t('Podmínky užití') }}</span>

                <v-btn text icon :to="{ name: 'Home' }" exact>
                    <v-icon> fal fa-times </v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="moveable">
                <!-- CS: -->
                <div class=WordSection1 v-if="lang == 'cs'">

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal'><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'>(Naposledy
aktualizováno dne 14. července 2023)<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>1. Webové stránky a Související aplikace</span></b><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>1.1 Přijetí podmínek používání</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Používání těchto Webových stránek a Související aplikace je podmíněno
souhlasem s těmito Podmínkami používání a také s naším&nbsp;</span><a
href="https://www.internationalcupid.com/cs/general/privacystatement"
target="_blank"><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS;text-decoration:none;text-underline:none'>Prohlášením o ochraně soukromí</span></a><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'>. Dalším používáním
těchto Webových stránek a Související aplikace přijímáte tyto Podmínky
používání a naše Prohlášení o ochraně soukromí, bez ohledu na to, zda se
zaregistrujete jako člen/ka. Tyto Podmínky používání a naše Prohlášení o
ochraně soukromí můžeme kdykoli změnit. Pokud tak učiníme, na Webových
stránkách nebo v&nbsp;Související aplikaci, bude zveřejněna aktualizovaná verze
těchto Podmínek používání a Prohlášení o ochraně soukromí. Je Vaší povinností
pravidelně nahlížet na tyto Podmínky používání a Prohlášení o ochraně soukromí.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Pokud nesouhlasíte s těmito Podmínkami používání a naším Prohlášením o
ochraně soukromí, není Vám povoleno tyto Webové stránky a Související aplikaci používat.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><span style='mso-spacerun:yes'> </span>1.2 Uživatelská dohoda</span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Tyto Podmínky používání představují dohodu mezi Vámi a Společností
E-see-you s.r.o. v souvislosti s Vaším využíváním Služby a Webových stránek (dále
jen „Dohoda“).<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>1.3 Kontaktní údaje</span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>E-see-you.cz (.com) (<b>Webové stránky a Související Aplikace</b>) vlastní
a provozuje E-see-you s.r.o. registrována v&nbsp;České republice, IČO 180 39 413.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS;mso-bidi-font-weight:bold'>E</span><span style='font-size:10.5pt;font-family:
"Cambria Math",serif;mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:
"Cambria Math";color:#505656;mso-fareast-language:CS;mso-bidi-font-weight:bold'>‐</span><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS;mso-bidi-font-weight:
bold'>mailová adresa:&nbsp;</span><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
CS'>info@e-see-you.cz<span style='color:#505656'><o:p></o:p></span></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-fareast-language:CS;mso-bidi-font-weight:
bold'>Telefonní číslo:&nbsp;</span><a href="tel:+61%207%205571%201181"><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:windowtext;mso-fareast-language:CS;text-decoration:
none;text-underline:none'>+420&nbsp;702&nbsp;105 663</span></a><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>2. Členství</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>2.1 Způsobilost</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Abyste se mohli zaregistrovat jako člen či členka Služby nebo používat tyto
Webové stránky nebo Související aplikaci, musíte být starší 18ti let nebo starší
15ti let se souhlasem zákonného zástupce. Používáním těchto Webových stránek nebo
Související aplikace prohlašujete, zavazujete se a zaručujete se, že máte
právo, pravomoc a schopnost uzavřít tuto Dohodu a dodržovat Podmínky používání
v plném rozsahu.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>2.2 Vznik dohody</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Registrací jako člen či členka uzavíráte se Společností Dohodu týkající se
používání Webových stránek a Související aplikace.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>2.3 Členství</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(a)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;Členem Služby se stáváte bezplatně.<br>
<b>(b)</b>&nbsp;Berete na vědomí a souhlasíte s tím, že za účelem ochrany
uživatelů těchto webových stránek a Související aplikace nemůžete požádat o
členství ani se stát členem, pokud jste byli odsouzeni za trestný čin nebo
zločin. Žádostí o členství se zaručujete a prohlašujete, že jste nebyli
odsouzeni za trestný čin ani zločin, a že nejste žádným vládním orgánem
registrováni jako sexuální delikventi.<b><o:p></o:p></b></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>2.4 Bezpečnost hesla</span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>V rámci registrace členství po Vás bude požadován výběr hesla. Jste plně
odpovědni za výběr hesla, které nelze snadno uhádnout, a za uchování hesla v
bezpečí. Souhlasíte s tím, že nepřevedete ani neprodáte své přístupové údaje na
Webové stránky a Související aplikace, žádné třetí straně. Pokud máte důvod
domnívat se, že Váš účet již není zabezpečený, je nutné, abyste nám tuto
skutečnost neprodleně oznámili a ihned si nastavili nové heslo.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>2.5 Ověření identity</span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Čas od času můžeme požádat o ověření:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(a)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;Vaší totožnosti;<br>
<b>(b)</b>&nbsp;Vámi poskytnutých informací;<br>
<b>(c)</b>&nbsp;že dodržujete naše Podmínky používání.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>3. Služba</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Služba je internetová informační služba umožňující kontakt mezi členy,
kteří mohou či nemusí hledat přátelství nebo vztah. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4. Používání služby</span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.1 Vlastní nebezpečí</span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Berete na vědomí, že používání Služby, Webových stránek a Související
aplikace je výhradně na Vaše vlastní nebezpečí.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.2 Přesné informace</span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Prohlašujete, zaručujete a zavazujete se, že informace a fotografie, které poskytujete
Službě, Webovým stránkám, Související aplikaci a Společnosti, jsou ve všech
ohledech přesné, neporušují tuto Dohodu a ani nijak nepoškozují žádné další
osoby.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Na naše Webové stránky a Související aplikaci nesmíte nahrávat ani na nich
zveřejňovat žádné fotografie, které zobrazují:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>•</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;někoho jiného než Vás;<br>
<b>•</b>&nbsp;nahotu;<br>
<b>•</b>&nbsp;předměty, které se Vás netýkají;<b><o:p></o:p></b></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.3 Nedůvěrné údaje</span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Souhlasíte s tím, že všechny materiály nebo informace, které nám
poskytujete, včetně Osobních údajů, budou použity za účelem funkce Služby.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.4 Informace dostupné v zahraničí</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(a)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;Všechny profilové informace, které jste nám poskytli, budou na Vašem
profilu veřejně přístupné, a to bez ohledu na polohu prohlížejícího. Vytvořením
profilu berete na vědomí, že si uživatelé ze zahraničí budou moci prohlížet Váš
profil.<br>
<b>(b)</b>&nbsp;Společnost využívá servery pro ukládání Vašich Osobních údajů,
které jsou umístěny v&nbsp;české republice.<br>
<b>(c)</b>&nbsp;Za účelem poskytování zákaznické podpory, provádění úkolů back
office, předcházení podvodům, či poskytování služeb pro Vás, je možné, že
budeme muset povolit našim zaměstnancům přístup k Vašim Osobním údajům.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.5 Obsah profilu</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(a)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;Nesete výhradní odpovědnost za obsah svého profilu, zprávy,
fotografie a další materiály, které můžete nahrávat do Služby.<br>
<b>(b)</b>&nbsp;Souhlasíte s tím, že na svůj profil na Webových stránkách nebo
Související aplikaci nebudete nahrávat ani zveřejňovat žádný obsah, který:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(i)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;je urážlivý, obscénní, znesvěcený, urážlivý, sexuálně orientovaný,
výhružný, obtěžující, rasově urážlivý, nemístný nebo jinak nevhodný na základě
našeho vlastního uvážení; nebo<br>
<b>(ii)</b>&nbsp;zobrazuje, popisuje, identifikuje nebo zmiňuje jinou osobu,
než jste Vy.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.6 Negativní závazky</span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Prohlašujete, zaručujete a zavazujete se, že:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(a)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;nikomu neposkytnete informace, které Vám byly poskytnuty
prostřednictvím Služby, bez předchozího souhlasu osoby, která Vám je poskytla;<br>
<b>(b)</b>&nbsp;Službu nebudete používat k žádné formě obtěžování nebo
urážlivého chování, včetně, ale nikoliv výlučně, distribuce jakéhokoli sexuálně
a/nebo rasově urážlivého, zneužívajícího, vyhrožujícího, vulgárního,
obscénního, obtěžujícího, urážlivého, ohavného, hanlivého nebo nepřijatelného
materiálu jakéhokoliv druhu, ani nezákonného nebo protizákonného materiálu,
nebo materiálu, který nedodržuje nebo porušuje práva jiné strany (včetně, ale
nikoliv výlučně, práva duševního vlastnictví a práva na ochranu osobních
údajů);<br>
<b>(c)</b>&nbsp;nebudete se vydávat za jinou osobu, zkreslovat svůj věk ani
uvádět na svůj profil informace, které jsou jakkoliv nepravdivé nebo
zavádějící;<br>
<b>(d)</b>&nbsp;nebudete elektronickými nebo jinými prostředky shromažďovat ani
získávat e</span><span style='font-size:10.5pt;font-family:"Cambria Math",serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Cambria Math";
color:#505656;mso-fareast-language:CS'>‐</span><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>mailové adresy nebo jiné kontaktní údaje
ostatních uživatelů Služby ani nebudete využívat Službu k šíření spamu,
řetězových dopisů nebo využívat Službu pro hromadné rozesílání e</span><span
style='font-size:10.5pt;font-family:"Cambria Math",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Cambria Math";color:#505656;mso-fareast-language:
CS'>‐</span><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'> mailů;<br>
<b>(e)</b>&nbsp;nebudete Související aplikaci nebo Webové stránky používat k
neoprávněným komerčním účelům;<br>
<b>(f)</b>&nbsp;nebudete žádat nebo pokoušet se získat peníze, údaje o
bankovních účtech nebo kreditních kartách nebo důvěrné finanční informace od
ostatních členů nebo uživatelů Služby, Související aplikace nebo Webových
stránek;<br>
<b>(g)</b>&nbsp;nebudete posílat peníze ani poskytovat finanční údaje osobě, se
kterou jste komunikovali nebo se kterou jste se seznámili prostřednictvím
Webových stránek nebo Související aplikace. Společnost nenese žádnou
odpovědnost za jakoukoli ztrátu (finanční nebo jinou), kterou si přivodíte v
důsledku tohoto chování. Souhlasíte, že nahlásíte každého, kdo se pokusí žádat
o peníze nebo získat finanční údaje, tak, že kontaktujete zákaznickou podporu
nebo pomocí funkce „Nahlásit profil“ na profilu člena;<br>
<b>(h)</b>&nbsp;nebudete obtěžovat ostatní tím, že se budete snažit pokračovat
v konverzaci s někým, kdo Vás jasně požádal o ukončení komunikace;<br>
<b>(i)</b>&nbsp;nebudete zveřejňovat ani odesílat materiál, který obsahuje viry
nebo jiné počítačové kódy, soubory nebo programy, které jsou vytvořeny ke
zničení nebo omezení funkčnosti počítačového softwaru nebo hardwaru;<br>
<b>(j)</b>&nbsp;nebudete používat nehumánní či automatizované roboty pro
přihlášení se do Služby;<b><o:p></o:p></b></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.7 Oznámení o porušení autorských práv</span></b><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(a)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;Společnost respektuje práva duševní vlastnictví jiných osob. Pokud na
Webových stránkách nebo na Související aplikaci naleznete obsah, o kterém máte
podezření, že patří Vám nebo třetí straně, a že je tento obsah zobrazen
způsobem, který porušuje nebo zdánlivě porušuje Vaše autorská práva, Vaše práva
duševního vlastnictví nebo práva duševního vlastnictví, které patří třetí
straně, můžete podat oznámení.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Oznámení mohou být zaslána na adresu:<br>
E</span><span style='font-size:10.5pt;font-family:"Cambria Math",serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Cambria Math";
color:#505656;mso-fareast-language:CS'>‐</span><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>mail:&nbsp;</span><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-fareast-language:CS'>info@e-see-you.cz.<span
style='color:#505656'><o:p></o:p></span></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>5. Informace</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>5.1 Sledování informací</span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Vyhrazujeme si právo kontrolovat všechny profily, abychom zajistili, že
jsou v souladu s těmito Podmínkami používání.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>5.2 Zabezpečení informací</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Žádný přenos dat přes internet nemůže být zaručen jako zcela bezpečný.
Přestože se snažíme tyto informace chránit, nezaručujeme a nemůžeme zajistit
bezpečnost informací, které k nám přenášíte. V souladu s tím výslovně uznáváte,
že veškeré informace, které nám předáváte, jsou předávány na Vaše vlastní
nebezpečí.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>6. Duševní vlastnictví</span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Společnost si vyhrazuje všechna práva, názvy a zájmy spojené se Službou,
Webovými stránkami a Související aplikací a odpovídajícími právy duševního
vlastnictví a vyhrazuje si všechna práva, která nebyla výslovně udělena.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>7. Odkazované webové stránky</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Webové stránky mohou obsahovat odkazy na jiné webové stránky („Odkazované
webové stránky“). Tyto odkazy jsou poskytovány pouze pro Vaše pohodlí a nemusí
být aktuální ani aktualizovány. Nejsme odpovědní za obsah nebo postupy týkající
se ochrany soukromí, které souvisí s Odkazovanými webovými stránkami.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>8. Žádné záruky ohledně dostupnosti</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Nezaručujeme, že Služba, Související aplikace nebo Webové stránky budou
fungovat nepřerušovaně nebo bezchybně. Služba je distribuována tak „jak je“.
Mohou nastávat zpoždění, vynechání a přerušení dostupnosti Služby, Související
aplikace nebo Webových stránek. Pokud to zákon dovoluje, berete na vědomí, že
Služba (a dostupnost Webových stránek a Související aplikace) je poskytována
bez jakékoliv druhu záruk, ať výslovných nebo předpokládaných, včetně, nikoliv
však výlučně k předpokládaným obchodním zárukám a vhodnosti pro konkrétní účel.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>9. Zvláštní varování týkající se seznamování online</span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>9.1 Rizika</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Berete na vědomí, že existují rizika spojená s online seznamováním, včetně
ale nikoliv výhradně, nevědomého kontaktu s nezletilými osobami nebo osobami
jednajícími pod falešnou záminkou nebo za účelem trestného činu. Souhlasíte s
tím, že přijmete veškerá nezbytná opatření při komunikaci nebo při osobním
setkání s ostatními uživateli, zvláště pokud se rozhodnete setkat osobně. Dále
souhlasíte s tím, že před použitím Služby si přečtěte naše tipy pro bezpečnost
online seznamování.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>9.2 Žádné záruky ohledně identity členů</span></b><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Berete na vědomí, že ověřování uživatelů na internetu je velmi obtížné.
Společnost nemůže a nezaručuje, že každý člen nebo uživatel Služby je tím, kým
tvrdí, že je. Dále společnost nemůže zaručit a nezaručuje, že profily členů
obsahují spolehlivé, přesné nebo úplné informace o členovi. Proto musíte být
opatrní při jednání s ostatními členy nebo uživateli Služby, Webových stránek
nebo Související aplikace.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>10. Ukončení</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>10.1 Platnost dohody pro členy</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Tato Dohoda zůstane v plné platnosti a účinnosti po dobu, kdy jste členem
Služby nebo kdy jinak používáte Webové stránky nebo Související aplikaci.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>10.2 Vámi ukončené členství</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Vaše členství můžete ukončit kdykoliv z jakéhokoliv důvodu s okamžitou
platností a to zrušením Vašeho profilu na Webových stránkách nebo v&nbsp;Související
aplikaci.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>10.3 Námi ukončené členství<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Kdykoliv můžeme s okamžitou platností ukončit Vaše členství a Váš přístup
ke Službě, pokud zjistíme (dle našeho uvážení), že jste porušili tuto Dohodu.
Oznámení o ukončení členství Vám bude doručeno na poslední Vámi poskytnutou e</span><span
style='font-size:10.5pt;font-family:"Cambria Math",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Cambria Math";color:#505656;mso-fareast-language:
CS'>‐</span><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>mailovou adresu.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>10.4 Deaktivace pro neužívání</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>E-see-you může deaktivovat Váš účet, pokud jste službu nepoužili po dobu 12
měsíců.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>11. Obecná ustanovení</span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>11.1 Nedodržení podmínek</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Společnost nenese žádnou odpovědnost za případné nedodržení těchto Podmínek
používání, jestliže takové selhání nastane okolnostmi mimo naši kontrolu.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>11.2 Jazyk dohody</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Jazykem této Dohody je čeština. Pokud Společnost poskytla překlad do anglické
verze této Dohody, souhlasíte s tím, že překlad je poskytován pouze pro Vaše
pohodlí. Pokud dojde k nějaké nesrovnalosti mezi anglickou verzí Dohody a
přeloženou verzí Dohody, bude rozhodná česká verze.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;margin-bottom:0cm;line-height:
normal'><b style='mso-bidi-font-weight:normal'><span style='font-size:16.0pt;
mso-bidi-font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'>Prohlášení o ochraně
soukromí<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal'><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'>(Naposledy
aktualizováno dne 14. července 2023)<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>1 Obecná ustanovení</span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Společnost E-see-you s.r.o. se zavazuje chránit soukromí uživatelů svých
služeb. Chápeme, že návštěvníci a uživatelé Webových stránek a Související
Aplikace se obávají o své soukromí a o diskrétnost a bezpečnost Osobních údajů,
které poskytují.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Osobní údaje shromažďujeme, používáme a poskytujeme pouze v souladu s tímto
Prohlášením o ochraně soukromí a zákony platnými v jurisdikcích, ve kterých
shromažďujeme Osobní údaje. Pokud mezi tímto Prohlášením o ochraně soukromí a
platným zákonem dojde k rozporu, v rozsahu nesouladu bude převažovat příslušný
zákon.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>2 Základ shromažďování dat</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Prohlášení o ochraně soukromí stanovuje zásady společnosti E-see-you
týkající se shromažďování a použití Vašich Osobních údajů, během užívání Webové
stránky nebo Související Aplikace společnosti E-see-you. Slova s velkým
počátečním písmenem, například „Osobní údaje&quot;, jsou definována v bodě „Pojmy“
tohoto Prohlášení.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>2.1 Smluvní služby</span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Pokud jste si vytvořili účet prostřednictvím naší Webové stránky nebo
Související Aplikace společnosti E-see-you a požádali jste, abychom Vám
poskytovali služby, budeme muset shromažďovat a používat Vaše osobní údaje k uzavření
smlouvy s Vámi a <span style='background:#F8F8F8'>poskytování Vám našich
služeb, které jsou dostupné prostřednictvím našich Webových stránek a
Související aplikace.</span><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><span style='mso-spacerun:yes'> </span>2.2 Legitimní obchodní účely</span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>V případě, že neshromažďujeme a nepoužíváme Vaše Osobní údaje za účelem
uzavření smlouvy s Vámi nebo poskytování Vám našich služeb, můžeme nadále
shromažďovat a používat Vaše Osobní údaje, pokud:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(i)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;Vás naše shromažďování či používání negativně neovlivní;<br>
<b>(ii)</b>&nbsp;takové shromažďování a používání je v souladu s tím, co byste
rozumně očekávali v souvislosti s naším používáním Vašich osobních údajů; a<br>
<b>(iii)</b>&nbsp;je nezbytné k naplnění našich oprávněných zájmů.<br>
<br>
To může zahrnovat například použití Vašich Osobních údajů k vylepšení našich
Webových stránek a Související aplikace, nebo rozsahu služeb, které Vám
nabízíme.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>2.3 Dodržování právních předpisů</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Existují určité situace, ve kterých můžeme být povinni zpracovávat Vaše
osobní údaje, abychom dodrželi zákon, například pokud podlehneme příkazu soudu
nebo regulačního orgánu, který takové zpracování vyžaduje.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>2.4 Váš souhlas</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Přístupem na Webové stránky a Související aplikaci a jejich používáním
souhlasíte se shromažďováním a použitím Vašich Osobních údajů v souladu se
zásadami stanovenými v tomto Prohlášení o ochraně soukromí. Vytvořením
členského účtu nebo přihlášením se na Webové stránky nebo Související Aplikaci
dáváte souhlas se shromažďováním a použitím Vašich Osobních údajů.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Váš souhlas se shromažďováním a používáním Vašich Osobních údajů můžete
odvolat kdykoli tak, že přestanete používat Webové stránky nebo Související Aplikace.
Pokud jste si založili členský účet, souhlas se shromažďováním a použitím
Vašich osobních odvoláte tak, že přestanete používat svůj členský účet a
požádáte o zrušení Vašeho členského účtu.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>3 Shromažďování údajů</span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>3.1 Všichni uživatelé</span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Kdykoli uživatelé (včetně uživatelů bez členského účtu) navštíví naše
Webové stránky nebo Související aplikaci, naše servery automaticky
zaznamenávají údaje pro statistické účely o Vašem použití Webových stránek nebo
Související aplikace, jako jsou:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(i)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;typ prohlížeče;<br>
<b>(ii)</b>&nbsp;odkazující URL;<br>
<b>(iii)</b>&nbsp;IP adresa;<br>
<b>(iv)</b>&nbsp;číslo a typ navštívené stránky;<br>
<b>(v)</b>&nbsp;datum a čas návštěvy; a<br>
<b>(vi)</b>&nbsp;výstupní URL.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Údaje, které o Vás shromažďujeme, nejsou Vaše osobní údaje, a v každém
případě zůstávají anonymní. Anonymní údaje spojujeme s ostatními Osobními údaji
pouze v případě, že jste si vytvořili členský účet nebo jste v době použití Webové
stránky nebo Související aplikace byli přihlášeni do svého členského účtu.
Pokud jste se tedy na Webových stránkách nebo Související aplikaci neidentifikovali,
například vytvořením si členského účtu nebo přihlášením se do členského účtu,
nebudeme spojovat záznam o použití Webových stránek nebo Související aplikací s
Vaší osobou.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>3.2 Členové</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Shromažďujeme údaje o registrovaných členech, abychom jim mohli poskytovat
všechny výhody členství. Shromažďujeme Vaše neidentifikační údaje a Osobní
údaje, když se poprvé zaregistrujete, a také později, když nám poskytnete
dodatečné údaje.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Osobní údaje, které mohou být shromažďovány společností E-see-you,
zahrnují:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(i)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;Vaše jméno a příjmení;<br>
<b>(ii)</b>&nbsp;Vaši e-mailovou adresu;<br>
<b>(iii)</b>&nbsp;fotografie Vás;<br>
<b>(iv)</b>&nbsp;Vaše datum narození;<b><o:p></o:p></b></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>3.3 Soubory Cookies and další sledovací technologie</span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Abychom Vám mohli poskytnout co nejlepší služby, můžeme používat soubory
cookies a další sledovací technologie, jako například pixelové tagy, webové
majáky, ID zařízení nebo inzertní IDs.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Používání cookies můžete odmítnout nebo si můžete přizpůsobit nastavení
Vašeho prohlížeče. Vezměte, prosím, na vědomí, že pokud tak učiníte, je možné,
že nebudete moci využívat všech funkcí Webových stránek a Související aplikace.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Naše Webové stránky a Související Aplikace využívají služby Google
Analytics, která předává údaje o návštěvnosti webových stránek na servery
Google ve Spojených státech amerických. Google Analytics neidentifikuje
individuální uživatele, ani nespojuje Vaši IP adresu s žádnými jinými údaji,
které má společnost Google k dispozici. Používáme záznamy poskytované službou
Google Analytics, abychom znali údaje o návštěvnosti našich Webových stránek a
Souvidející aplikace a jejich použití uživateli.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>V nastavení reklam Vašeho účtu na Google můžete změnit nebo deaktivovat
službu Google Analytics pro zobrazování reklam.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Naše Webové stránky a Související Aplikace používají soubory cookies a
další sledovací technologie, aby podpořili Google Analytics za účelem:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(i)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;integrace platformy Google Marketing;<br>
<b>(ii)</b>&nbsp;online inzerce včetně remarketingu a přehledů zobrazení sítě
reklam na Google pro inzerování našich služeb na internetu;<br>
<b>(iii)</b>&nbsp;optimizace a perzonalizace reklamních služeb na základě
Vašich minulých návštěv našich Webových stránek; a<br>
<b>(iv)</b>&nbsp;sledování dat týkajících se použití reklamních služeb a
interakcí se zobrazenou reklamou a jejich souvislostí s návštěvami našich
webových stránek.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Soubory Cookies a další sledovací technologie mohou být také použity pro
potřeby analytiky jiných třetích stran.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>3.4 Sociální média</span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Společnost E-see-you je propojená se sociálními médii jako je Facebook,
YouTube a Google Maps. Pokud se rozhodnete kliknout na „líbí se mi&quot; nebo
„sdílet&quot; informace z našich Webových stránek nebo Související aplikace prostřednictvím
těchto služeb, doporučujeme Vám přečíst si Prohlášení o ochraně soukromí
příslušné služby. Pokud jste registrovaným uživatelem sociálních médií,
rozhraní mohou povolit sociálním médiím spojit Vaše návštěvy těchto webových
stránek s ostatními Osobními údaji.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4 Účel shromažďování, uchovávání, používání a zveřejňování Osobních údajů</span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.1 Získávání údajů pouze od Vás</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Pokud je to účelné nebo praktické, shromažďujeme osobní údaje přímo od Vás.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.2 Účely shromažďování Osobních údajů</span></b><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Shromažďujeme pouze Osobní údaje, které jsou nezbytné k tomu, abychom:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(i)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;mohli zpracovat Vaši žádost o členství a poskytnout Vám naše služby
(včetně vytvoření veřejně dostupného profilu, povolení členům hledat vhodné
protějšky a poskytnutí platformy pro komunikaci mezi členy);<br>
<b>(ii)</b>&nbsp;je využili v našem interním výzkumu a pro statistické účely
(včetně segmentace trhu a analýzy hodnoty zákazníka);<br>
<b>(iii)</b>&nbsp;Vám mohli předat další informace a materiály, o kterých se
domníváme, že by Vás mohly zajímat;<br>
<b>(iv)</b>&nbsp;je mohli využít za jakýmkoli jiným účelem uvedeným v našich
Podmínkách používání, které jsou dostupné na naší Webové stránce nebo
v&nbsp;Související Aplikaci<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.3 Použití a zveřejnění Osobních údajů</span></b><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Použití údajů pro Primární účel bude zahrnovat použití Osobních údajů v
automatizovaných rozhodovacích procesech.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Abychom Vám mohli nabízet naše služby, můžeme také poskytnout hash Vaší
e-mailové adresy (ne Osobní údaje) třetím stranám, jako je Facebook.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Srovnání Vaší hashované e-mailové adresy bude sloužit pouze k propagaci
našich produktů a služeb a nebude třetí stranou použito za žádným jiným účelem.
Srovnání hashe Vaší e-mailové adresy nebude použito k identifikování Vaší osoby
či Vaší e-mailové adresy, a žádné další údaje o Vás nebudou námi poskytnuty
třetí straně.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.4 Uchovávání Osobních údajů</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Podnikneme příslušná opatření, abychom chránili Vaše Osobní údaje, které
uchováváme, před:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(i)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;zneužitím, zásahem a ztrátou; a<br>
<b>(ii)</b>&nbsp;neautorizovaným přístupem, změnou nebo zveřejněním.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>V nepravděpodobném případě vážného úniku údajů, který by mohl vést k
ohrožení Vašich práv a svobod, budeme bez zbytečného odkladu informovat
příslušný kontrolní orgán. V případě, že existuje riziko, že by únik údajů mohl
mít dopad na Vaše práva a svobody, budeme o úniku údajů informovat i Vás.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.5 Zveřejňování údajů v zahraničí</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Veškeré údaje, které jste poskytli na svém profilu, budou veřejně přístupné
bez ohledu na lokalitu, ve které se návštěvník Vašeho profilu nachází.
Vytvořením profilu souhlasíte, že osoby v zahraničí si mohou prohlížet Váš
profil.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Abychom mohli poskytovat zákaznickou podporu, vykonávat funkce back office,
provádět úkony pro předcházení podvodům a poskytovat Vám naše služby, budeme
někdy muset našim zaměstnancům povolit přístup k Vašim profilovým údajům, nebo
Osobním údajům, které jste poskytli.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Osobní údaje poskytneme Třetí straně z jiné země nebo Mezinárodní
organizaci pouze v případě, že je zveřejnění údajů požadováno nebo schváleno
zákonem, nařízením nebo soudním příkazem.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.6 Komunikace</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Vyhrazujeme si právo zasílat Vám elektronickou poštu týkající se
doporučených vhodných protějšků, oznámení o interakci s Vaším profilem (jako
jsou oznámení o projevení zájmu nebo oznámení o nové zprávě), změn a doplňků k
našim službám.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>5 Zničení osobních údajů</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Vaše Osobní údaje budeme uchovávat pouze po dobu, kdy je budeme potřebovat pro
legitimní obchodní účely a abychom vyhověli platným zákonům. Podnikneme veškeré
přiměřené kroky ke smazání všech Osobních údajů, které o Vás uchováváme, v
případě, že:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(i)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;již nepotřebujeme Osobní údaje pro účely, pro které byly shromážděny,
a nemáme právo je nadále uchovávat; a<br>
<b>(ii)</b>&nbsp;nejsme požádáni ani povinni podle zákona, nařízení nebo
soudního příkazu tyto údaje uchovávat.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>6 Stížnosti</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Pokud se domníváte, že jsme použili nebo zveřejnili Vaše osobní údaje
způsobem, který je v rozporu s tímto Prohlášením o ochraně soukromí, nebo jiným
způsobem porušuje platné zákony, pošlete nám e-mail </span><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-fareast-language:CS'>na&nbsp;</span><a
href="mailto:privacy@cupidmedia.com"><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:windowtext;
mso-fareast-language:CS;text-decoration:none;text-underline:none'>info@e-see-you.cz</span></a><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-fareast-language:CS'>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Pojmy</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&quot;E-see-you&quot;</span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'>&nbsp;se rozumí Společnost poskytující seznamovací Služby
zákazníkům;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>„Obecné nařízení o ochraně osobních údajů&quot;</span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'>&nbsp;a&nbsp;<b>GDPR</b>&nbsp;se
rozumí Nařízení Evropského parlamentu a rady 2016/679 ze dne 27. dubna 2016 o
ochraně fyzických osob v souvislosti se zpracováním osobních údajů a volném
pohybu těchto údajů a odvolání Směrnice 95/46/EC;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>„Mezinárodní organizací&quot;</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>&nbsp;se rozumí organizace a její
podřízené subjekty, které se řídí veřejným mezinárodním právem, nebo jiný subjekt,
který je zřízen na základě dohody mezi dvěma nebo více zeměmi;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>„Osobními údaji&quot;</span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'>&nbsp;se rozumí jakékoli údaje týkající se
identifikovatelné fyzické osoby (subjekt údajů); identifikovatelná fyzická
osoba je osoba, kterou lze přímo nebo nepřímo identifikovat, zejména odkazem na
identifikátor jako je jméno, identifikační číslo, údaje o poloze nebo, online
identifikátor;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>„Zpracováním&quot;</span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'>&nbsp;se rozumí jakýkoli úkon nebo soubor úkonů, které
se provádějí s osobními údaji nebo se souborem osobních údajů (ať už
automatizovanými prostředky nebo ne) jako je shromažďování, záznam, uspořádání,
seřazení, ukládání, upravení nebo změnění, vyhledávání, konzultace, použití,
zveřejnění přenosem, šířením nebo jiným zpřístupněním na internet, seřazením
nebo spojením, omezením, smazáním nebo zničením;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>„Webová stránka&quot; </span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'>se rozumí webová stránka, kterou vlastní, provozuje a
řídí společnost E-see-you s.r.o.;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>„Související aplikace&quot; </span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>se rozumí aplikace, kterou vlastní,
provozuje a řídí společnost E-see-you s.r.o.;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>„Společností“</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;se rozumí společnost E-see-you s.r.o., společnost zaregistrovaná v&nbsp;České
Republice, IČO 180 39&nbsp;413;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>„Prohlášením o ochraně soukromí“</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>&nbsp;se rozumí Prohlášení Společnosti o
ochraně soukromí, které je k dispozici na Webových stránkách;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>„Službou“</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;se rozumí funkce poskytované členům prostřednictvím Webových stránek
nebo Související aplikace;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>„Podmínkami používání“</span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'>&nbsp;se rozumí tyto Podmínky používání ve znění,
které je čas od času měněno Společností a které vytvářejí dohodu mezi Vámi a
Společností;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Pojmy&nbsp;<b>„My“, „Nás“ a „Naše“</b>&nbsp;se rozumí Společnost.<o:p></o:p></span></p>

<p class=MsoNormal><o:p>&nbsp;</o:p></p>

</div>

            <!-- EN: -->
            <div class=WordSection2 v-if="lang == 'en'">

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal'><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'>(Last <span
class=SpellE>Updated</span> 14<sup>th</sup> July 2023)<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>1. <span class=SpellE>Website</span> and <span class=SpellE>Related</span>
mobile <span class=SpellE>application</span></span></b><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>1.1 <span class=SpellE>Acceptance</span> <span class=SpellE>of</span> <span
class=SpellE>Terms</span> <span class=SpellE>of</span> Use</span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>The</span></span><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'> use <span class=SpellE>of</span> <span
class=SpellE>this</span> <span class=SpellE>Website</span> and <span
class=SpellE>Related</span> mobile <span class=SpellE>application</span> <span
class=SpellE>is</span> <span class=SpellE>conditional</span> <span
class=SpellE>upon</span> <span class=SpellE>your</span> <span class=SpellE>acceptance</span>
<span class=SpellE>of</span> these <span class=SpellE>Terms</span> <span
class=SpellE>of</span> Use and <span class=SpellE>our</span>&nbsp;</span><span
class=SpellE><a
href="https://www.internationalcupid.com/en/general/privacystatement"
target="_blank"><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS;text-decoration:none;text-underline:none'>Privacy</span><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS;text-decoration:none;
text-underline:none'> Statement</span></a></span><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>. You <span class=SpellE>will</span> <span
class=SpellE>be</span> <span class=SpellE>deemed</span> to <span class=SpellE>have</span>
<span class=SpellE>accepted</span> these <span class=SpellE>Terms</span> <span
class=SpellE>of</span> Use and <span class=SpellE>our</span> <span
class=SpellE>Privacy</span> <span class=SpellE>Statement</span> by <span
class=SpellE>continuing</span> to use <span class=SpellE>this</span> <span
class=SpellE>Website</span> and <span class=SpellE>Related</span> mobile <span
class=SpellE>application</span>, <span class=SpellE>regardless</span> <span
class=SpellE>of</span> <span class=SpellE>whether</span> <span class=SpellE>or</span>
not you <span class=SpellE>register</span> as a <span class=SpellE>member</span>.
<span class=SpellE>We</span> <span class=SpellE>may</span> <span class=SpellE>change</span>
these <span class=SpellE>Terms</span> <span class=SpellE>of</span> Use and <span
class=SpellE>our</span> <span class=SpellE>Privacy</span> <span class=SpellE>Statement</span>
<span class=SpellE>at</span> any <span class=SpellE>time</span>. <span
class=SpellE>If</span> <span class=SpellE>we</span> do, <span class=SpellE>an</span>
<span class=SpellE>amended</span> <span class=SpellE>version</span> <span
class=SpellE>of</span> <span class=SpellE>the</span> <span class=SpellE>Terms</span>
<span class=SpellE>of</span> Use and <span class=SpellE>Privacy</span> <span
class=SpellE>Statement</span> <span class=SpellE>will</span> <span
class=SpellE>be</span> <span class=SpellE>posted</span> on <span class=SpellE>the</span>
<span class=SpellE>Website</span> and <span class=SpellE>Related</span> mobile <span
class=SpellE>application</span>. You are <span class=SpellE>responsible</span> <span
class=SpellE>for</span> <span class=SpellE>ensuring</span> <span class=SpellE>that</span>
you <span class=SpellE>regularly</span> <span class=SpellE>review</span> <span
class=SpellE>the</span> <span class=SpellE>Terms</span> <span class=SpellE>of</span>
Use and <span class=SpellE>Privacy</span> <span class=SpellE>Statement</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>If</span></span><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'> you do not <span class=SpellE>agree</span>
to these <span class=SpellE>Terms</span> <span class=SpellE>of</span> Use you <span
class=SpellE>must</span> not use <span class=SpellE>this</span> <span
class=SpellE>Website</span> and <span class=SpellE>Related</span> mobile <span
class=SpellE>application</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>1.2 User <span class=SpellE>agreement</span></span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>These <span class=SpellE>Terms</span> <span class=SpellE>of</span> Use <span
class=SpellE>constitute</span> <span class=SpellE>the</span> <span
class=SpellE>agreement</span> <span class=SpellE>between</span> you and <span
class=SpellE>the</span> <span class=SpellE>Company</span> E-see-you Ltd in <span
class=SpellE>respect</span> <span class=SpellE>of</span> <span class=SpellE>your</span>
use <span class=SpellE>of</span> <span class=SpellE>the</span> <span
class=SpellE>Service</span> and <span class=SpellE>the</span> <span
class=SpellE>Website</span> and <span class=SpellE>Related</span> mobile <span
class=SpellE>application</span> (<span class=SpellE>the</span> &quot;<span
class=SpellE>Agreement</span>&quot;).<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>1.3 <span class=SpellE>Contact</span><o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>E-see-you.cz (.<span class=SpellE>com</span>) (<span class=SpellE>the</span>&nbsp;<span
class=SpellE><b>Website</b></span><b> </b><span style='mso-bidi-font-weight:
bold'>and <span class=SpellE>the</span><b> <span class=SpellE>Related</span> <span
class=SpellE>mobie</span> <span class=SpellE>application</span></b></span>) <span
class=SpellE>is</span> <span class=SpellE>owned</span> and <span class=SpellE>operated</span>
by <span class=SpellE>the</span> <span class=SpellE>Company</span> E-see-you
Ltd <span class=SpellE>registered</span> in <span class=SpellE>the</span> Czech
Republic, ID 180 39 413.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-fareast-language:CS'>Email:&nbsp;</span></b><a
href="mailto:info@e-see-you.cz"><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:windowtext;mso-fareast-language:
CS'>info@e-see-you.cz</span></a><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-fareast-language:CS'>Telephone</span></b></span><b><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-fareast-language:CS'>:&nbsp;</span></b><a href="tel:+61%207%205571%201181"><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:windowtext;mso-fareast-language:CS;text-decoration:
none;text-underline:none'>+420 702&nbsp;105&nbsp;663</span></a><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>2. <span class=SpellE>Membership</span></span></b><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>2.1 <span class=SpellE>Eligibility</span></span></b><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>You <span class=SpellE>must</span> <span class=SpellE>be</span> <span
class=SpellE>over</span> 18 <span class=SpellE>years</span> <span class=SpellE>or</span>
<span class=SpellE>over</span> 15 <span class=SpellE>years</span> <span
class=SpellE>with</span> <span class=SpellE>the</span> <span class=SpellE>approval</span>
<span class=SpellE>of</span> <span class=SpellE>the</span> <span class=SpellE>legal</span>
<span class=SpellE>representative</span> to <span class=SpellE>register</span>
as a <span class=SpellE>member</span> <span class=SpellE>of</span> <span
class=SpellE>the</span> <span class=SpellE>Service</span> <span class=SpellE>or</span>
to use <span class=SpellE>this</span> <span class=SpellE>Website</span> and <span
class=SpellE>Related</span> mobile <span class=SpellE>application</span>. By <span
class=SpellE>using</span> <span class=SpellE>the</span> <span class=SpellE>Website</span>
and <span class=SpellE>Related</span> mobile <span class=SpellE>application</span>,
you <span class=SpellE>represent</span>, <span class=SpellE>undertake</span>
and warrant <span class=SpellE>that</span> you <span class=SpellE>have</span> <span
class=SpellE>the</span> <span class=SpellE>right</span>, <span class=SpellE>authority</span>
and <span class=SpellE>capacity</span> to enter <span class=SpellE>into</span> <span
class=SpellE>this</span> <span class=SpellE>Agreement</span> and to <span
class=SpellE>comply</span> <span class=SpellE>with</span> <span class=SpellE>all</span>
<span class=SpellE>of</span> <span class=SpellE>the</span> <span class=SpellE>Terms</span>
<span class=SpellE>of</span> Use.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>2.2 <span class=SpellE>Formation</span> <span class=SpellE>of</span> <span
class=SpellE>contract</span></span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>By <span class=SpellE>registering</span> as a <span class=SpellE>member</span>
you enter <span class=SpellE>into</span> a <span class=SpellE>contract</span> <span
class=SpellE>with</span> <span class=SpellE>the</span> <span class=SpellE>Company</span>
in <span class=SpellE>respect</span> <span class=SpellE>of</span> <span
class=SpellE>the</span> <span class=SpellE>Website</span> and <span
class=SpellE>Related</span> mobile <span class=SpellE>application</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>2.3 <span class=SpellE>Membership</span></span></b><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(a)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;You <span class=SpellE>become</span> a <span class=SpellE>member</span>
<span class=SpellE>of</span> <span class=SpellE>the</span> <span class=SpellE>Service</span>
free <span class=SpellE>of</span> <span class=SpellE>charge</span>. <br>
<b>(b)</b>&nbsp;You <span class=SpellE>acknowledge</span> and <span
class=SpellE>agree</span> <span class=SpellE>that</span> in <span class=SpellE>order</span>
to <span class=SpellE>protect</span> <span class=SpellE>the</span> <span
class=SpellE>users</span> <span class=SpellE>of</span> <span class=SpellE>this</span>
Web <span class=SpellE>site</span> and <span class=SpellE>Related</span> mobile
<span class=SpellE>application</span>, you <span class=SpellE>may</span> not <span
class=SpellE>apply</span> <span class=SpellE>for</span> <span class=SpellE>or</span>
<span class=SpellE>become</span> a <span class=SpellE>member</span> <span
class=SpellE>if</span> you <span class=SpellE>have</span> <span class=SpellE>been</span>
<span class=SpellE>convicted</span> <span class=SpellE>of</span> <span
class=SpellE>an</span> <span class=SpellE>indictable</span> <span class=SpellE>offence</span>
<span class=SpellE>or</span> a <span class=SpellE>felony</span>. By <span
class=SpellE>applying</span> <span class=SpellE>for</span> <span class=SpellE>membership</span>
you warrant and <span class=SpellE>represent</span> to <span class=SpellE>us</span>
<span class=SpellE>that</span> you <span class=SpellE>have</span> not <span
class=SpellE>been</span> <span class=SpellE>convicted</span> <span
class=SpellE>of</span> <span class=SpellE>an</span> <span class=SpellE>indictable</span>
<span class=SpellE>offence</span> <span class=SpellE>or</span> a <span
class=SpellE>felony</span> nor are you <span class=SpellE>required</span> to <span
class=SpellE>register</span> as a sex <span class=SpellE>offender</span> <span
class=SpellE>with</span> any <span class=SpellE>government</span> <span
class=SpellE>authority</span>.<b><o:p></o:p></b></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>2.4 <span class=SpellE>Password</span> <span class=SpellE>security</span></span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>As part <span class=SpellE>of</span> <span class=SpellE>the</span> <span
class=SpellE>member</span> <span class=SpellE>registration</span> <span
class=SpellE>process</span>, you <span class=SpellE>will</span> <span
class=SpellE>be</span> <span class=SpellE>required</span> to <span
class=SpellE>select</span> a <span class=SpellE>password</span>. You are <span
class=SpellE>solely</span> <span class=SpellE>responsible</span> <span
class=SpellE>for</span> <span class=SpellE>selecting</span> a <span
class=SpellE>password</span> <span class=SpellE>that</span> <span class=SpellE>is</span>
not <span class=SpellE>easily</span> <span class=SpellE>guessed</span> and <span
class=SpellE>for</span> <span class=SpellE>keeping</span> <span class=SpellE>your</span>
<span class=SpellE>password</span> <span class=SpellE>safe</span>, and <span
class=SpellE>agree</span> not to transfer <span class=SpellE>or</span> <span
class=SpellE>resell</span> <span class=SpellE>your</span> use <span
class=SpellE>of</span> <span class=SpellE>or</span> <span class=SpellE>access</span>
to <span class=SpellE>the</span> <span class=SpellE>Website</span> and <span
class=SpellE>Related</span> mobile <span class=SpellE>application</span> to any
<span class=SpellE>third</span> party. <span class=SpellE>If</span> you <span
class=SpellE>have</span> <span class=SpellE>reason</span> to <span
class=SpellE>believe</span> <span class=SpellE>that</span> <span class=SpellE>your</span>
<span class=SpellE>account</span> <span class=SpellE>is</span> no <span
class=SpellE>longer</span> <span class=SpellE>secure</span>, you <span
class=SpellE>must</span> <span class=SpellE>immediately</span> <span
class=SpellE>notify</span> <span class=SpellE>us</span> and you <span
class=SpellE>must</span> <span class=SpellE>promptly</span> <span class=SpellE>change</span>
<span class=SpellE>your</span> <span class=SpellE>password</span> by <span
class=SpellE>updating</span> <span class=SpellE>your</span> <span class=SpellE>account</span>
<span class=SpellE>information</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>2.5 <span class=SpellE>Verification</span> <span class=SpellE>of</span>
identity</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>We</span></span><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'> <span class=SpellE>may</span> <span
class=SpellE>from</span> <span class=SpellE>time</span> to <span class=SpellE>time</span>
<span class=SpellE>request</span> a <span class=SpellE>form</span> <span
class=SpellE>of</span> <span class=SpellE>identification</span> to <span
class=SpellE>verify</span>:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(a)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;<span class=SpellE>your</span> identity;<br>
<b>(b)</b>&nbsp;<span class=SpellE>the</span> <span class=SpellE>information</span>
<span class=SpellE>provided</span> by you;<br>
<b>(c)</b>&nbsp;<span class=SpellE>your</span> <span class=SpellE>compliance</span>
<span class=SpellE>with</span> <span class=SpellE>our</span> <span
class=SpellE>Terms</span> <span class=SpellE>of</span> Use.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>3. <span class=SpellE>The</span> <span class=SpellE>Service</span></span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>The</span></span><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'> <span class=SpellE>Service</span> <span
class=SpellE>is</span> <span class=SpellE>an</span> internet <span
class=SpellE>information</span> <span class=SpellE>service</span> <span
class=SpellE>that</span> <span class=SpellE>facilitates</span> <span
class=SpellE>contact</span> <span class=SpellE>between</span> <span
class=SpellE>members</span> <span class=SpellE>who</span> <span class=SpellE>may</span>
<span class=SpellE>or</span> <span class=SpellE>may</span> not <span
class=SpellE>be</span> <span class=SpellE>seeking</span> <span class=SpellE>friendship</span>
<span class=SpellE>or</span> a <span class=SpellE>relationship</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4. Use <span class=SpellE>of</span> <span class=SpellE>the</span> <span
class=SpellE>Service</span></span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.1 <span class=SpellE>Own</span> risk</span></b><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>You <span class=SpellE>acknowledge</span> <span class=SpellE>that</span> <span
class=SpellE>your</span> use <span class=SpellE>of</span> <span class=SpellE>the</span>
<span class=SpellE>Service</span>, <span class=SpellE>Website</span> and <span
class=SpellE>Related</span> mobile <span class=SpellE>application</span> <span
class=SpellE>is</span> <span class=SpellE>solely</span> <span class=SpellE>at</span>
<span class=SpellE>your</span> <span class=SpellE>own</span> risk.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.2 <span class=SpellE>Accurate</span> <span class=SpellE>information</span></span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>You <span class=SpellE>represent</span>, warrant and <span class=SpellE>undertake</span>
<span class=SpellE>that</span> <span class=SpellE>the</span> <span
class=SpellE>information</span> and <span class=SpellE>photos</span> <span
class=SpellE>that</span> you <span class=SpellE>supply</span> to <span
class=SpellE>the</span> <span class=SpellE>Service</span>, <span class=SpellE>the</span>
<span class=SpellE>Website</span> and <span class=SpellE>Related</span> mobile <span
class=SpellE>application</span> and <span class=SpellE>the</span> <span
class=SpellE>Company</span> <span class=SpellE>is</span>/are <span
class=SpellE>accurate</span> in <span class=SpellE>all</span> <span
class=SpellE>respects</span>, not in <span class=SpellE>breach</span> <span
class=SpellE>of</span> <span class=SpellE>this</span> <span class=SpellE>Agreement</span>
and not <span class=SpellE>harmful</span> to any person in any <span
class=SpellE>way</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>Without</span></span><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'> <span class=SpellE>limiting</span>
<span class=SpellE>the</span> <span class=SpellE>above</span>, you <span
class=SpellE>must</span> not <span class=SpellE>transmit</span> <span
class=SpellE>or</span> post on <span class=SpellE>our</span> <span
class=SpellE>Website</span> and <span class=SpellE>Related</span> mobile <span
class=SpellE>application</span>, any <span class=SpellE>images</span> <span
class=SpellE>that</span> <span class=SpellE>contain</span>:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>•</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;<span class=SpellE>images</span> <span class=SpellE>of</span> any
person <span class=SpellE>other</span> <span class=SpellE>than</span> you;<br>
<b>•</b>&nbsp;nudity;<br>
<b>•</b>&nbsp;<span class=SpellE>images</span> <span class=SpellE>of</span> <span
class=SpellE>other</span> <span class=SpellE>objects</span> <span class=SpellE>that</span>
do not <span class=SpellE>contain</span> you;<b><o:p></o:p></b></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.3 <span class=SpellE>Information</span> not <span class=SpellE>confidential</span></span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>You <span class=SpellE>agree</span> <span class=SpellE>that</span> any <span
class=SpellE>material</span> <span class=SpellE>or</span> <span class=SpellE>information</span>
<span class=SpellE>provided</span> by you, <span class=SpellE>including</span> <span
class=SpellE>Personal</span> Data, <span class=SpellE>will</span> <span
class=SpellE>be</span> <span class=SpellE>treated</span> <span class=SpellE>for</span>
<span class=SpellE>the</span> <span class=SpellE>reason</span> <span
class=SpellE>of</span> <span class=SpellE>providing</span> <span class=SpellE>the</span>
<span class=SpellE>Service</span>. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.4 <span class=SpellE>Information</span> <span class=SpellE>available</span>
<span class=SpellE>overseas</span></span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(a)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;Any profile <span class=SpellE>information</span> <span class=SpellE>that</span>
you <span class=SpellE>have</span> <span class=SpellE>provided</span> to <span
class=SpellE>us</span> <span class=SpellE>will</span> <span class=SpellE>be</span>
<span class=SpellE>publicly</span> <span class=SpellE>viewable</span> on <span
class=SpellE>your</span> profile, <span class=SpellE>irrespective</span> <span
class=SpellE>of</span> <span class=SpellE>the</span> <span class=SpellE>location</span>
<span class=SpellE>of</span> <span class=SpellE>the</span> <span class=SpellE>viewer</span>.
By <span class=SpellE>creating</span> a profile, you <span class=SpellE>acknowledge</span>
<span class=SpellE>that</span> <span class=SpellE>overseas</span> <span
class=SpellE>recipients</span> <span class=SpellE>will</span> <span
class=SpellE>be</span> <span class=SpellE>able</span> to <span class=SpellE>view</span>
<span class=SpellE>your</span> profile.<br>
<b>(b)</b>&nbsp;<span class=SpellE>The</span> <span class=SpellE>Company</span>
<span class=SpellE>utilizes</span> <span class=SpellE>servers</span> <span
class=SpellE>for</span> <span class=SpellE>the</span> <span class=SpellE>storage</span>
<span class=SpellE>of</span> <span class=SpellE>your</span> <span class=SpellE>Personal</span>
Data <span class=SpellE>which</span> are <span class=SpellE>located</span> in Czech
Republic.<br>
<b>(c)</b>&nbsp;In <span class=SpellE>order</span> to <span class=SpellE>provide</span>
<span class=SpellE>customer</span> support, <span class=SpellE>perform</span> <span
class=SpellE>back</span> office <span class=SpellE>functions</span>, <span
class=SpellE>perform</span> <span class=SpellE>fraud</span> <span class=SpellE>prevention</span>
<span class=SpellE>tasks</span> <span class=SpellE>or</span> <span
class=SpellE>provide</span> <span class=SpellE>services</span> to you <span
class=SpellE>we</span> <span class=SpellE>may</span> <span class=SpellE>need</span>
to <span class=SpellE>allow</span> <span class=SpellE>our</span> <span
class=SpellE>staff</span> <span class=SpellE>access</span> to <span
class=SpellE>your</span> <span class=SpellE>Personal</span> Data.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.5 <span class=SpellE>Content</span> <span class=SpellE>of</span> profile</span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(a)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;You are <span class=SpellE>solely</span> <span class=SpellE>responsible</span>
<span class=SpellE>for</span> <span class=SpellE>the</span> <span class=SpellE>content</span>
<span class=SpellE>of</span> <span class=SpellE>your</span> profile, <span
class=SpellE>messages</span>, and <span class=SpellE>other</span> <span
class=SpellE>materials</span> you <span class=SpellE>may</span> upload to <span
class=SpellE>the</span> <span class=SpellE>Service</span>.<br>
<b>(b)</b>&nbsp;You <span class=SpellE>agree</span> <span class=SpellE>that</span>
you <span class=SpellE>will</span> not upload <span class=SpellE>or</span> post
<span class=SpellE>onto</span> <span class=SpellE>your</span> profile <span
class=SpellE>or</span> <span class=SpellE>the</span> <span class=SpellE>Website</span>
and <span class=SpellE>Related</span> mobile <span class=SpellE>application</span>
any <span class=SpellE>content</span> <span class=SpellE>which</span>:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(i)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;<span class=SpellE>is</span> <span class=SpellE>abusive</span>, <span
class=SpellE>obscene</span>, <span class=SpellE>profane</span>, <span
class=SpellE>offensive</span>, <span class=SpellE>sexually</span> <span
class=SpellE>oriented</span>, <span class=SpellE>threatening</span>, <span
class=SpellE>harassing</span>, <span class=SpellE>racially</span> <span
class=SpellE>offensive</span>, <span class=SpellE>improper</span> <span
class=SpellE>or</span> <span class=SpellE>otherwise</span> <span class=SpellE>inappropriate</span>
as <span class=SpellE>determined</span> <span class=SpellE>at</span> <span
class=SpellE>our</span> sole <span class=SpellE>discretion</span>; <span
class=SpellE>or</span><br>
<b>(<span class=SpellE>ii</span>)</b>&nbsp;<span class=SpellE>depicts</span>, <span
class=SpellE>describes</span>, <span class=SpellE>identifies</span> <span
class=SpellE>or</span> <span class=SpellE>alludes</span> to any person <span
class=SpellE>other</span> <span class=SpellE>than</span> <span class=SpellE>yourself</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.6 Negative <span class=SpellE>covenants</span></span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>You <span class=SpellE>represent</span>, warrant and covenant <span
class=SpellE>that</span>:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(a)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;you <span class=SpellE>will</span> not <span class=SpellE>disclose</span>
any <span class=SpellE>information</span> <span class=SpellE>provided</span> to
you <span class=SpellE>through</span> <span class=SpellE>the</span> <span
class=SpellE>Service</span> to <span class=SpellE>anyone</span> <span
class=SpellE>without</span> <span class=SpellE>the</span> prior <span
class=SpellE>permission</span> <span class=SpellE>of</span> <span class=SpellE>the</span>
person <span class=SpellE>who</span> <span class=SpellE>provided</span> <span
class=SpellE>it</span> to you;<br>
<b>(b)</b>&nbsp;you <span class=SpellE>will</span> not use <span class=SpellE>the</span>
<span class=SpellE>Service</span> to <span class=SpellE>engage</span> in any <span
class=SpellE>form</span> <span class=SpellE>of</span> <span class=SpellE>harassment</span>
<span class=SpellE>or</span> <span class=SpellE>offensive</span> <span
class=SpellE>behavior</span>, <span class=SpellE>including</span> but not
limited to <span class=SpellE>the</span> <span class=SpellE>distribution</span>
<span class=SpellE>of</span> any <span class=SpellE>sexually</span> and/<span
class=SpellE>or</span> <span class=SpellE>racially</span> <span class=SpellE>offensive</span>,
<span class=SpellE>abusive</span>, <span class=SpellE>threatening</span>, <span
class=SpellE>vulgar</span>, <span class=SpellE>obscene</span>, <span
class=SpellE>harassing</span>, <span class=SpellE>libelous</span>, <span
class=SpellE>slanderous</span>, <span class=SpellE>defamatory</span> <span
class=SpellE>or</span> <span class=SpellE>objectionable</span> <span
class=SpellE>material</span> <span class=SpellE>of</span> any <span
class=SpellE>kind</span>, nor any <span class=SpellE>unlawful</span> <span
class=SpellE>or</span> <span class=SpellE>illegal</span> <span class=SpellE>material</span>,
<span class=SpellE>or</span> any <span class=SpellE>material</span> <span
class=SpellE>that</span> <span class=SpellE>infringes</span> <span
class=SpellE>or</span> <span class=SpellE>violates</span> <span class=SpellE>another</span>
<span class=SpellE>party's</span> <span class=SpellE>rights</span> (<span
class=SpellE>including</span>, but not limited to, <span class=SpellE>intellectual</span>
<span class=SpellE>property</span> <span class=SpellE>rights</span> and <span
class=SpellE>privacy</span> <span class=SpellE>rights</span>);<br>
<b>(c)</b>&nbsp;you <span class=SpellE>will</span> not <span class=SpellE>impersonate</span>
any person <span class=SpellE>or</span> <span class=SpellE>misrepresent</span> <span
class=SpellE>your</span> <span class=SpellE>age</span> <span class=SpellE>or</span>
<span class=SpellE>marital</span> status <span class=SpellE>or</span> place <span
class=SpellE>information</span> in <span class=SpellE>your</span> profile <span
class=SpellE>that</span> <span class=SpellE>is</span> <span class=SpellE>false</span>
<span class=SpellE>or</span> <span class=SpellE>misleading</span> in any <span
class=SpellE>way</span>;<br>
<b>(d)</b>&nbsp;you <span class=SpellE>will</span> not <span class=SpellE>harvest</span>
<span class=SpellE>or</span> <span class=SpellE>collect</span> email <span
class=SpellE>addresses</span> <span class=SpellE>or</span> <span class=SpellE>other</span>
<span class=SpellE>contact</span> <span class=SpellE>information</span> <span
class=SpellE>of</span> <span class=SpellE>other</span> <span class=SpellE>users</span>
<span class=SpellE>from</span> <span class=SpellE>the</span> <span
class=SpellE>Service</span> by <span class=SpellE>electronic</span> <span
class=SpellE>or</span> <span class=SpellE>other</span> <span class=SpellE>means</span>
<span class=SpellE>or</span> use <span class=SpellE>the</span> <span
class=SpellE>services</span> to <span class=SpellE>transmit</span>
&quot;spam&quot;, <span class=SpellE>chain</span> <span class=SpellE>letters</span>,
<span class=SpellE>junk</span> mail, <span class=SpellE>or</span> <span
class=SpellE>engage</span> in any <span class=SpellE>unsolicited</span> <span
class=SpellE>mass</span> <span class=SpellE>distribution</span> <span
class=SpellE>of</span> e-mail;<br>
<b>(e)</b>&nbsp;you <span class=SpellE>will</span> not use <span class=SpellE>the</span>
<span class=SpellE>Service</span> <span class=SpellE>or</span> <span
class=SpellE>the</span> <span class=SpellE>Website</span> and <span
class=SpellE>Related</span> mobile <span class=SpellE>application</span> <span
class=SpellE>for</span> any <span class=SpellE>unauthorized</span> <span
class=SpellE>commercial</span> <span class=SpellE>purposes</span>;<br>
<b>(f)</b>&nbsp;you <span class=SpellE>will</span> not <span class=SpellE>solicit</span>
<span class=SpellE>or</span> <span class=SpellE>attempt</span> to <span
class=SpellE>solicit</span> any <span class=SpellE>money</span>, bank <span
class=SpellE>account</span> <span class=SpellE>or</span> <span class=SpellE>credit</span>
<span class=SpellE>card</span> <span class=SpellE>details</span> <span
class=SpellE>or</span> <span class=SpellE>confidential</span> <span
class=SpellE>financial</span> <span class=SpellE>information</span> <span
class=SpellE>from</span> any <span class=SpellE>member</span> <span
class=SpellE>or</span> <span class=SpellE>other</span> user <span class=SpellE>of</span>
<span class=SpellE>the</span> <span class=SpellE>Service</span> <span
class=SpellE>or</span> <span class=SpellE>the</span> <span class=SpellE>Website</span>
and <span class=SpellE>Related</span> mobile <span class=SpellE>application</span>;<br>
<b>(g)</b>&nbsp;you <span class=SpellE>will</span> not <span class=SpellE>send</span>
<span class=SpellE>money</span> <span class=SpellE>or</span> <span
class=SpellE>provide</span> <span class=SpellE>financial</span> <span
class=SpellE>information</span> to any person <span class=SpellE>that</span>
you <span class=SpellE>have</span> <span class=SpellE>communicated</span> <span
class=SpellE>with</span> <span class=SpellE>or</span> met via <span
class=SpellE>the</span> <span class=SpellE>Website</span> and <span
class=SpellE>Related</span> mobile <span class=SpellE>application</span>. <span
class=SpellE>The</span> <span class=SpellE>Company</span> <span class=SpellE>will</span>
not <span class=SpellE>be</span> <span class=SpellE>liable</span> <span
class=SpellE>for</span> any <span class=SpellE>loss</span> (<span class=SpellE>financial</span>
<span class=SpellE>or</span> <span class=SpellE>otherwise</span>) <span
class=SpellE>suffered</span> by you as a <span class=SpellE>result</span> <span
class=SpellE>of</span> <span class=SpellE>this</span> <span class=SpellE>behavior</span>.
You <span class=SpellE>agree</span> to report <span class=SpellE>anyone</span> <span
class=SpellE>who</span> <span class=SpellE>attempts</span> to <span
class=SpellE>solicit</span> <span class=SpellE>money</span> <span class=SpellE>or</span>
<span class=SpellE>obtain</span> <span class=SpellE>financial</span> <span
class=SpellE>information</span> by <span class=SpellE>either</span> <span
class=SpellE>contacting</span> <span class=SpellE>customer</span> support <span
class=SpellE>or</span> <span class=SpellE>using</span> <span class=SpellE>the</span>
&quot;report profile&quot; <span class=SpellE>function</span> on <span
class=SpellE>the</span> <span class=SpellE>member’s</span> profile;<br>
<b>(h)</b>&nbsp;you <span class=SpellE>will</span> not <span class=SpellE>harass</span>
<span class=SpellE>others</span> by <span class=SpellE>continuing</span> to <span
class=SpellE>attempt</span> to <span class=SpellE>communicate</span> <span
class=SpellE>with</span> <span class=SpellE>someone</span> <span class=SpellE>who</span>
has <span class=SpellE>clearly</span> <span class=SpellE>asked</span> you to <span
class=SpellE>cease</span> <span class=SpellE>communications</span>;<br>
<b>(i)</b>&nbsp;you <span class=SpellE>will</span> not post <span class=SpellE>or</span>
<span class=SpellE>transmit</span> <span class=SpellE>material</span> <span
class=SpellE>which</span> <span class=SpellE>contains</span> <span
class=SpellE>viruses</span> <span class=SpellE>or</span> <span class=SpellE>other</span>
<span class=SpellE>computer</span> <span class=SpellE>codes</span>, <span
class=SpellE>files</span> <span class=SpellE>or</span> <span class=SpellE>programs</span>
<span class=SpellE>which</span> are <span class=SpellE>designed</span> to limit
<span class=SpellE>or</span> <span class=SpellE>destroy</span> <span
class=SpellE>the</span> <span class=SpellE>functionality</span> <span
class=SpellE>of</span> <span class=SpellE>computer</span> software <span
class=SpellE>or</span> hardware;<br>
<b>(j)</b>&nbsp;you <span class=SpellE>will</span> not use non-<span
class=SpellE>human</span> <span class=SpellE>or</span> <span class=SpellE>automated</span>
<span class=SpellE>bots</span> to login to <span class=SpellE>the</span> <span
class=SpellE>Service</span>;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.7 <span class=SpellE>Notification</span> <span class=SpellE>of</span>
Copyright <span class=SpellE>Infringement</span></span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(a)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;<span class=SpellE>The</span> <span class=SpellE>Company</span> <span
class=SpellE>respects</span> <span class=SpellE>the</span> <span class=SpellE>intellectual</span>
<span class=SpellE>property</span> <span class=SpellE>rights</span> <span
class=SpellE>of</span> <span class=SpellE>others</span>. <span class=SpellE>If</span>
you <span class=SpellE>encounter</span> <span class=SpellE>content</span> <span
class=SpellE>displayed</span> on <span class=SpellE>the</span> <span
class=SpellE>Website</span> and <span class=SpellE>Related</span> mobile <span
class=SpellE>application</span> <span class=SpellE>that</span> you <span
class=SpellE>suspect</span> <span class=SpellE>belongs</span> to you <span
class=SpellE>or</span> a <span class=SpellE>third</span> party, and <span
class=SpellE>that</span> <span class=SpellE>content</span> <span class=SpellE>is</span>
<span class=SpellE>being</span> <span class=SpellE>displayed</span> in a <span
class=SpellE>manner</span> <span class=SpellE>which</span> <span class=SpellE>violates</span>
<span class=SpellE>or</span> <span class=SpellE>appears</span> to <span
class=SpellE>violate</span> <span class=SpellE>your</span> copyright <span
class=SpellE>or</span> <span class=SpellE>other</span> <span class=SpellE>intellectual</span>
<span class=SpellE>property</span> <span class=SpellE>right</span> <span
class=SpellE>or</span> <span class=SpellE>an</span> <span class=SpellE>intellectual</span>
<span class=SpellE>property</span> <span class=SpellE>right</span> <span
class=SpellE>belonging</span> to a <span class=SpellE>third</span> party, you <span
class=SpellE>may</span> <span class=SpellE>submit</span> a <span class=SpellE>notification</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>Notices</span></span><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'> <span class=SpellE>may</span>
<span class=SpellE>be</span> <span class=SpellE>sent</span> to:<br>
</span><span style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-fareast-language:CS'>Email:&nbsp;</span><a
href="mailto:info@e-see-you.cz"><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:windowtext;mso-fareast-language:
CS'>info@e-see-you.cz</span></a><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>5. <span class=SpellE>Information</span></span></b><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>5.1 Monitor <span class=SpellE>information</span></span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>We</span></span><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'> <span class=SpellE>reserve</span> <span
class=SpellE>the</span> <span class=SpellE>right</span> to monitor <span
class=SpellE>all</span> <span class=SpellE>profiles</span>, to <span
class=SpellE>ensure</span> <span class=SpellE>that</span> <span class=SpellE>they</span>
<span class=SpellE>conform</span> to <span class=SpellE>the</span> <span
class=SpellE>requirements</span> <span class=SpellE>of</span> these <span
class=SpellE>Terms</span> <span class=SpellE>of</span> Use.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>5.2 <span class=SpellE>Security</span> <span class=SpellE>of</span> <span
class=SpellE>information</span></span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>No data <span class=SpellE>transmission</span> <span class=SpellE>over</span>
<span class=SpellE>the</span> internet <span class=SpellE>can</span> <span
class=SpellE>be</span> <span class=SpellE>guaranteed</span> as <span
class=SpellE>being</span> <span class=SpellE>totally</span> <span class=SpellE>secure</span>.
<span class=SpellE>Whilst</span> <span class=SpellE>we</span> <span
class=SpellE>strive</span> to <span class=SpellE>protect</span> such <span
class=SpellE>information</span>, <span class=SpellE>we</span> do not warrant
and <span class=SpellE>cannot</span> <span class=SpellE>ensure</span> <span
class=SpellE>the</span> <span class=SpellE>security</span> <span class=SpellE>of</span>
any <span class=SpellE>information</span> <span class=SpellE>which</span> you <span
class=SpellE>transmit</span> to <span class=SpellE>us</span>. <span
class=SpellE>Accordingly</span>, you <span class=SpellE>expressly</span> <span
class=SpellE>acknowledge</span> any <span class=SpellE>information</span> <span
class=SpellE>which</span> you <span class=SpellE>transmit</span> to <span
class=SpellE>us</span> <span class=SpellE>is</span> <span class=SpellE>transmitted</span>
<span class=SpellE>at</span> <span class=SpellE>your</span> <span class=SpellE>own</span>
risk.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>6. <span class=SpellE>Intellectual</span> <span class=SpellE>property</span>
<span class=SpellE>ownership</span></span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>The</span></span><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'> <span class=SpellE>Company</span> <span
class=SpellE>retains</span> <span class=SpellE>all</span> <span class=SpellE>rights</span>,
<span class=SpellE>titles</span>, and <span class=SpellE>interests</span> in <span
class=SpellE>the</span> <span class=SpellE>Service</span> and <span
class=SpellE>the</span> <span class=SpellE>Website</span> and <span
class=SpellE>Related</span> mobile <span class=SpellE>application</span> and <span
class=SpellE>the</span> <span class=SpellE>corresponding</span> <span
class=SpellE>intellectual</span> <span class=SpellE>property</span> <span
class=SpellE>rights</span> and <span class=SpellE>reserves</span> <span
class=SpellE>all</span> <span class=SpellE>rights</span> not <span
class=SpellE>expressly</span> <span class=SpellE>granted</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>7. <span class=SpellE>Linked</span> <span class=SpellE>websites</span></span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>The</span></span><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'> <span class=SpellE>Website</span> and <span
class=SpellE>Related</span> mobile <span class=SpellE>application</span> <span
class=SpellE>may</span> <span class=SpellE>contain</span> <span class=SpellE>links</span>
to <span class=SpellE>other</span> <span class=SpellE>websites</span> (&quot;<span
class=SpellE>Linked</span> <span class=SpellE>Website</span>&quot;). <span
class=SpellE>Those</span> <span class=SpellE>links</span> are <span
class=SpellE>provided</span> <span class=SpellE>for</span> <span class=SpellE>convenience</span>
<span class=SpellE>only</span> and <span class=SpellE>may</span> not <span
class=SpellE>remain</span> <span class=SpellE>current</span> <span
class=SpellE>or</span> <span class=SpellE>be</span> <span class=SpellE>maintained</span>.
<span class=SpellE>We</span> are not <span class=SpellE>responsible</span> <span
class=SpellE>for</span> <span class=SpellE>the</span> <span class=SpellE>content</span>
<span class=SpellE>or</span> <span class=SpellE>privacy</span> <span
class=SpellE>practices</span> <span class=SpellE>associated</span> <span
class=SpellE>with</span> <span class=SpellE>Linked</span> <span class=SpellE>Websites</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>8 No <span class=SpellE>warranties</span> as to <span class=SpellE>availability</span><o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>E-see-you <span class=SpellE>does</span> not make any <span class=SpellE>representations</span>
<span class=SpellE>or</span> <span class=SpellE>warranties</span> <span
class=SpellE>that</span> <span class=SpellE>the</span> <span class=SpellE>material</span>
<span class=SpellE>or</span> <span class=SpellE>information</span> <span
class=SpellE>provided</span> <span class=SpellE>through</span> <span
class=SpellE>the</span> <span class=SpellE>Service</span> <span class=SpellE>or</span>
on <span class=SpellE>the</span> <span class=SpellE>Website</span> and <span
class=SpellE>Related</span> mobile <span class=SpellE>application</span> (<span
class=SpellE>including</span> any <span class=SpellE>member</span> profile, <span
class=SpellE>advice</span>, <span class=SpellE>opinion</span>, <span
class=SpellE>statement</span> <span class=SpellE>or</span> <span class=SpellE>other</span>
<span class=SpellE>information</span> <span class=SpellE>displayed</span>, <span
class=SpellE>uploaded</span> <span class=SpellE>or</span> <span class=SpellE>distributed</span>
by <span class=SpellE>the</span> <span class=SpellE>Company</span> <span
class=SpellE>or</span> any <span class=SpellE>member</span> <span class=SpellE>or</span>
any <span class=SpellE>other</span> person <span class=SpellE>or</span> entity)
<span class=SpellE>is</span> <span class=SpellE>reliable</span>, <span
class=SpellE>accurate</span> <span class=SpellE>or</span> <span class=SpellE>complete</span>
<span class=SpellE>or</span> <span class=SpellE>that</span> <span class=SpellE>your</span>
<span class=SpellE>access</span> to <span class=SpellE>the</span> <span
class=SpellE>Service</span> <span class=SpellE>or</span> <span class=SpellE>the</span>
<span class=SpellE>Website</span> and <span class=SpellE>Related</span> mobile <span
class=SpellE>application</span> <span class=SpellE>will</span> <span
class=SpellE>be</span> <span class=SpellE>uninterrupted</span>, <span
class=SpellE>timely</span> <span class=SpellE>or</span> <span class=SpellE>secure</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>9. <span class=SpellE>Specific</span> <span class=SpellE>warnings</span> <span
class=SpellE>regarding</span> online <span class=SpellE>dating</span></span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>9.1 <span class=SpellE>Risks</span></span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>You <span class=SpellE>acknowledge</span> <span class=SpellE>that</span> <span
class=SpellE>there</span> are <span class=SpellE>risks</span> <span
class=SpellE>involved</span> in online <span class=SpellE>dating</span>, <span
class=SpellE>including</span> <span class=SpellE>without</span> <span
class=SpellE>limitation</span>, <span class=SpellE>unknowingly</span> <span
class=SpellE>dealing</span> <span class=SpellE>with</span> <span class=SpellE>underage</span>
<span class=SpellE>persons</span> <span class=SpellE>or</span> <span
class=SpellE>persons</span> <span class=SpellE>acting</span> <span
class=SpellE>under</span> a <span class=SpellE>false</span> pretense <span
class=SpellE>or</span> <span class=SpellE>for</span> a <span class=SpellE>criminal</span>
<span class=SpellE>purpose</span>. You <span class=SpellE>agree</span> to <span
class=SpellE>take</span> <span class=SpellE>all</span> <span class=SpellE>necessary</span>
<span class=SpellE>precautions</span> <span class=SpellE>when</span> <span
class=SpellE>communicating</span> <span class=SpellE>with</span> <span
class=SpellE>or</span> meeting <span class=SpellE>with</span> <span
class=SpellE>other</span> <span class=SpellE>users</span>, <span class=SpellE>especially</span>
<span class=SpellE>if</span> you <span class=SpellE>decide</span> to meet in
person. In <span class=SpellE>addition</span>, you <span class=SpellE>agree</span>
to <span class=SpellE>review</span> <span class=SpellE>our</span> online <span
class=SpellE>dating</span> <span class=SpellE>safety</span> <span class=SpellE>tips</span>
prior to <span class=SpellE>using</span> <span class=SpellE>the</span> <span
class=SpellE>service</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>9.2 No warranty as to identity <span class=SpellE>of</span> <span
class=SpellE>members</span></span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>You <span class=SpellE>acknowledge</span> <span class=SpellE>that</span>
user <span class=SpellE>authentication</span> on <span class=SpellE>the</span>
internet <span class=SpellE>is</span> <span class=SpellE>extremely</span> <span
class=SpellE>difficult</span>. <span class=SpellE>The</span> <span
class=SpellE>Company</span> <span class=SpellE>cannot</span> and <span
class=SpellE>does</span> not warrant <span class=SpellE>that</span> <span
class=SpellE>each</span> <span class=SpellE>member</span> <span class=SpellE>or</span>
user <span class=SpellE>of</span> <span class=SpellE>the</span> <span
class=SpellE>Service</span> <span class=SpellE>is</span> <span class=SpellE>who</span>
he <span class=SpellE>or</span> <span class=SpellE>she</span> <span
class=SpellE>claims</span> to <span class=SpellE>be</span>. <span class=SpellE>Further</span>,
<span class=SpellE>the</span> <span class=SpellE>Company</span> <span
class=SpellE>cannot</span> and <span class=SpellE>does</span> not warrant <span
class=SpellE>that</span> <span class=SpellE>member</span> <span class=SpellE>profiles</span>
are <span class=SpellE>reliable</span>, <span class=SpellE>accurate</span> <span
class=SpellE>or</span> <span class=SpellE>complete</span>. <span class=SpellE>Accordingly</span>,
you <span class=SpellE>must</span> <span class=SpellE>be</span> <span
class=SpellE>careful</span> in <span class=SpellE>dealing</span> <span
class=SpellE>with</span> <span class=SpellE>other</span> <span class=SpellE>members</span>
<span class=SpellE>or</span> <span class=SpellE>users</span> <span
class=SpellE>of</span> <span class=SpellE>the</span> <span class=SpellE>Service</span>
<span class=SpellE>or</span> <span class=SpellE>the</span> <span class=SpellE>Website</span>
and <span class=SpellE>Related</span> mobile <span class=SpellE>application</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>10. <span class=SpellE>Termination</span></span></b><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>10.1 <span class=SpellE>Agreement</span> <span class=SpellE>effective</span>
<span class=SpellE>for</span> <span class=SpellE>members</span></span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>This</span></span><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'> <span class=SpellE>Agreement</span>
<span class=SpellE>will</span> <span class=SpellE>remain</span> in full <span
class=SpellE>force</span> and <span class=SpellE>effect</span> <span
class=SpellE>while</span> you are a <span class=SpellE>member</span> <span
class=SpellE>of</span> <span class=SpellE>the</span> <span class=SpellE>Service</span>
<span class=SpellE>or</span> <span class=SpellE>while</span> you <span
class=SpellE>otherwise</span> use <span class=SpellE>the</span> <span
class=SpellE>Website</span> and <span class=SpellE>Related</span> mobile <span
class=SpellE>application</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>10.2 <span class=SpellE>Termination</span> <span class=SpellE>of</span> <span
class=SpellE>membership</span> by you</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>You <span class=SpellE>may</span> <span class=SpellE>terminate</span> <span
class=SpellE>your</span> <span class=SpellE>membership</span> <span
class=SpellE>at</span> any <span class=SpellE>time</span>, <span class=SpellE>for</span>
any <span class=SpellE>reason</span>, <span class=SpellE>effective</span> <span
class=SpellE>immediately</span> <span class=SpellE>upon</span> <span
class=SpellE>the</span> <span class=SpellE>cancelation</span> <span
class=SpellE>of</span> <span class=SpellE>your</span> E-see-you <span
class=SpellE>account</span> on <span class=SpellE>the</span> Web <span
class=SpellE>site</span> <span class=SpellE>or</span> in <span class=SpellE>the</span>
<span class=SpellE>Related</span> mobile <span class=SpellE>application</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>10.3 <span class=SpellE>Termination</span> <span class=SpellE>of</span> <span
class=SpellE>membership</span> by <span class=SpellE>us</span></span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>We</span></span><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'> <span class=SpellE>may</span> <span
class=SpellE>immediately</span> <span class=SpellE>terminate</span> <span
class=SpellE>your</span> <span class=SpellE>membership</span> and <span
class=SpellE>your</span> <span class=SpellE>access</span> to <span
class=SpellE>the</span> <span class=SpellE>Service</span> <span class=SpellE>at</span>
any <span class=SpellE>time</span> <span class=SpellE>if</span> <span
class=SpellE>we</span> <span class=SpellE>determine</span> (in <span
class=SpellE>our</span> <span class=SpellE>absolute</span> <span class=SpellE>discretion</span>)
<span class=SpellE>that</span> you <span class=SpellE>have</span> <span
class=SpellE>breached</span> <span class=SpellE>this</span> <span class=SpellE>Agreement</span>.
<span class=SpellE>Notice</span> <span class=SpellE>of</span> <span
class=SpellE>termination</span> <span class=SpellE>will</span> <span
class=SpellE>be</span> <span class=SpellE>delivered</span> to <span
class=SpellE>the</span> last email <span class=SpellE>address</span> you <span
class=SpellE>provide</span> to <span class=SpellE>us</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>10.4 <span class=SpellE>Deactivation</span> <span class=SpellE>for</span>
non-use</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>E-see-you <span class=SpellE>may</span> <span class=SpellE>deactivate</span>
<span class=SpellE>your</span> <span class=SpellE>account</span> <span
class=SpellE>if</span> you <span class=SpellE>have</span> not <span
class=SpellE>used</span> <span class=SpellE>the</span> <span class=SpellE>Service</span>
<span class=SpellE>for</span> a <span class=SpellE>consecutive</span> 12 <span
class=SpellE>months</span> period.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>11. General</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>11.1 <span class=SpellE>Failure</span> to <span class=SpellE>Comply</span></span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>The</span></span><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'> <span class=SpellE>Company</span> <span
class=SpellE>accepts</span> no <span class=SpellE>liability</span> <span
class=SpellE>for</span> any <span class=SpellE>failure</span> to <span
class=SpellE>comply</span> <span class=SpellE>with</span> these <span
class=SpellE>Terms</span> <span class=SpellE>of</span> Use <span class=SpellE>where</span>
such <span class=SpellE>failure</span> <span class=SpellE>is</span> <span
class=SpellE>due</span> to <span class=SpellE>circumstances</span> <span
class=SpellE>beyond</span> <span class=SpellE>our</span> <span class=SpellE>reasonable</span>
<span class=SpellE>control</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>11.2 <span class=SpellE>Language</span> <span class=SpellE>of</span> <span
class=SpellE>Agreement</span></span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>The</span></span><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'> <span class=SpellE>language</span> <span
class=SpellE>of</span> <span class=SpellE>this</span> <span class=SpellE>Agreement</span>
<span class=SpellE>is</span> Czech. <span class=SpellE>Where</span> <span
class=SpellE>the</span> <span class=SpellE>Company</span> has <span
class=SpellE>provided</span> a <span class=SpellE>translation</span> to <span
class=SpellE>the</span> <span class=SpellE>English</span> <span class=SpellE>version</span>
<span class=SpellE>of</span> <span class=SpellE>this</span> <span class=SpellE>Agreement</span>,
you <span class=SpellE>agree</span> <span class=SpellE>that</span> <span
class=SpellE>the</span> <span class=SpellE>translation</span> <span
class=SpellE>is</span> <span class=SpellE>provided</span> <span class=SpellE>for</span>
<span class=SpellE>your</span> <span class=SpellE>convenience</span> <span
class=SpellE>only</span>. <span class=SpellE>If</span> <span class=SpellE>there</span>
<span class=SpellE>is</span> any <span class=SpellE>inconsistency</span> <span
class=SpellE>between</span> <span class=SpellE>the</span> Czech <span
class=SpellE>language</span> <span class=SpellE>version</span> <span
class=SpellE>of</span> <span class=SpellE>this</span> <span class=SpellE>Agreement</span>
and a <span class=SpellE>translation</span>, <span class=SpellE>the</span> Czech
<span class=SpellE>language</span> <span class=SpellE>version</span> <span
class=SpellE>will</span> <span class=SpellE>prevail</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;margin-bottom:0cm;line-height:
normal'><span class=SpellE><b style='mso-bidi-font-weight:normal'><span
style='font-size:16.0pt;mso-bidi-font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Privacy</span></b></span><b style='mso-bidi-font-weight:normal'><span
style='font-size:16.0pt;mso-bidi-font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'> <span class=SpellE>Statement</span><o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-bottom-alt:auto;line-height:normal'><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'>(Last <span
class=SpellE>Updated</span> 14<sup>th</sup> July 2023)<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>1 General</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>We</span></span><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'> are <span class=SpellE>committed</span>
to <span class=SpellE>protecting</span> user <span class=SpellE>privacy</span>.
<span class=SpellE>We</span> <span class=SpellE>understand</span> <span
class=SpellE>that</span> <span class=SpellE>visitors</span> and <span
class=SpellE>users</span> <span class=SpellE>of</span> <span class=SpellE>the</span>
<span class=SpellE>Website</span> and <span class=SpellE>the</span> <span
class=SpellE>Related</span> <span class=SpellE>mobie</span> <span class=SpellE>application</span>
are <span class=SpellE>concerned</span> <span class=SpellE>about</span> <span
class=SpellE>their</span> <span class=SpellE>privacy</span>, and <span
class=SpellE>the</span> <span class=SpellE>confidentiality</span> and <span
class=SpellE>security</span> <span class=SpellE>of</span> any <span
class=SpellE>Personal</span> Data <span class=SpellE>that</span> <span
class=SpellE>is</span> <span class=SpellE>provided</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>We</span></span><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'> <span class=SpellE>will</span> <span
class=SpellE>only</span> <span class=SpellE>collect</span>, use and <span
class=SpellE>disclose</span> <span class=SpellE>Personal</span> Data in <span
class=SpellE>accordance</span> <span class=SpellE>with</span> <span
class=SpellE>this</span> <span class=SpellE>Privacy</span> <span class=SpellE>Statement</span>
and <span class=SpellE>the</span> <span class=SpellE>laws</span> <span
class=SpellE>applicable</span> in <span class=SpellE>the</span> <span
class=SpellE>jurisdictions</span> in <span class=SpellE>which</span> <span
class=SpellE>we</span> are <span class=SpellE>collecting</span> <span
class=SpellE>Personal</span> Data. <span class=SpellE>If</span> <span
class=SpellE>there</span> <span class=SpellE>is</span> any <span class=SpellE>inconsistency</span>
<span class=SpellE>between</span> <span class=SpellE>this</span> <span
class=SpellE>Privacy</span> <span class=SpellE>Statement</span> and <span
class=SpellE>an</span> <span class=SpellE>applicable</span> <span class=SpellE>law</span>,
<span class=SpellE>the</span> <span class=SpellE>relevant</span> <span
class=SpellE>law</span> <span class=SpellE>will</span> <span class=SpellE>prevail</span>
to <span class=SpellE>the</span> <span class=SpellE>extent</span> <span
class=SpellE>of</span> any <span class=SpellE>inconsistency</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>2 <span class=SpellE>Basis</span> <span class=SpellE>for</span> <span
class=SpellE>Collection</span></span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>This</span></span><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'> <span class=SpellE>Privacy</span>
<span class=SpellE>Policy</span> <span class=SpellE>sets</span> out <span
class=SpellE>our</span> <span class=SpellE>policies</span> <span class=SpellE>relating</span>
to <span class=SpellE>the</span> <span class=SpellE>collection</span> and use <span
class=SpellE>of</span> <span class=SpellE>your</span> <span class=SpellE>Personal</span>
Data <span class=SpellE>while</span> you use <span class=SpellE>the</span> <span
class=SpellE>Website</span> and <span class=SpellE>the</span> <span
class=SpellE>Related</span> <span class=SpellE>mobie</span> <span class=SpellE>application</span>
<span class=SpellE>operated</span> by E-see-you <span class=GramE>Ltd..</span> <span
class=SpellE>Words</span> <span class=SpellE>that</span> are <span
class=SpellE>capitalized</span>, such as &quot;<span class=SpellE>Personal</span>
Data&quot;, are <span class=SpellE>defined</span> in <span class=SpellE>clause</span>
1.8 <span class=SpellE>of</span> <span class=SpellE>this</span> <span
class=SpellE>policy</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>2.1 <span class=SpellE>Contractual</span> <span class=SpellE>services</span></span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>Where</span></span><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'> you <span
class=SpellE>have</span> <span class=SpellE>created</span> <span class=SpellE>an</span>
<span class=SpellE>account</span> <span class=SpellE>through</span> <span
class=SpellE>our</span> <span class=SpellE>Website</span> and <span
class=SpellE>the</span> <span class=SpellE>Related</span> <span class=SpellE>mobie</span>
<span class=SpellE>application</span>, by E-see-you Ltd, and <span
class=SpellE>requested</span> <span class=SpellE>that</span> <span
class=SpellE>we</span> <span class=SpellE>provide</span> <span class=SpellE>services</span>
to you, <span class=SpellE>we</span> <span class=SpellE>will</span> <span
class=SpellE>need</span> to <span class=SpellE>collect</span> and use <span
class=SpellE>your</span> <span class=SpellE>Personal</span> Data to enter <span
class=SpellE>into</span> <span class=SpellE>our</span> <span class=SpellE>agreement</span>
<span class=SpellE>with</span> you and<span style='background:#F8F8F8'>&nbsp;<span
class=SpellE>deliver</span> to you <span class=SpellE>our</span> <span
class=SpellE>services</span> <span class=SpellE>that</span> are <span
class=SpellE>available</span> <span class=SpellE>through</span> <span
class=SpellE>our</span> <span class=SpellE>Website</span> and <span
class=SpellE>the</span> <span class=SpellE>Related</span> <span class=SpellE>mobie</span>
<span class=SpellE>application</span>.</span></span><span style='font-size:
12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>2.2 <span class=SpellE>Legitimate</span> Business <span class=SpellE>Purposes</span></span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>Where</span></span><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'> <span class=SpellE>we</span>
are not <span class=SpellE>collecting</span> and <span class=SpellE>using</span>
<span class=SpellE>your</span> <span class=SpellE>Personal</span> Data <span
class=SpellE>for</span> <span class=SpellE>the</span> <span class=SpellE>purposes</span>
<span class=SpellE>of</span> <span class=SpellE>entering</span> <span
class=SpellE>into</span> a <span class=SpellE>contract</span> <span
class=SpellE>with</span> you, <span class=SpellE>or</span> to <span
class=SpellE>deliver</span> to you <span class=SpellE>our</span> <span
class=SpellE>services</span>, <span class=SpellE>we</span> <span class=SpellE>may</span>
<span class=SpellE>still</span> <span class=SpellE>collect</span> and use <span
class=SpellE>your</span> <span class=SpellE>Personal</span> Data <span
class=SpellE>where</span> <span class=SpellE>we</span> <span class=SpellE>consider</span>:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(i)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;you <span class=SpellE>will</span> not <span class=SpellE>be</span> <span
class=SpellE>detrimentally</span> <span class=SpellE>impacted</span> by <span
class=SpellE>our</span> <span class=SpellE>collection</span> and use;<br>
<b>(<span class=SpellE>ii</span>)</b>&nbsp;such <span class=SpellE>collection</span>
and use <span class=SpellE>is</span> in <span class=SpellE>accordance</span> <span
class=SpellE>with</span> <span class=SpellE>what</span> you <span class=SpellE>would</span>
<span class=SpellE>reasonably</span> <span class=SpellE>expect</span> in <span
class=SpellE>the</span> <span class=SpellE>context</span> <span class=SpellE>of</span>
<span class=SpellE>our</span> use <span class=SpellE>of</span> <span
class=SpellE>your</span> <span class=SpellE>Personal</span> Data; and<br>
<b>(<span class=SpellE>iii</span>)</b>&nbsp;<span class=SpellE>it</span> <span
class=SpellE>is</span> <span class=SpellE>necessary</span> to <span
class=SpellE>fulfill</span> <span class=SpellE>our</span> <span class=SpellE>legitimate</span>
<span class=SpellE>interests</span>.<br>
<br>
<span class=SpellE>This</span> <span class=SpellE>may</span> <span
class=SpellE>include</span>, <span class=SpellE>for</span> <span class=SpellE>example</span>,
use <span class=SpellE>of</span> <span class=SpellE>your</span> <span
class=SpellE>Personal</span> Data to <span class=SpellE>improve</span> <span
class=SpellE>our</span> <span class=SpellE>Website</span> and <span
class=SpellE>the</span> <span class=SpellE>Related</span> <span class=SpellE>mobie</span>
<span class=SpellE>application</span> <span class=SpellE>or</span> <span
class=SpellE>the</span> <span class=SpellE>range</span> <span class=SpellE>of</span>
<span class=SpellE>services</span> <span class=SpellE>that</span> <span
class=SpellE>we</span> <span class=SpellE>offer</span> to you.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>2.3 <span class=SpellE>Legal</span> <span class=SpellE>compliance</span></span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>There</span></span><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'> are <span
class=SpellE>certain</span> <span class=SpellE>situations</span> in <span
class=SpellE>which</span> <span class=SpellE>we</span> <span class=SpellE>may</span>
<span class=SpellE>be</span> <span class=SpellE>required</span> to <span
class=SpellE>process</span> <span class=SpellE>your</span> <span class=SpellE>Personal</span>
Data in <span class=SpellE>order</span> to <span class=SpellE>comply</span> <span
class=SpellE>with</span> <span class=SpellE>the</span> <span class=SpellE>law</span>,
<span class=SpellE>for</span> <span class=SpellE>example</span> <span
class=SpellE>if</span> <span class=SpellE>we</span> are <span class=SpellE>subject</span>
to <span class=SpellE>an</span> <span class=SpellE>order</span> <span
class=SpellE>of</span> <span class=SpellE>the</span> <span class=SpellE>Court</span>
<span class=SpellE>or</span> a <span class=SpellE>regulatory</span> <span
class=SpellE>authority</span> <span class=SpellE>requiring</span> such <span
class=SpellE>processing</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>2.4 <span class=SpellE>Your</span> <span class=SpellE>consent</span></span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>By <span class=SpellE>accessing</span> and <span class=SpellE>using</span> <span
class=SpellE>the</span> <span class=SpellE>Website</span> and <span
class=SpellE>the</span> <span class=SpellE>Related</span> <span class=SpellE>mobie</span>
<span class=SpellE>application</span> you are <span class=SpellE>consenting</span>
to <span class=SpellE>the</span> <span class=SpellE>collection</span> and use <span
class=SpellE>of</span> <span class=SpellE>your</span> <span class=SpellE>Personal</span>
Data in <span class=SpellE>accordance</span> <span class=SpellE>with</span> <span
class=SpellE>the</span> <span class=SpellE>terms</span> <span class=SpellE>of</span>
<span class=SpellE>this</span> <span class=SpellE>Privacy</span> <span
class=SpellE>Statement</span>. By <span class=SpellE>registering</span> <span
class=SpellE>an</span> <span class=SpellE>account</span> <span class=SpellE>or</span>
<span class=SpellE>logging</span> on as a <span class=SpellE>member</span> on <span
class=SpellE>the</span> <span class=SpellE>Website</span> and <span
class=SpellE>the</span> <span class=SpellE>Related</span> <span class=SpellE>mobie</span>
<span class=SpellE>application</span>, you are <span class=SpellE>consenting</span>
to <span class=SpellE>the</span> <span class=SpellE>collection</span> and use <span
class=SpellE>of</span> <span class=SpellE>your</span> <span class=SpellE>Personal</span>
Data.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>You <span class=SpellE>may</span> <span class=SpellE>withdraw</span> <span
class=SpellE>your</span> <span class=SpellE>consent</span> to <span
class=SpellE>our</span> <span class=SpellE>collection</span> and use <span
class=SpellE>of</span> <span class=SpellE>your</span> <span class=SpellE>Personal</span>
Data <span class=SpellE>at</span> any <span class=SpellE>time</span> by <span
class=SpellE>ceasing</span> to use <span class=SpellE>the</span> <span
class=SpellE>relevant</span> <span class=SpellE>Website</span> and <span
class=SpellE>the</span> <span class=SpellE>Related</span> <span class=SpellE>mobie</span>
<span class=SpellE>application</span>. <span class=SpellE>If</span> you are a <span
class=SpellE>member</span> <span class=SpellE>that</span> has <span
class=SpellE>created</span> <span class=SpellE>an</span> <span class=SpellE>account</span>,
you <span class=SpellE>may</span> <span class=SpellE>withdraw</span> <span
class=SpellE>your</span> <span class=SpellE>consent</span> to <span
class=SpellE>our</span> <span class=SpellE>collection</span> and use <span
class=SpellE>of</span> <span class=SpellE>your</span> <span class=SpellE>Personal</span>
Data by <span class=SpellE>ceasing</span> to use <span class=SpellE>your</span>
<span class=SpellE>account</span> and <span class=SpellE>making</span> a <span
class=SpellE>request</span> <span class=SpellE>for</span> <span class=SpellE>your</span>
<span class=SpellE>account</span> to <span class=SpellE>be</span> <span
class=SpellE>suspended</span> <span class=SpellE>or</span> <span class=SpellE>deleted</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>3 <span class=SpellE>Collection</span> <span class=SpellE>of</span> data</span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>3.1 All <span class=SpellE>users</span></span></b><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>Whenever</span></span><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'> you (<span
class=SpellE>including</span> non-<span class=SpellE>member</span> <span
class=SpellE>users</span>) visit <span class=SpellE>our</span> <span
class=SpellE>Website</span> and <span class=SpellE>the</span> <span
class=SpellE>Related</span> <span class=SpellE>mobie</span> <span class=SpellE>application</span>,
<span class=SpellE>our</span> <span class=SpellE>servers</span> <span
class=SpellE>automatically</span> <span class=SpellE>record</span> <span
class=SpellE>information</span> <span class=SpellE>for</span> <span
class=SpellE>statistical</span> <span class=SpellE>purposes</span> <span
class=SpellE>about</span> <span class=SpellE>your</span> <span class=SpellE>usage</span>
<span class=SpellE>of</span> <span class=SpellE>the</span> <span class=SpellE>Website</span>
and <span class=SpellE>the</span> <span class=SpellE>Related</span> <span
class=SpellE>mobie</span> <span class=SpellE>application</span> such as:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(i)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;<span class=SpellE>the</span> type <span class=SpellE>of</span>
browser <span class=SpellE>used</span>;<br>
<b>(<span class=SpellE>ii</span>)</b>&nbsp;<span class=SpellE>the</span> <span
class=SpellE>referring</span> URL;<br>
<b>(<span class=SpellE>iii</span>)</b>&nbsp;<span class=SpellE>the</span> IP <span
class=SpellE>address</span>;<br>
<b>(<span class=SpellE>iv</span>)</b>&nbsp;<span class=SpellE>the</span> <span
class=SpellE>number</span> and type <span class=SpellE>of</span> <span
class=SpellE>pages</span> <span class=SpellE>viewed</span>;<br>
<b>(v)</b>&nbsp;<span class=SpellE>the</span> <span class=SpellE>date</span>
and <span class=SpellE>time</span> <span class=SpellE>of</span> <span
class=SpellE>visits</span>; and<br>
<b>(<span class=SpellE>vi</span>)</b>&nbsp;<span class=SpellE>the</span> exit
URL.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>This</span></span><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'> <span class=SpellE>information</span>
<span class=SpellE>we</span> <span class=SpellE>collect</span> <span
class=SpellE>about</span> you <span class=SpellE>is</span> not <span
class=SpellE>Personal</span> Data and, in any event, <span class=SpellE>remains</span>
<span class=SpellE>anonymous</span>. <span class=SpellE>We</span> do not link <span
class=SpellE>the</span> <span class=SpellE>anonymous</span> <span class=SpellE>information</span>
to any <span class=SpellE>other</span> <span class=SpellE>Personal</span> Data <span
class=SpellE>unless</span> you <span class=SpellE>have</span> <span
class=SpellE>either</span> <span class=SpellE>registered</span> as a <span
class=SpellE>member</span> <span class=SpellE>or</span> <span class=SpellE>logged</span>
on as a <span class=SpellE>member</span> <span class=SpellE>at</span> <span
class=SpellE>the</span> <span class=SpellE>time</span> <span class=SpellE>of</span>
use. <span class=SpellE>Accordingly</span>, <span class=SpellE>if</span> you <span
class=SpellE>have</span> not <span class=SpellE>identified</span> <span
class=SpellE>yourself</span> on <span class=SpellE>the</span> <span
class=SpellE>Website</span> and <span class=SpellE>the</span> <span
class=SpellE>Related</span> <span class=SpellE>mobie</span> <span class=SpellE>application</span>,
such as by <span class=SpellE>registering</span> as a <span class=SpellE>member</span>
<span class=SpellE>or</span> <span class=SpellE>logging</span> on as a <span
class=SpellE>member</span>, <span class=SpellE>we</span> <span class=SpellE>will</span>
not link a <span class=SpellE>record</span> <span class=SpellE>of</span> <span
class=SpellE>your</span> <span class=SpellE>usage</span> <span class=SpellE>of</span>
<span class=SpellE>our</span> <span class=SpellE>Website</span> and <span
class=SpellE>the</span> <span class=SpellE>Related</span> <span class=SpellE>mobie</span>
<span class=SpellE>application</span> to you <span class=SpellE>personally</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>3.2 <span class=SpellE>Members</span></span></b><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>Upon</span></span><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'> <span class=SpellE>your</span>
<span class=SpellE>registration</span> as a <span class=SpellE>member</span> <span
class=SpellE>we</span> <span class=SpellE>collect</span> <span class=SpellE>information</span>
<span class=SpellE>about</span> you in <span class=SpellE>order</span> to <span
class=SpellE>provide</span> you <span class=SpellE>with</span> <span
class=SpellE>the</span> full <span class=SpellE>benefits</span> <span
class=SpellE>of</span> <span class=SpellE>membership</span>. <span
class=SpellE>We</span> <span class=SpellE>collect</span> non-<span
class=SpellE>identifying</span> <span class=SpellE>information</span> as <span
class=SpellE>well</span> as <span class=SpellE>Personal</span> Data <span
class=SpellE>from</span> you <span class=SpellE>directly</span> <span
class=SpellE>when</span> you <span class=SpellE>first</span> <span
class=SpellE>register</span>, and <span class=SpellE>also</span> <span
class=SpellE>from</span> <span class=SpellE>time</span> to <span class=SpellE>time</span>
<span class=SpellE>thereafter</span> <span class=SpellE>if</span> you <span
class=SpellE>provide</span> <span class=SpellE>us</span> <span class=SpellE>with</span>
<span class=SpellE>additional</span> <span class=SpellE>information</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>Personal</span></span><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'> Data <span
class=SpellE>which</span> <span class=SpellE>may</span> <span class=SpellE>be</span>
<span class=SpellE>collected</span> by <span class=SpellE>the</span> <span
class=SpellE>Company</span> <span class=SpellE>includes</span>:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(i) <span class=SpellE>your</span> full <span class=SpellE>name</span>;<br>
(<span class=SpellE>ii</span>)</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>&nbsp;<span class=SpellE>your</span>
email <span class=SpellE>address</span>;<br>
<b>(v)</b>&nbsp;a <span class=SpellE>photograph</span> <span class=SpellE>of</span>
you;<br>
<b>(<span class=SpellE>vi</span>)</b>&nbsp;<span class=SpellE>your</span> <span
class=SpellE>date</span> <span class=SpellE>of</span> <span class=SpellE>birth</span><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>3.3 Cookies and <span class=SpellE>other</span> <span class=SpellE>tracking</span>
<span class=SpellE>technologies</span></span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>To <span class=SpellE>improve</span> <span class=SpellE>your</span> <span
class=SpellE>experience</span> on <span class=SpellE>our</span> <span
class=SpellE>Website</span> and <span class=SpellE>the</span> <span
class=SpellE>Related</span> <span class=SpellE>mobie</span> <span class=SpellE>application</span>,
<span class=SpellE>we</span> <span class=SpellE>may</span> use cookies and <span
class=SpellE>other</span> <span class=SpellE>tracking</span> <span
class=SpellE>technologies</span>, such as pixel <span class=SpellE>tags</span>,
web <span class=SpellE>beacons</span>, <span class=SpellE>device</span> <span
class=SpellE>IDs</span>, <span class=SpellE>or</span> <span class=SpellE>advertising</span>
<span class=SpellE>IDs</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>You <span class=SpellE>may</span> <span class=SpellE>refuse</span> <span
class=SpellE>the</span> use <span class=SpellE>of</span> cookies by <span
class=SpellE>selecting</span> <span class=SpellE>the</span> <span class=SpellE>appropriate</span>
<span class=SpellE>settings</span> on <span class=SpellE>your</span> browser, <span
class=SpellE>however</span> <span class=SpellE>please</span> <span
class=SpellE>note</span> <span class=SpellE>that</span> <span class=SpellE>if</span>
you do <span class=SpellE>this</span>, you <span class=SpellE>may</span> not <span
class=SpellE>be</span> <span class=SpellE>able</span> to use <span
class=SpellE>the</span> full <span class=SpellE>functionality</span> <span
class=SpellE>of</span> <span class=SpellE>our</span> <span class=SpellE>Website</span>
and <span class=SpellE>the</span> <span class=SpellE>Related</span> <span
class=SpellE>mobie</span> <span class=SpellE>application</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>Our</span></span><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'> <span class=SpellE>Website</span> and <span
class=SpellE>the</span> <span class=SpellE>Related</span> <span class=SpellE>mobie</span>
<span class=SpellE>application</span> <span class=SpellE>uses</span> Google <span
class=SpellE>Analytics</span>, a <span class=SpellE>service</span> <span
class=SpellE>which</span> <span class=SpellE>transmits</span> <span
class=SpellE>Website</span> and <span class=SpellE>the</span> <span
class=SpellE>Related</span> <span class=SpellE>mobie</span> <span class=SpellE>application</span>
<span class=SpellE>traffic</span> data to Google <span class=SpellE>servers</span>
in <span class=SpellE>the</span> United <span class=SpellE>States</span>.
Google <span class=SpellE>Analytics</span> <span class=SpellE>does</span> not <span
class=SpellE>identify</span> <span class=SpellE>individual</span> <span
class=SpellE>users</span> <span class=SpellE>or</span> <span class=SpellE>associate</span>
<span class=SpellE>your</span> IP <span class=SpellE>address</span> <span
class=SpellE>with</span> any <span class=SpellE>other</span> data <span
class=SpellE>held</span> by Google. <span class=SpellE>We</span> use <span
class=SpellE>reports</span> <span class=SpellE>provided</span> by Google <span
class=SpellE>Analytics</span> to <span class=SpellE>help</span> <span
class=SpellE>us</span> <span class=SpellE>understand</span> <span class=SpellE>Website</span>
and <span class=SpellE>the</span> <span class=SpellE>Related</span> <span
class=SpellE>mobie</span> <span class=SpellE>application</span> <span
class=SpellE>traffic</span> and web <span class=SpellE>page</span> <span
class=SpellE>usage</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>You <span class=SpellE>can</span> <span class=SpellE>customize</span> <span
class=SpellE>or</span> <span class=SpellE>opt</span> out <span class=SpellE>of</span>
Google <span class=SpellE>Analytics</span> <span class=SpellE>for</span>
Display <span class=SpellE>Advertising</span> <span class=SpellE>using</span> <span
class=SpellE>the</span> <span class=SpellE>Ads</span> <span class=SpellE>Settings</span>
<span class=SpellE>for</span> <span class=SpellE>your</span> Google <span
class=SpellE>account</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>Our</span></span><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'> <span class=SpellE>Website</span> and <span
class=SpellE>the</span> <span class=SpellE>Related</span> <span class=SpellE>mobie</span>
<span class=SpellE>application</span> <span class=SpellE>uses</span> cookies
and <span class=SpellE>other</span> <span class=SpellE>tracking</span> <span
class=SpellE>technologies</span> to support Google <span class=SpellE>Analytics</span>
<span class=SpellE>for</span>:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(i)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;Google Marketing <span class=SpellE>Platform</span> <span
class=SpellE>integrations</span>;<br>
<b>(<span class=SpellE>ii</span>)</b>&nbsp;Display <span class=SpellE>Advertising</span>
<span class=SpellE>including</span> <span class=SpellE>Remarketing</span> and
Google Display Network <span class=SpellE>Impression</span> Reporting to <span
class=SpellE>advertise</span> <span class=SpellE>our</span> <span class=SpellE>services</span>
online;<br>
<b>(<span class=SpellE>iii</span>)</b>&nbsp;<span class=SpellE>optimizing</span>
and <span class=SpellE>personalizing</span> ad <span class=SpellE>services</span>
<span class=SpellE>based</span> on <span class=SpellE>your</span> past <span
class=SpellE>visits</span> to <span class=SpellE>our</span> <span class=SpellE>Website</span>
and <span class=SpellE>the</span> <span class=SpellE>Related</span> <span
class=SpellE>mobie</span> <span class=SpellE>application</span>; and<br>
<b>(<span class=SpellE>iv</span>)</b>&nbsp;reporting <span class=SpellE>the</span>
use <span class=SpellE>of</span> ad <span class=SpellE>services</span> and <span
class=SpellE>interactions</span> <span class=SpellE>with</span> ad <span
class=SpellE>impressions</span> and <span class=SpellE>how</span> <span
class=SpellE>they</span> are <span class=SpellE>related</span> to <span
class=SpellE>visits</span> to <span class=SpellE>our</span> <span class=SpellE>site</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Cookies and <span class=SpellE>other</span> <span class=SpellE>tracking</span>
<span class=SpellE>technologies</span> <span class=SpellE>may</span> <span
class=SpellE>also</span> <span class=SpellE>be</span> <span class=SpellE>used</span>
to support <span class=SpellE>analytics</span> by <span class=SpellE>other</span>
<span class=SpellE>third</span> <span class=SpellE>parties</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>3.4 <span class=SpellE>Social</span> media</span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>The</span></span><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'> <span class=SpellE>Compaby</span> <span
class=SpellE>interfaces</span> <span class=SpellE>with</span> <span
class=SpellE>social</span> media <span class=SpellE>sites</span> such as
Facebook, YouTube, and Google <span class=SpellE>Maps</span>. <span
class=SpellE>If</span> you <span class=SpellE>choose</span> to &quot;<span
class=SpellE>like</span>&quot; <span class=SpellE>or</span> &quot;<span
class=SpellE>share</span>&quot; <span class=SpellE>information</span> <span
class=SpellE>from</span> <span class=SpellE>our</span> <span class=SpellE>Website</span>
and <span class=SpellE>the</span> <span class=SpellE>Related</span> <span
class=SpellE>mobie</span> <span class=SpellE>application</span> <span
class=SpellE>through</span> these <span class=SpellE>services</span>, you <span
class=SpellE>should</span> <span class=SpellE>review</span> <span class=SpellE>the</span>
<span class=SpellE>privacy</span> <span class=SpellE>policy</span> <span
class=SpellE>of</span> <span class=SpellE>the</span> <span class=SpellE>relevant</span>
<span class=SpellE>service</span>. <span class=SpellE>If</span> you are a <span
class=SpellE>member</span> <span class=SpellE>of</span> a <span class=SpellE>social</span>
media <span class=SpellE>site</span>, <span class=SpellE>the</span> <span
class=SpellE>interfaces</span> <span class=SpellE>may</span> <span
class=SpellE>allow</span> <span class=SpellE>the</span> <span class=SpellE>social</span>
media <span class=SpellE>site</span> to <span class=SpellE>connect</span> <span
class=SpellE>your</span> <span class=SpellE>visits</span> to <span
class=SpellE>this</span> <span class=SpellE>Website</span> and <span
class=SpellE>the</span> <span class=SpellE>Related</span> <span class=SpellE>mobie</span>
<span class=SpellE>application</span> <span class=SpellE>with</span> <span
class=SpellE>other</span> <span class=SpellE>Personal</span> Data.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4 <span class=SpellE>Purposes</span> <span class=SpellE>of</span> <span
class=SpellE>collecting</span>, holding, <span class=SpellE>using</span> and <span
class=SpellE>disclosing</span> <span class=SpellE>Personal</span> Data</span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.1 <span class=SpellE>Collect</span> <span class=SpellE>from</span> you <span
class=SpellE>only</span> </span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>Where</span></span><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'> <span class=SpellE>it</span>
<span class=SpellE>is</span> <span class=SpellE>reasonable</span> <span
class=SpellE>or</span> <span class=SpellE>practical</span> to do so <span
class=SpellE>we</span> <span class=SpellE>will</span> <span class=SpellE>only</span>
<span class=SpellE>collect</span> <span class=SpellE>Personal</span> Data <span
class=SpellE>about</span> you <span class=SpellE>from</span> you <span
class=SpellE>directly</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.2 <span class=SpellE>Purposes</span> <span class=SpellE>of</span> <span
class=SpellE>collecting</span> <span class=SpellE>Personal</span> Data</span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>We</span></span><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'> <span class=SpellE>will</span> <span
class=SpellE>only</span> <span class=SpellE>collect</span> <span class=SpellE>Personal</span>
Data <span class=SpellE>that</span> <span class=SpellE>is</span> <span
class=SpellE>reasonably</span> <span class=SpellE>necessary</span> <span
class=SpellE>for</span>:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(i)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;<span class=SpellE>enabling</span> <span class=SpellE>us</span> to <span
class=SpellE>process</span> <span class=SpellE>your</span> <span class=SpellE>application</span>
<span class=SpellE>for</span> <span class=SpellE>membership</span> and <span
class=SpellE>enabling</span> <span class=SpellE>us</span> to <span
class=SpellE>provide</span> <span class=SpellE>services</span> to you (<span
class=SpellE>including</span> <span class=SpellE>creating</span> <span
class=SpellE>your</span> <span class=SpellE>publicly</span> <span class=SpellE>viewable</span>
profile, <span class=SpellE>allowing</span> <span class=SpellE>members</span>
to <span class=SpellE>search</span> <span class=SpellE>for</span> <span
class=SpellE>suitable</span> <span class=SpellE>members</span> and <span
class=SpellE>providing</span> <span class=SpellE>mechanisms</span> <span
class=SpellE>for</span> <span class=SpellE>communication</span> <span
class=SpellE>between</span> <span class=SpellE>members</span>);<br>
<b>(<span class=SpellE>ii</span>)</b>&nbsp;<span class=SpellE>our</span> <span
class=SpellE>internal</span> <span class=SpellE>research</span> and <span
class=SpellE>statistical</span> <span class=SpellE>purposes</span> (<span
class=SpellE>including</span> market <span class=SpellE>segmentation</span> and
<span class=SpellE>customer</span> <span class=SpellE>value</span> <span
class=SpellE>analysis</span>);<br>
<b>(<span class=SpellE>iii</span>)</b>&nbsp;<span class=SpellE>enabling</span> <span
class=SpellE>us</span> to forward to you <span class=SpellE>other</span> <span
class=SpellE>information</span> <span class=SpellE>or</span> <span
class=SpellE>material</span> <span class=SpellE>which</span> <span
class=SpellE>we</span> <span class=SpellE>believe</span> <span class=SpellE>may</span>
<span class=SpellE>be</span> <span class=SpellE>of</span> <span class=SpellE>interest</span>
to you;<br>
<b>(<span class=SpellE>iv</span>)</b>&nbsp;any <span class=SpellE>other</span> <span
class=SpellE>purpose</span> <span class=SpellE>specified</span> in <span
class=SpellE>our</span> <span class=SpellE>Website</span> and <span
class=SpellE>the</span> <span class=SpellE>Related</span> <span class=SpellE>mobie</span>
<span class=SpellE>application</span> <span class=SpellE>Terms</span> <span
class=SpellE>of</span> Use <span class=SpellE>available</span> on <span
class=SpellE>our</span> <span class=SpellE>Website</span> and <span
class=SpellE>the</span> <span class=SpellE>Related</span> <span class=SpellE>mobie</span>
<span class=SpellE>application</span><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.3 Use and <span class=SpellE>disclosure</span> <span class=SpellE>of</span>
<span class=SpellE>Personal</span> Data</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Use <span class=SpellE>of</span> <span class=SpellE>Personal</span> Data <span
class=SpellE>for</span> <span class=SpellE>the</span> <span class=SpellE>Primary</span>
<span class=SpellE>Purpose</span> <span class=SpellE>will</span> <span
class=SpellE>include</span> <span class=SpellE>the</span> use <span
class=SpellE>of</span> <span class=SpellE>Personal</span> Data in <span
class=SpellE>automated</span> <span class=SpellE>decision-making</span> <span
class=SpellE>processes</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>In <span class=SpellE>order</span> to <span class=SpellE>promote</span> <span
class=SpellE>our</span> <span class=SpellE>products</span> and <span
class=SpellE>services</span> to you, <span class=SpellE>we</span> <span
class=SpellE>may</span> <span class=SpellE>also</span> <span class=SpellE>provide</span>
a <span class=SpellE>hash</span> <span class=SpellE>of</span> <span
class=SpellE>your</span> email <span class=SpellE>address</span> (and no <span
class=SpellE>other</span> <span class=SpellE>Personal</span> Data) to <span
class=SpellE>third</span> <span class=SpellE>parties</span>, such as Facebook
and <span class=SpellE>other</span> <span class=SpellE>companies</span> <span
class=SpellE>within</span> E-see-you, in <span class=SpellE>order</span> to <span
class=SpellE>better</span> <span class=SpellE>target</span> <span class=SpellE>our</span>
<span class=SpellE>advertising</span> to you, by <span class=SpellE>using</span>
<span class=SpellE>products</span> such as <span class=SpellE>Facebook's</span>
<span class=SpellE>Custom</span> <span class=SpellE>Audiences</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>A <span class=SpellE>comparison</span> <span class=SpellE>of</span> <span
class=SpellE>your</span> <span class=SpellE>hashed</span> email <span
class=SpellE>address</span> <span class=SpellE>will</span> <span class=SpellE>only</span>
<span class=SpellE>be</span> <span class=SpellE>for</span> <span class=SpellE>the</span>
<span class=SpellE>purpose</span> <span class=SpellE>of</span> <span
class=SpellE>promoting</span> <span class=SpellE>our</span> <span class=SpellE>goods</span>
and <span class=SpellE>services</span> to you and <span class=SpellE>will</span>
not <span class=SpellE>be</span> <span class=SpellE>used</span> by <span
class=SpellE>that</span> <span class=SpellE>third</span> party <span
class=SpellE>for</span> any <span class=SpellE>other</span> <span class=SpellE>purpose</span>.
<span class=SpellE>The</span> <span class=SpellE>comparison</span> <span
class=SpellE>of</span> <span class=SpellE>the</span> <span class=SpellE>hash</span>
<span class=SpellE>of</span> <span class=SpellE>your</span> email <span
class=SpellE>address</span> <span class=SpellE>will</span> not <span
class=SpellE>be</span> <span class=SpellE>used</span> to <span class=SpellE>identify</span>
you <span class=SpellE>or</span> <span class=SpellE>your</span> email <span
class=SpellE>address</span>, and no <span class=SpellE>further</span> <span
class=SpellE>information</span> <span class=SpellE>about</span> you <span
class=SpellE>will</span> <span class=SpellE>be</span> <span class=SpellE>provided</span>
by <span class=SpellE>us</span> to <span class=SpellE>that</span> <span
class=SpellE>third</span> party. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.4 Holding <span class=SpellE>of</span> <span class=SpellE>Personal</span>
Data</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>We</span></span><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'> <span class=SpellE>will</span> use <span
class=SpellE>best</span> <span class=SpellE>practice</span> <span class=SpellE>processes</span>
and <span class=SpellE>take</span> <span class=SpellE>all</span> <span
class=SpellE>reasonable</span> <span class=SpellE>steps</span> to <span
class=SpellE>protect</span> <span class=SpellE>Personal</span> Data <span
class=SpellE>that</span> <span class=SpellE>we</span> hold <span class=SpellE>from</span>:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(i)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;<span class=SpellE>misuse</span>, interference, and <span
class=SpellE>loss</span>; and<br>
<b>(<span class=SpellE>ii</span>)</b>&nbsp;<span class=SpellE>unauthorized</span>
<span class=SpellE>access</span>, <span class=SpellE>modification</span>, <span
class=SpellE>or</span> <span class=SpellE>disclosure</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>In <span class=SpellE>the</span> <span class=SpellE>unlikely</span> event <span
class=SpellE>that</span> <span class=SpellE>there</span> <span class=SpellE>is</span>
a <span class=SpellE>serious</span> data <span class=SpellE>breach</span> <span
class=SpellE>that</span> <span class=SpellE>is</span> <span class=SpellE>likely</span>
to <span class=SpellE>result</span> in a risk to <span class=SpellE>your</span>
<span class=SpellE>rights</span> and <span class=SpellE>freedoms</span>, <span
class=SpellE>we</span> <span class=SpellE>will</span> <span class=SpellE>without</span>
<span class=SpellE>undue</span> <span class=SpellE>delay</span> <span
class=SpellE>notify</span> <span class=SpellE>the</span> <span class=SpellE>appropriate</span>
supervisory <span class=SpellE>authority</span> in <span class=SpellE>your</span>
<span class=SpellE>jurisdiction</span>. <span class=SpellE>Where</span> <span
class=SpellE>there</span> <span class=SpellE>is</span> a <span class=SpellE>high</span>
risk <span class=SpellE>that</span> a <span class=SpellE>serious</span> data <span
class=SpellE>breach</span> <span class=SpellE>will</span> <span class=SpellE>impact</span>
<span class=SpellE>upon</span> <span class=SpellE>your</span> <span
class=SpellE>rights</span> and <span class=SpellE>freedoms</span>, <span
class=SpellE>we</span> <span class=SpellE>will</span> <span class=SpellE>also</span>
<span class=SpellE>notify</span> you <span class=SpellE>of</span> <span
class=SpellE>that</span> <span class=SpellE>breach</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.5 <span class=SpellE>Overseas</span> <span class=SpellE>disclosures</span></span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>Any profile <span class=SpellE>information</span> <span class=SpellE>that</span>
you <span class=SpellE>have</span> <span class=SpellE>provided</span> to <span
class=SpellE>us</span> <span class=SpellE>will</span> <span class=SpellE>be</span>
<span class=SpellE>publicly</span> <span class=SpellE>viewable</span> on <span
class=SpellE>your</span> profile, <span class=SpellE>irrespective</span> <span
class=SpellE>of</span> <span class=SpellE>the</span> <span class=SpellE>location</span>
<span class=SpellE>of</span> <span class=SpellE>the</span> <span class=SpellE>viewer</span>.
By <span class=SpellE>creating</span> a profile you <span class=SpellE>acknowledge</span>
<span class=SpellE>that</span> <span class=SpellE>overseas</span> <span
class=SpellE>recipients</span> <span class=SpellE>will</span> <span
class=SpellE>be</span> <span class=SpellE>able</span> to <span class=SpellE>view</span>
<span class=SpellE>your</span> profile.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>In <span class=SpellE>order</span> to <span class=SpellE>provide</span> <span
class=SpellE>customer</span> support, <span class=SpellE>perform</span> <span
class=SpellE>back</span> office <span class=SpellE>functions</span>, <span
class=SpellE>perform</span> <span class=SpellE>fraud</span> <span class=SpellE>prevention</span>
<span class=SpellE>tasks</span>, <span class=SpellE>or</span> <span
class=SpellE>provide</span> <span class=SpellE>services</span> to you <span
class=SpellE>we</span> <span class=SpellE>may</span> <span class=SpellE>need</span>
to <span class=SpellE>allow</span> <span class=SpellE>our</span> <span
class=SpellE>staff</span> to <span class=SpellE>access</span> <span
class=SpellE>your</span> profile <span class=SpellE>information</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>We</span></span><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'> <span class=SpellE>will</span> <span
class=SpellE>only</span> <span class=SpellE>disclose</span> <span class=SpellE>Personal</span>
Data to a <span class=SpellE>Third</span> Party Country recipient <span
class=SpellE>or</span> International <span class=SpellE>Organization</span> <span
class=SpellE>if</span> <span class=SpellE>the</span> <span class=SpellE>disclosure</span>
<span class=SpellE>of</span> <span class=SpellE>the</span> <span class=SpellE>information</span>
<span class=SpellE>is</span> <span class=SpellE>required</span> <span
class=SpellE>or</span> <span class=SpellE>authorized</span> by <span
class=SpellE>law</span>, <span class=SpellE>regulation</span> <span
class=SpellE>or</span> <span class=SpellE>court</span> <span class=SpellE>order</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>4.6 <span class=SpellE>Communication</span></span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>We</span></span><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'> <span class=SpellE>reserve</span> <span
class=SpellE>the</span> <span class=SpellE>right</span> to <span class=SpellE>send</span>
<span class=SpellE>electronic</span> mail to you <span class=SpellE>regarding</span>
<span class=SpellE>recommended</span> profile <span class=SpellE>matches</span>,
<span class=SpellE>notification</span> <span class=SpellE>alerts</span> <span
class=SpellE>of</span> <span class=SpellE>activity</span> such as <span
class=SpellE>interest</span> <span class=SpellE>alerts</span> <span
class=SpellE>or</span> <span class=SpellE>message</span> <span class=SpellE>alerts</span>,
<span class=SpellE>promotions</span> <span class=SpellE>or</span> <span
class=SpellE>offers</span>, <span class=SpellE>changes</span> <span
class=SpellE>or</span> <span class=SpellE>additions</span> to <span
class=SpellE>our</span> <span class=SpellE>services</span>, <span class=SpellE>or</span>
any <span class=SpellE>products</span> and <span class=SpellE>services</span> <span
class=SpellE>of</span> <span class=SpellE>our</span> <span class=SpellE>affiliated</span>
<span class=SpellE>businesses</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>5 <span class=SpellE>Destruction</span> <span class=SpellE>of</span> <span
class=SpellE>Personal</span> Data</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>We</span></span><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'> <span class=SpellE>will</span> <span
class=SpellE>keep</span> <span class=SpellE>your</span> <span class=SpellE>Personal</span>
Data <span class=SpellE>only</span> <span class=SpellE>for</span> as long as <span
class=SpellE>we</span> <span class=SpellE>need</span> <span class=SpellE>it</span>
<span class=SpellE>for</span> <span class=SpellE>legitimate</span> business <span
class=SpellE>purposes</span> and as <span class=SpellE>required</span> by <span
class=SpellE>applicable</span> <span class=SpellE>law</span>. <span
class=SpellE>We</span> <span class=SpellE>will</span> <span class=SpellE>take</span>
<span class=SpellE>reasonable</span> <span class=SpellE>steps</span> to <span
class=SpellE>erase</span> any <span class=SpellE>Personal</span> Data <span
class=SpellE>that</span> <span class=SpellE>we</span> hold <span class=SpellE>about</span>
you, <span class=SpellE>where</span>:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>(i)</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;<span class=SpellE>we</span> no <span class=SpellE>longer</span> <span
class=SpellE>need</span> <span class=SpellE>the</span> <span class=SpellE>Personal</span>
Data <span class=SpellE>for</span> <span class=SpellE>the</span> <span
class=SpellE>purposes</span> <span class=SpellE>for</span> <span class=SpellE>which</span>
<span class=SpellE>it</span> <span class=SpellE>was</span> <span class=SpellE>collected</span>
and <span class=SpellE>we</span> are not <span class=SpellE>otherwise</span> <span
class=SpellE>entitled</span> to <span class=SpellE>retain</span> such data; and<br>
<b>(<span class=SpellE>ii</span>)</b>&nbsp;<span class=SpellE>we</span> are not
<span class=SpellE>required</span> <span class=SpellE>or</span> <span
class=SpellE>obligated</span> by <span class=SpellE>law</span>, <span
class=SpellE>regulation</span> <span class=SpellE>or</span> <span class=SpellE>court</span>
<span class=SpellE>order</span> to <span class=SpellE>retain</span> <span
class=SpellE>the</span> <span class=SpellE>information</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>6 <span class=SpellE>Complaints</span></span></b><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>If</span></span><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'> you <span class=SpellE>believe</span> <span
class=SpellE>that</span> <span class=SpellE>we</span> <span class=SpellE>have</span>
<span class=SpellE>used</span> <span class=SpellE>or</span> <span class=SpellE>disclosed</span>
<span class=SpellE>your</span> <span class=SpellE>Personal</span> Data in a <span
class=SpellE>manner</span> <span class=SpellE>which</span> <span class=SpellE>is</span>
<span class=SpellE>contrary</span> to <span class=SpellE>this</span> <span
class=SpellE>Privacy</span> <span class=SpellE>Statement</span> <span
class=SpellE>or</span> <span class=SpellE>that</span> <span class=SpellE>otherwise</span>
<span class=SpellE>breaches</span> <span class=SpellE>an</span> <span
class=SpellE>applicable</span> <span class=SpellE>law</span>, <span
class=SpellE>then</span> you <span class=SpellE>should</span> email <span
class=SpellE>us</span> </span><span class=SpellE><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-fareast-language:CS'>at</span></span><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-fareast-language:CS'>&nbsp;</span><a href="mailto:info@e-see-you.cz"><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:windowtext;mso-fareast-language:CS'>info@e-see-you.cz</span></a><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-fareast-language:CS'>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>Definitions</span></b></span><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&quot;E-see-you &quot;</span></b><span style='font-size:10.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#505656;
mso-fareast-language:CS'>&nbsp;<span class=SpellE>means</span> <span
class=SpellE>company</span> <span class=SpellE>that</span> <span class=SpellE>controls</span>
and <span class=SpellE>which</span> <span class=SpellE>provide</span> <span
class=SpellE>dating</span> <span class=SpellE>services</span> to <span
class=SpellE>customers</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&quot;General Data <span class=SpellE>Protection</span> <span class=SpellE>Regulation</span>&quot;</span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'>&nbsp;and&nbsp;<b>&quot;GDPR&quot;</b>&nbsp;<span
class=SpellE>mean</span> <span class=SpellE>Regulation</span> (EU) 2016/679 <span
class=SpellE>of</span> <span class=SpellE>The</span> <span class=SpellE>European</span>
<span class=SpellE>Parliament</span> and <span class=SpellE>of</span> <span
class=SpellE>the</span> <span class=SpellE>Council</span> <span class=SpellE>of</span>
27 <span class=SpellE>April</span> 2016 on <span class=SpellE>the</span> <span
class=SpellE>protection</span> <span class=SpellE>of</span> natural <span
class=SpellE>persons</span> <span class=SpellE>with</span> <span class=SpellE>regard</span>
to <span class=SpellE>the</span> <span class=SpellE>processing</span> <span
class=SpellE>of</span> <span class=SpellE>personal</span> data and on <span
class=SpellE>the</span> free <span class=SpellE>movement</span> <span
class=SpellE>of</span> such data, and <span class=SpellE>repealing</span> <span
class=SpellE>Directive</span> 95/46/EC.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&quot;International <span class=SpellE>Organization</span>&quot;</span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'>&nbsp;<span
class=SpellE>means</span> <span class=SpellE>an</span> <span class=SpellE>organization</span>
and <span class=SpellE>its</span> <span class=SpellE>subordinate</span> <span
class=SpellE>bodies</span> <span class=SpellE>governed</span> by public <span
class=SpellE>international</span> <span class=SpellE>law</span>, <span
class=SpellE>or</span> any <span class=SpellE>other</span> body <span
class=SpellE>which</span> <span class=SpellE>is</span> set up by, <span
class=SpellE>or</span> on <span class=SpellE>the</span> <span class=SpellE>basis</span>
<span class=SpellE>of</span>, <span class=SpellE>an</span> <span class=SpellE>agreement</span>
<span class=SpellE>between</span> <span class=SpellE>two</span> <span
class=SpellE>or</span> more <span class=SpellE>countries</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&quot;<span class=SpellE>Personal</span> Data&quot;</span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'>&nbsp;<span
class=SpellE>means</span> any <span class=SpellE>information</span> <span
class=SpellE>relating</span> to <span class=SpellE>an</span> <span
class=SpellE>identifiable</span> natural person ('data <span class=SpellE>subject</span>');
<span class=SpellE>an</span> <span class=SpellE>identifiable</span> natural
person <span class=SpellE>is</span> <span class=SpellE>one</span> <span
class=SpellE>who</span> <span class=SpellE>can</span> <span class=SpellE>be</span>
<span class=SpellE>identified</span>, <span class=SpellE>directly</span> <span
class=SpellE>or</span> <span class=SpellE>indirectly</span>, in <span
class=SpellE>particular</span> by reference to <span class=SpellE>an</span> <span
class=SpellE>identifier</span> such as a <span class=SpellE>name</span>, <span
class=SpellE>an</span> <span class=SpellE>identification</span> <span
class=SpellE>number</span>, <span class=SpellE>location</span> data <span
class=SpellE>or</span> <span class=SpellE>an</span> online <span class=SpellE>identifier</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&quot;<span class=SpellE>Processing</span>&quot;</span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'>&nbsp;<span
class=SpellE>means</span> any <span class=SpellE>operation</span> <span
class=SpellE>or</span> set <span class=SpellE>of</span> <span class=SpellE>operations</span>
<span class=SpellE>which</span> <span class=SpellE>is</span> <span
class=SpellE>performed</span> on <span class=SpellE>personal</span> data <span
class=SpellE>or</span> on <span class=SpellE>sets</span> <span class=SpellE>of</span>
<span class=SpellE>personal</span> data, <span class=SpellE>whether</span> <span
class=SpellE>or</span> not by <span class=SpellE>automated</span> <span
class=SpellE>means</span>, such as <span class=SpellE>collection</span>, <span
class=SpellE>recording</span>, <span class=SpellE>organization</span>, <span
class=SpellE>structuring</span>, <span class=SpellE>storage</span>, <span
class=SpellE>adaptation</span> <span class=SpellE>or</span> <span class=SpellE>alteration</span>,
<span class=SpellE>retrieval</span>, <span class=SpellE>consultation</span>,
use, <span class=SpellE>disclosure</span> by <span class=SpellE>transmission</span>,
<span class=SpellE>dissemination</span> <span class=SpellE>or</span> <span
class=SpellE>otherwise</span> <span class=SpellE>making</span> <span
class=SpellE>available</span>, <span class=SpellE>alignment</span> <span
class=SpellE>or</span> <span class=SpellE>combination</span>, <span
class=SpellE>restriction</span>, <span class=SpellE>erasure</span> <span
class=SpellE>or</span> <span class=SpellE>destruction</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&quot;<span class=SpellE>Related</span> <span class=SpellE>mobie</span> <span
class=SpellE>application</span>&quot;</span></b><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>&nbsp;<span class=SpellE>means</span>
any <span class=SpellE>other</span> mobile <span class=SpellE>application</span>
<span class=SpellE>owned</span>, <span class=SpellE>operated</span> <span
class=SpellE>or</span> <span class=SpellE>controlled</span> by E-see-you Ltd.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&quot;<span class=SpellE>Website</span>&quot;</span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'>&nbsp;<span
class=SpellE>means</span> any <span class=SpellE>other</span> <span
class=SpellE>website</span> <span class=SpellE>owned</span>, <span
class=SpellE>operated</span> <span class=SpellE>or</span> <span class=SpellE>controlled</span>
by E-see-you Ltd.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&quot;<span class=SpellE>Company</span>&quot;</span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'>&nbsp;<span
class=SpellE>means</span> E-see-you Ltd, a <span class=SpellE>company</span> <span
class=SpellE>registered</span> in Czech Republic, ID 180 39 413;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&quot;EU&quot;</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;<span class=SpellE>means</span> <span class=SpellE>the</span> <span
class=SpellE>political</span> and <span class=SpellE>economic</span> union <span
class=SpellE>of</span> <span class=SpellE>member</span> <span class=SpellE>states</span>
<span class=SpellE>located</span> in <span class=SpellE>Europe</span> <span
class=SpellE>known</span> as <span class=SpellE>the</span> <span class=SpellE>European</span>
Union;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&quot;<span class=SpellE>Privacy</span> <span class=SpellE>Statement</span>&quot;</span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'>&nbsp;<span
class=SpellE>means</span> <span class=SpellE>the</span> <span class=SpellE>Company's</span>
<span class=SpellE>privacy</span> <span class=SpellE>policy</span> <span
class=SpellE>which</span> <span class=SpellE>is</span> <span class=SpellE>available</span>
on <span class=SpellE>the</span> <span class=SpellE>Website</span> and <span
class=SpellE>Related</span> mobile <span class=SpellE>application</span>;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&quot;<span class=SpellE>Service</span>&quot;</span></b><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#505656;mso-fareast-language:CS'>&nbsp;<span
class=SpellE>means</span> <span class=SpellE>the</span> <span class=SpellE>facilities</span>
made <span class=SpellE>available</span> <span class=SpellE>for</span> <span
class=SpellE>members</span> via <span class=SpellE>the</span> <span
class=SpellE>Website</span> and <span class=SpellE>Related</span> mobile <span
class=SpellE>application</span>;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&quot;<span class=SpellE>Terms</span> <span class=SpellE>of</span>
Use&quot;</span></b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&nbsp;<span class=SpellE>means</span> these <span class=SpellE>Terms</span>
<span class=SpellE>of</span> Use as <span class=SpellE>amended</span> by <span
class=SpellE>the</span> <span class=SpellE>Company</span> <span class=SpellE>from</span>
<span class=SpellE>time</span> to <span class=SpellE>time</span>, <span
class=SpellE>which</span> <span class=SpellE>form</span> <span class=SpellE>the</span>
<span class=SpellE>agreement</span> <span class=SpellE>between</span> you and <span
class=SpellE>the</span> <span class=SpellE>Company</span>;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#505656;mso-fareast-language:
CS'>&quot;<span class=SpellE>We</span>&quot;, &quot;<span class=SpellE>Us</span>&quot;
and &quot;<span class=SpellE>Our</span>&quot;</span></b><span style='font-size:
10.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#505656;mso-fareast-language:CS'>&nbsp;<span class=SpellE>all</span> <span
class=SpellE>refer</span> to <span class=SpellE>the</span> <span class=SpellE>Company</span>.</span></p>

</div>

            </v-card-text>
        </v-card>
    </v-container>
</template>
  
<script>


export default {
    name: 'Terms',
    data: () => ({
        lang: localStorage.getItem('language')
    }),
    created() {
        // Na prase ale to nikdo nepozná :D
        setInterval(() => {
            let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'cs';
            this.lang = lang;
        }, 1);
    }
}
</script>

<style lang="scss" scoped>
.container {
    height: calc(100vh - 48px);
    padding-bottom: 90px;
}
.v-card {
    height: 100%;

    display: flex;
    flex-direction: column;
}
.moveable {
    flex-grow: 2;
    overflow-x: auto;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #f3f3f3;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #7a9704;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
</style>