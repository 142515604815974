<template>
  <v-container fluid class="h-100">
    <v-row justify="center" align="center" class="h-100">
      <v-col cols="12" sm="10" md="8" lg="7" xl="5" class="esy-wrapper-panel">
        <v-card v-if="user">
          <v-toolbar dark class="pl-1">
            <span class="text-h6 font-weight-black">{{ $t('Váš profil') }}</span>

            <v-spacer></v-spacer>

            <v-btn text icon color="white" :to="{ name: 'ProfileEdit' }">
              <v-icon small> fal fa-pen </v-icon>
            </v-btn>

            <v-btn text icon color="white" :to="{ name: 'Home' }" exact>
              <v-icon small> fal fa-times </v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text class="pa-0">
            <v-container>
              <v-row justify="space-between" >
                <v-col>
                  <v-list>
                    <v-list-item style="min-height: 30px;">
                      <v-list-item-title>
                        <b>{{ $t('Pohlaví') }}: </b>
                        {{ user.sex === "female" ? $t('žena') : $t('muž') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item style="min-height: 30px;">
                      <v-list-item-title>
                        <b>{{ $t('Jméno') }}: </b> {{ user.firstName }} {{ user.lastName }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item style="min-height: 30px;">
                      <v-list-item-title>
                        <b>{{ $t('Věk') }}: </b> {{ today.diff(user.birthDate, "year") }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item style="min-height: 30px;">
                      <v-list-item-title>
                        <b>{{ $t('Bydliště') }}: </b> {{ user.city }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item style="min-height: 30px;">
                      <v-list-item-title>
                        <b>{{ $t('Aktuální stav') }}: </b>

                        <span class="green--text">{{ $t('Online') }}</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="6" sm="6" md="5" lg="5" class="d-flex justify-end">
                  <v-avatar
                    width="100%"
                    height="auto"
                  >
                    <v-img
                      class="user"
                      :class="{
                        woman: user.sex === 'female',
                        man: user.sex !== 'female',
                      }"
                      :src="user.profilePicture ? getPictureSource(user.profilePicture) : ''"
                    ></v-img>
                  </v-avatar>
                </v-col>
              </v-row>

              <v-row class="my-0 mt-3 py-0" justify="space-between">
                <v-col cols="12" sm="auto" class="py-0">
                  <p class="mb-0">
                    <b>{{ $t('Poslední přihlášení') }}:</b>
                    {{ user.lastAuth.format("DD. MM. YYYY") }}
                  </p>
                </v-col>
                <v-col cols="12" sm="auto" class="py-0">
                  <p class="mb-0">
                    <b>{{ $t('Celkem přihlášení') }}: </b>
                    {{ user.loggedCount }}x
                  </p>
                </v-col>
              </v-row>
            </v-container>

            <v-container>
              <v-row dense>
                <v-col
                  cols="12"
                  sm="4"
                  md="2"
                  class="esy-image pokus-image"
                  v-for="(picture, i) in user.pictures"
                  :key="i"
                >
                  <div
                    class="image"
                    @click.capture="
                      zoomedImage =
                        zoomedImage != undefined
                          ? zoomedImage == i
                            ? undefined
                            : i
                          : i
                    "
                    :class="{ zoomed: zoomedImage == i }"
                  >
                    <div class="close-holder">
                      <v-img :src="getPictureSource(picture)" contain class="img-wrp"></v-img>

                      <v-btn
                        v-if="zoomedImage == i"
                        fab
                        dark
                        @click.capture="zoomedImage = undefined"
                        class="close"
                        style="width: 42px; height: 42px;"
                      >
                        <v-icon>fal fa-times</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <searching />
  </v-container>
</template>

<script>
import moment from "moment";
import { URL } from "@/store/index";
import Searching from '../../components/layout/Searching.vue';

export default {
  components: { Searching },
  name: "Profile",

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  data: () => ({
    today: moment(),

    zoomedImage: undefined,
  }),


  methods: {
    getPictureSource(picture) {
      return URL + picture;
    },
  },
};
</script>

<style lang="scss">
.image {
  cursor: pointer;

  &.zoomed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    > .close-holder {
      position: relative;
      width: 80%;
      height: 80%;

      > .v-image {
        width: 100%;
        height: 100%;
      }

      
      .v-btn.close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1000;

        transform: translate(50%, -50%);
      }
    }
  }
}

.h-100 {
  height: 100%;
}

.esy-image .img-wrp {
    height: 100% !important;
}

.esy-image .v-image__image {
    // background-size: cover !important;
}

.esy-image .close-holder {
    height: 100%;
}

.esy-image .image {
    height: 100%;
}

.pokus-image {
    max-height: 72px;
    padding: 4px;
    border: 1px solid #dedede;
    margin-right: 4px;
    border-radius: 4px;
}

.pokus-image .image:not(.zoomed) .v-image__image {
    background-size: cover;
}

@media only screen and (max-width: 992px) {
    .esy-wrapper-panel {
        padding: 0 !important;
        height: calc(100vh - 48px);
    }

    .pokus-image {
        max-height: none;
    }

    .esy-wrapper-panel header {
        border-radius: 0 !important;
    }

    .esy-wrapper-panel .v-card {
        height: 100%;
    }

    .esy-wrapper-panel .v-card__text {
        height: calc(100vh - 144px);
        overflow-y: scroll !important;

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background: #f3f3f3;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #7a9704;
            border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
}

</style>