<template>
  <v-container fluid class="h-100 esy-wrapper-panel-main moveable">
    <v-row justify="center" align="center" class="h-100">
      <v-col cols="12" sm="10" md="9" lg="8" xl="5" class="esy-oprava-card">
        <v-card>
          <v-card-title>
            <v-row justify="space-between">
              <v-col cols="auto"> {{ $t('Úprava profilu') }} </v-col>
              <v-col cols="auto">
                <v-btn text color="black" class="d-none d-sm-flex" exact :to="{ name: 'Profile' }">
                  <!-- <v-icon small class="mr-2"> fal fa-eye </v-icon> -->
                  <v-icon class="mr-2">fal fa-times</v-icon>

                  {{ $t('Zobrazit profil') }}
                </v-btn>
                <v-btn text color="black" class="d-inline d-sm-none" exact icon :to="{ name: 'Profile' }">
                  <!-- <v-icon small class="mr-2"> fal fa-eye </v-icon> -->
                  <v-icon class="mr-2">fal fa-times</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-tabs background-color="dark" v-model="tab" centered center-active dark>
            <v-tab key="profile_info">{{ $t('Profilové údaje') }}</v-tab>
            <v-tab key="profile_foto">{{ $t('Profilové foto') }}</v-tab>
            <v-tab key="photos">{{ $t('Fotografie') }}</v-tab>
            <v-tab key="password">{{ $t('Heslo') }}</v-tab>
            <v-tab key="deletion">{{ $t('Zrušení účtu') }}</v-tab>
          </v-tabs>

          <v-card-text>
            <v-tabs-items v-model="tab" :show-arrows="false" :touchless="photoDialog">
              <v-tab-item key="profile_info">
                <v-container>
                  <v-row>
                    <!--v-col cols="12">
                                        <v-text-field
                                            label="Přezdívka"
                                            outlined
                                            required
                                        ></v-text-field>
                                    </v-col-->
                    <v-col cols="6">
                      <v-text-field :label="$t('Křestní jméno')" v-model="firstName" outlined hide-details :rules="[
                        (v) =>
                          v.length <= 50 ||
                          $t('Pole může mít maximálně 50 znaků'),
                      ]"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field :label="$t('Příjmení')" v-model="lastName" outlined hide-details :rules="[
                        (v) =>
                          v.length <= 50 ||
                          $t('Pole může mít maximálně 50 znaků'),
                      ]"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field :label="$t('Email')" v-model="email" outlined required hide-details :rules="[
                        (v) =>
                          v.length <= 50 ||
                          $t('Pole může mít maximálně 50 znaků'),
                      ]" disabled></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field :label="$t('Bydliště')" v-model="city" hide-details outlined :rules="[
                        (v) =>
                          v.length <= 50 ||
                          $t('Pole může mít maximálně 50 znaků'),
                      ]"></v-text-field>
                    </v-col>
                    <!--
                    <v-col cols="6">
                      <v-text-field :label="$t('Telefon')" v-model="telephone" outlined hide-details :rules="[
                        (v) => !!v || $t('Telefon je povinný údaj'),
                        (v) =>
                          v.length === 9 ||
                          $t('Telefon musí mít přesně 9 číslic'),
                        (v) =>
                          /^\d+$/.test(v) ||
                          $t('Telefon může obsahovat pouze číslice'),
                      ]"></v-text-field>
                    </v-col> -->
                    <!--v-col cols="12" sm="6">
                                        <v-select
                                            :items="['Muž', 'Žena', 'Ostatní']"
                                            label="Pohlaví"
                                            hide-details
                                            outlined
                                        ></v-select>
                                    </v-col-->
                  </v-row>
                  <v-row justify="end">
                    <v-col cols="auto">
                      <v-btn color="green" dark x-large class="font-weight-black" @click="saveProfileInfo"
                        :loading="loading">
                        {{ $t('Uložit') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>

              </v-tab-item>
              <v-tab-item key="profile_foto">
                <div id="photo-tab" style="position: relative">
                    <!-- CROPPER -->
                  <v-dialog :fullscreen="!$vuetify.breakpoint.mdAndUp" v-model="photoDialog" width="500" attach="#photo-tab" hide-overlay style="position: absolute">
                    <v-card>
                      <v-card-title class="d-flex justify-space-between align-center">
                        <span>{{ $t('Nastavení profilové fotografie') }}</span>

                        <v-btn icon @click="photoDialog = false; isSelecting = false; profilePhoto = null;" class="ml-2">
                          <v-icon>fal fa-times</v-icon>
                        </v-btn>
                      </v-card-title>

                      <v-card-text>

                        <v-row>
                            <!-- Cropper -->
                            <v-col cols="12">
                                <div style="overflow: hidden; border-radius: 4px;">
                                    <cropper
                                        class="cropper cropper-wrapper-element"
                                        :src="profilePhotoBase64"
                                        @change="change"
                                        :stencil-props="{
                                            aspectRatio: 1
                                        }"
                                        stencil-component="circle-stencil"
                                    ></cropper>
                                </div>
                            </v-col>

                            <!--
                          <v-col cols="12" class="d-flex justify-center align-center">
                            <div ref="profilePhotoCropper" :style="`background: url('${profilePhotoBase64}') no-repeat 0 0 ; background-size: ${profilePhotoZoom * 100
                              }%; width: 400px; height: 400px; background-color: black; position: relative;`">
                              <svg viewBox="0 0 50 50" width="100%" height="100%"
                                style="position: absolute; top: 0; left: 0;">
                                <defs>
                                  <mask id="mask" x="0" y="0" width="50" height="50">
                                    <rect x="0" y="0" width="50" height="50" fill="#fff" />
                                    <circle cx="25" cy="25" r="21" />
                                  </mask>
                                </defs>
                                <rect x="0" y="0" width="50" height="50" mask="url(#mask)" fill-opacity="0.55" />
                              </svg>
                            </div>
                          </v-col>
                          <v-col cols="12">
                            <div class="d-flex align-center">
                              <v-btn small icon @click="profilePhotoZoom = Math.max(1, profilePhotoZoom - 0.2)">
                                <v-icon small>fal fa-minus</v-icon>
                              </v-btn>

                              <div class="slider"
                                style="width: 100%; display: inline-block; margin: 0 10px; height: 10px; background-color: #eee;"
                                ref="slider">
                                <div class="value"
                                  :style="`width: ${(profilePhotoZoom - 1) / 0.02}%; height: 10px; background-color: black;`">
                                </div>
                              </div>

                              <v-btn small icon @click="profilePhotoZoom = Math.min(3, profilePhotoZoom + 0.2)">
                                <v-icon small>fal fa-plus</v-icon>
                              </v-btn>
                            </div>
                          </v-col>
                          -->
                          <v-col cols="12">
                            <v-btn color="green" dark block x-large class="font-weight-black" @click="uploadProfilePhoto"
                              :loading="loading">
                              {{ $t('Uložit') }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-dialog>

                  <v-container>
                    <v-row justify="center">
                      <v-col cols="auto" class="d-flex flex-column align-center" style="gap: 30px">
                        <v-avatar size="250" color="blue" class="user" :class="{
                          woman: user.sex === 'female',
                          man: user.sex !== 'female',
                        }">
                          <v-img v-if="user.profilePicture" :src="profilePictureSource"></v-img>
                        </v-avatar>

                        <v-file-input accept="image/jpeg,image/png,image/jpg" :label="$t('Profilový obrázek')"
                          style="min-width: 200px" v-model="profilePhoto" ref="profileUploader" :rules="[
                            (value) =>
                              !value ||
                              value.size < 5000000 ||
                              $t('Avatar musí být menší jak  5 MB!'),
                          ]" class="d-none" @cancel="photoDialog = false; isSelecting = false; profilePhoto = null;"></v-file-input>

                        <v-btn color="green" dark x-large class="font-weight-black" :loading="isSelecting"
                          @click="handleProfileFileImport">
                          {{ $t('Vybrat jinou fotografii') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-tab-item>
              <v-tab-item key="photos">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4" md="2" class="h-100" v-for="(picture, i) in user.pictures" :key="i"
                      style="position: relative">
                      <v-img :src="getPictureSource(picture)" height="100%" cover></v-img>

                      <v-btn fab x-small color="red" @click="toDelete = i; deleteDialog = true;" class="delete">
                        <v-icon small color="white">fal fa-times</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row justify="center">
                    <v-col cols="auto" class="d-flex align-center" style="gap: 30px">
                      <v-file-input accept="image/jpeg,image/png,image/jpg" label="Obrázek" style="min-width: 200px"
                        v-model="photo" ref="photoUploader" :rules="[
                          (value) =>
                            !value ||
                            value.size < 5000000 ||
                            $t('Fotografie musí být menší jak  5 MB!'),
                        ]" class="d-none"></v-file-input>

                      <v-btn color="green" :dark="user.pictures.length < 6" x-large class="font-weight-black"
                        :loading="isSelecting" :disabled="user.pictures.length >= 6" @click="handleFileImport">
                        {{ $t('Přidat fotografii') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item key="password">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field :label="$t('Původní heslo')" type="password" v-model="oldPassword" outlined hide-details
                        required :rules="[(v) => !!v || $t('Původní heslo je povinný údaj')]">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field :label="$t('Nové heslo')" type="password" v-model="newPassword" outlined required
                        hide-details :rules="[
                          (v) => !!v || $t('Nové heslo je povinný údaj'),
                          (v) =>
                            v.length >= 8 || $t('Heslo musí mít alespoň 8 znaků'),
                          (v) =>
                            v.length <= 50 ||
                            $t('Heslo může mít maximálně 50 znaků'),
                        ]"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field :label="$t('Kontrola nového hesla')" type="password" v-model="newPasswordCheck" hide-details
                        outlined required :rules="[
                          (v) => !!v || $t('Kontrola nového hesla je povinný údaj'),
                          (v) =>
                            v.length >= 8 || $t('Heslo musí mít alespoň 8 znaků'),
                          (v) => v === newPassword || $t('Hesla se neshodují'),
                          (v) =>
                            v.length <= 50 ||
                            $t('Heslo může mít maximálně 50 znaků')
                        ]"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row justify="end">
                    <v-col cols="auto">
                      <v-btn color="green" dark block x-large class="font-weight-black" @click="changePassword"
                        :loading="loading">
                        {{ $t('Uložit nové heslo') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>

              </v-tab-item>
              <v-tab-item key="deletion">
                <v-container>
                  <v-row>
                    <v-col cols="12">

                      <p class="text-h6 font-weight-black black--text">
                        {{ $t('Smazání Vašeho profilu je nevratná operace. Bude smazán nejen Váš profil, ale tak i Vaše požadavky a fotografie.') }}
                      </p>
                      <p class="font-weight-bold black--text">
                        {{ $t("Pokud chcete profil smazat, zadejte své přihlašovací heslo a stistkněte 'Smazat profil'. Pro větší bezpečnost Vám bude zaslán e-mail s potvrzením, na který budete muset odpovědět.") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="8" style="margin-top: 0; padding-top: 0; margin-bottom: 0; padding-bottom: 0;">
                    </v-col>
                    <v-col cols="12" md="4" style="margin-top: 0; padding-top: 0; margin-bottom: 0; padding-bottom: 0;">
                      <v-btn color="red" x-large block @click="showDeletionModal = true"> <!-- @click="requestDeletion" -->
                        <span class="white--text font-weight-bold">{{ $t('Zrušit profil') }}</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="deleteDialog" width="unset" hide-overlay transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title style="margin-right: 40px;">{{ $t('Smazat fotografii?') }}</v-toolbar-title>

                <v-spacer></v-spacer>
 
                <v-btn @click="deleteDialog = false; toDelete = null;" icon color="white">
                    <v-icon>fal fa-times</v-icon>
                </v-btn>
            </v-toolbar>

            <div class="h-100" style="padding: 24px; display: flex; justify-content: center; flex-direction: column;">
                <v-img :src="getPictureSource(user.pictures[toDelete])" height="300px" width="100%" cover></v-img>

                <v-btn color="red" dark @click="deleteDialog = false; deletePhoto(toDelete);" elevation="8" style="margin-top: 24px;">
                    {{ $t('Smazat') }}
                </v-btn>
            </div>
        </v-card>
    </v-dialog>

    <!-- Deletion modal -->
    <v-dialog
        v-model="showDeletionModal"
        width="450px"
        >
        <v-card>
            <div style="padding: 20px;">
                <p style="color: black; font-weight: bold; font-size: 18px;">
                    {{ $t('Smazání profilu je nevratné. Jste si skutečně jistý?') }}
                </p>
                <v-text-field :label="$t('Heslo')" type="password" outlined v-model="deletionPassword" required
                        :rules="[(v) => !!v || 'Heslo je povinný údaj']"></v-text-field>
            </div>

            

            <v-card-actions style="padding: 20px; flex: row;">
                <v-btn color="#92A913" style="color: white; padding: 24px; font-weight: bold; width: 50%; min-width: 0 !important;" block @click="showDeletionModal = false" >{{ $t('Zpět') }}</v-btn>
                <v-btn dark color="red" style="color: white; padding: 24px; font-weight: bold; width: 50%; min-width: 0 !important;" block @click="requestDeletion">{{ $t('Smazat') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { URL } from "@/store/index";
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const dataURLtoFile = async (url, file) => {
  console.log("getting file from url: ", url, " with name: ", file);

  const response = await fetch(url);
  const data = await response.blob();

  return new File([data], file, {
    type: data.type || "image/jpeg",
  });
};

export default {
  name: "ProfileEdit",

  computed: {
    user() {
      let user = this.$store.state.user;

      if (user.firstName == "-")
        user.firstName = "";

      if (user.lastName == "-")
        user.lastName = "";

      return user;
    },

    profilePictureSource() {
      return URL + this.user.profilePicture;
    },
  },

  data: () => ({
    tab: "photos",

    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    city: "",
    showDeletionModal: "",

    profilePhoto: null,
    photoDialog: false,
    profilePhotoZoom: 1,
    profilePhotoBase64: null,
    profilePhotoChange: null,
    photo: null,

    oldPassword: "",
    newPassword: "",
    newPasswordCheck: "",

    deletionPassword: "",

    loading: false,

    isSelecting: false,
    photo: null,
    toDelete: null,
    deleteDialog: false
  }),

  mounted() {
    this.firstName = this.user.firstName;
    this.lastName = this.user.lastName;
    this.email = this.user.email;
    this.telephone = this.user.telephone;
    this.city = this.user.city;
  },

  methods: {
    change({ coordinates, canvas }) {
        this.profilePhotoChange = { coordinates, canvas };
    },
    getPictureSource(picture) {
      return URL + picture;
    },

    async saveProfileInfo() {
      this.loading = true;
      const user = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        telephone: this.telephone,
        city: this.city,
      };

      const start = Date.now();
      const result = await this.$store.dispatch("updateUser", user);

      if (!result) {
        this.loading = false;

        this.$root.$emit("notification", {
          message: this.$t("Něco se pokazilo. Zkuste to prosím později."),
          type: "error",
          duration: 6,
        });
      } else {
        if (start + 1000 > Date.now()) {
          setTimeout(() => {
            this.loading = false;
            this.$root.$emit("notification", {
              message: this.$t("Úspěšně uloženo!"),
              duration: 2,
            });
          }, 1000 - (Date.now() - start));
        } else {
          this.loading = false;
          this.$root.$emit("notification", {
            message: this.$t("Úspěšně uloženo!"),
            duration: 2,
          });
        }
      }
    },

    handleProfileFileImport() {
      this.isSelecting = true;

      // once uploader is done
      this.$refs.profileUploader.$refs.input.addEventListener(
        "change",
        async () => {
          this.profilePhotoBase64 = await toBase64(this.profilePhoto);

          this.photoDialog = true;
        }
      );

      this.$refs.profileUploader.$refs.input.click();
    },

    async uploadProfilePhoto() {
        /*
      // Crop image by this.$refs.profilePhotoCropper - consider zoom and bg position
      const bgPosX = parseFloat(
        getComputedStyle(this.$refs.profilePhotoCropper).backgroundPositionX
      );
      const bgPosY = parseFloat(
        getComputedStyle(this.$refs.profilePhotoCropper).backgroundPositionY
      );
      const bgSize = parseFloat(
        getComputedStyle(this.$refs.profilePhotoCropper).backgroundSize
      );

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const img = new Image();
      img.src = this.profilePhotoBase64;

      canvas.width = 500;
      canvas.height = 500;

      const ratio = Math.min(
        canvas.width / img.width,
        canvas.height / img.height
      );

      console.log(ratio);

      const border = 32 / ratio;
      ctx.drawImage(
        img,
        0 + border / 2,
        0 + border / 2,
        img.width - border,
        img.height - border,
        bgPosX,
        bgPosY,
        ((img.width * bgSize) / 100) * ratio,
        ((img.height * bgSize) / 100) * ratio
      );

      console.log(
        img.width,
        img.height,
        bgPosX,
        bgPosY,
        bgSize,
        ((img.width * bgSize) / 100) * ratio,
        ((img.height * bgSize) / 100) * ratio
      );
      */

        let canvas = this.profilePhotoChange.canvas;

        let url = canvas.toDataURL("image/jpeg", 1);

        // Convert to File
        let profilePhoto = await dataURLtoFile(url, "profilePhoto.jpg");

        if (profilePhoto.size == 0) {
            this.$root.$emit("notification", {
                message: this.$t("Něco se pokazilo. Zkuste to prosím později."),
                type: "error",
                duration: 6,
            });
            this.profilePhoto = null;
            this.photoDialog = false;
            return;
        }

        const result = await this.$store.dispatch(
            "uploadProfilePicture",
            profilePhoto
        );

        if (!result) {
            this.$root.$emit("notification", {
                message: this.$t("Něco se pokazilo. Zkuste to prosím později."),
                type: "error",
                duration: 6,
            });
            this.profilePhoto = null;
            this.photoDialog = false;
            return;
        }

        this.profilePhoto = null;
        this.photoDialog = false;
        this.isSelecting = false;

        this.$root.$emit("notification", {
            message: this.$t("Úspěšně nahráno!"),
            duration: 2,
        });
    },

    handleFileImport() {
      this.isSelecting = true;

      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      // once uploader is done
      this.$refs.photoUploader.$refs.input.addEventListener(
        "change",
        () => {
          this.uploadPhoto();
        },
        { once: true }
      );

      this.$refs.photoUploader.$refs.input.click();
    },

    async uploadPhoto() {
      const result = await this.$store.dispatch("uploadPicture", this.photo);

      if (!result) {
        this.$root.$emit("notification", {
          message: this.$t("Něco se pokazilo. Zkuste to prosím později."),
          type: "error",
          duration: 6,
        });
        this.photo = null;
        return;
      }

      this.photo = null;
      this.$root.$emit("notification", {
        message: this.$t("Úspěšně nahráno!"),
        duration: 2,
      });
    },
    async deletePhoto(index) {
      const result = await this.$store.dispatch("deletePicture", index);

      if (!result) {
        this.$root.$emit("notification", {
          message: this.$t("Něco se pokazilo. Zkuste to prosím později."),
          type: "error",
          duration: 6,
        });
        return;
      }

      this.$root.$emit("notification", {
        message: this.$t("Úspěšně smazáno!"),
        duration: 2,
      });
    },

    async changePassword() {
      this.loading = true;
      const data = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      };

      const start = Date.now();
      const result = await this.$store.dispatch("updateUserPassword", data);

      if (!result) {
        this.loading = false;
        this.$root.$emit("notification", {
          message: this.$t("Něco se pokazilo. Zkuste to prosím později."),
          type: "error",
          duration: 6,
        });
      } else {
        if (start + 1000 > Date.now()) {
          setTimeout(() => {
            this.loading = false;
          }, 1000 - (Date.now() - start));
        } else {
          this.loading = false;
        }

        this.$root.$emit("notification", {
          message: this.$t("Heslo bylo úspěšně změněno."),
          duration: 6,
        });

        /*
        this.oldPassword = "";
        this.newPassword = "";
        this.newPasswordCheck = ""; */
      }
    },

    async requestDeletion() {
      const result = await this.$store.dispatch("requestDeletion", {
        password: this.deletionPassword,
      });

      if (!result) {
        this.$root.$emit("notification", {
          message: this.$t("Něco se pokazilo. Zkuste to prosím později."),
          type: "error",
          duration: 6,
        });
      } else {
        this.$root.$emit("notification", {
          message: this.$t("Váš účet byl smazán."),
          duration: 6,
        });

        this.$store.dispatch("logOut");
        this.$router.push({ name: "Home" });
      }
    },
  },
};
</script>

<style lang="scss">
@media only screen and (max-width: 992px) { 
    .esy-oprava-card > div {
        height: 100% !important;
        border-radius: 0 !important;
        box-shadow: none !important;
    }
}

.vue-simple-handler {
    background: #92A913 !important;
}

</style>

<style lang="scss" scoped>

.cropper-wrapper-element {
    max-height: 400px !important;
}

.h-100 {
  height: 100%;
}

.delete {
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 992px) {
    .v-main__wrap {
        display: block !important;
    }

    .esy-wrapper-panel-main {
        padding: 0 !important;
        width: 100%;
        overflow-x: hidden !important;
        height: calc(100vh - 48px);
        min-height: 0 !important;
    }

    .mobile-insets .esy-wrapper-panel-main {
        height: calc(100vh - 96px);
    }

    .esy-wrapper-panel-main > div {
        align-items: start !important;
    }

    .esy-wrapper-panel-main > div > div {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

.moveable {
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #f3f3f3;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #7a9704;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
</style>