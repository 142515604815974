<template>
    <v-navigation-drawer v-if="user" :permanent="$vuetify.breakpoint.mdAndUp" app right clipped>
        <div class="d-flex align-center" style="height: 100%; width: 100%">
            <div class="searching">
                <h3>{{ $t('Nově hledající') }}</h3>
                <p>{{ $t('Nově zaregistrovaní uživatelé') }}</p>

                <div class="people">
                    <div class="profile" v-for="(user, i) in newUsers" :key="i">
                        <img :src="getPictureSource(user.profilePicture, user)" alt="" />
                        <p style="width: 100%; text-align: center;">{{ user.nickName }}</p>
                    </div>
                </div>
            </div>
        </div>
    </v-navigation-drawer>
</template>

<script>
import { URL } from "@/store/index";

export default {
    computed: {
        user() {
            return this.$store.state.user;
        },
    },

    data: () => ({
        newUsers: [],
    }),

    async mounted() {
        this.newUsers = await this.$store.dispatch("getUserStats");
    },

    methods: {
        getPictureSource(picture, user) {
            console.log(user);
            if (!picture || picture == 'undefined' || picture.includes('undefined'))
                return user.sex == 'female' ? "/img/woman.png" : "/img/man.png";

            return URL + picture;
        },
    },
}
</script>

<style lang="scss" scoped>
.searching {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8) !important;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 20px 10px;

    h3 {
        color: white;
        font-size: 1.3rem;
        font-weight: 800;
    }

    >p {
        color: #9a9a9a;
        font-size: 1.1rem;
        font-weight: 300;
    }

    .people {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: repeat(3, minmax(0, 1fr));

        width: 100%;
        margin-top: 20px;

        .profile {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                width: 80px;
                height: 80px;
                border-radius: 50%;
            }

            p {
                color: white;
                font-size: 1.1rem;
                font-weight: 300;
                margin-top: 10px;

                width: 90%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}
</style>