import moment from 'moment/moment'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import VuexPersistence from 'vuex-persist';
const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    key: 'seznamka',
    reducer: state => ({
        user: state.user,
        token: state.token,
    })
})

//const URL = "http://localhost:3000/";
//const URL = "https://seznamka-api.cajthaml.eu/"; // https://seznamka-api.cajthaml.eu/ e-see-you-api.200solutions.com/
const URL = "https://e-see-you-7128.rostiapp.cz/" // https://backend.e-see-you.200solutions.waage.cz/ https://e-see-you-7128.rostiapp.cz/

export { URL };

export default new Vuex.Store({
    plugins: [vuexLocal.plugin],
    state: {
        token: undefined,
        user: undefined,

        chat: undefined,
        chats: [],

        searches: [],

        stats: {
            totalUsers: 0,
            totalSearches: 0,
            activeUsers: 0,
        }
    },
    mutations: {
        setToken(state, data) {
            state.token = data
        },
        setUser(state, data) {
            state.user = data
        },

        setChat(state, data) {
            state.chat = data
        },

        setChats(state, data) {
            state.chats = data
        },

        setSearches(state, data) {
            state.searches = data
        },

        setStats(state, data) {
            state.stats = data
        }
    },
    actions: {
        getSource({ state}, data) {
            return URL;
        },

        setToken({ commit }, data) {
            commit('setToken', data)
        },

        async loadUser({ commit, state }) {
            if (!state.token) return;

            const response = await fetch(URL + "user/@me", {
                method: "GET",
                cors: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": state.token
                },
            })

            if(response.status !== 200) return;

            const data = (await response.json()).data;

            commit('setUser', {
                ...data.user,

                lastAuth: moment(data.user.lastAuth),
                createdAt: moment(data.user.createdAt),
                birthDate: moment(data.user.birthDate),
            });
        },

        async loadSearch({ state }, params) {
            const response = await fetch(URL + "search/list?" + new URLSearchParams(params), {
                method: "GET",
                cors: "cors",
                headers: state.token ? {
                    "Content-Type": "application/json",
                    "x-auth-token": state.token
                } : {
                    "Content-Type": "application/json",
                },
            });

            const searches = (await response.json()).data.searches;

            return searches;
        },

        async putSearch({ state }, params) {
            const response = await fetch(URL + "search", {
                method: "POST",
                body: JSON.stringify(params),
                cors: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": state.token
                },
            }).catch(error => {
                console.error(error);
            });

            const data = (await response.json()).data;

            return data;
        },

        async createChat({ state, commit }, params) {
            const response = await fetch(URL + "chat", {
                method: "POST",
                body: JSON.stringify({
                    message: params.message,
                    search: params.search
                }),
                cors: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": state.token
                },
            })

            const data = (await response.json()).data;

            commit('setChat', {
                id: data.chat
            });

            return data;
        },

        async getChats({ state, commit }) {
            const response = await fetch(URL + "chat/list", {
                method: "GET",
                cors: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": state.token
                },
            })

            const data = (await response.json()).data;

            commit('setChats', data.chats);
        },

        async updateUser({state, dispatch}, params) {
            const response = await fetch(URL + "user/@me", {
                method: "PUT",
                body: JSON.stringify(params),
                cors: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": state.token
                },
            })

            // check if response is ok
            if(response.status !== 200) return false;

            dispatch("loadUser");

            return true;
        },

        async updateUserPassword({state, dispatch}, data) {
            const response = await fetch(URL + "user/@me/password", {
                method: "PATCH",
                body: JSON.stringify(data),
                cors: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": state.token
                },
            })

            // check if response is ok
            if(response.status !== 200) return false;

            return true;
        },

        async requestDeletion({state}, data) {
            const response = await fetch(URL + "user/@me", {
                method: "DELETE",
                cors: "cors",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": state.token
                },
            })

            // check if response is ok
            if(response.status !== 200) return false;

            return true;
        },
        async deleteUser({state, dispatch}, data) {
            const response = await fetch(URL + "user/@me/" + data, {
                method: "DELETE",
                cors: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": state.token
                },
            })

            // check if response is ok
            if(response.status !== 200) return false;

            return true;
        },

        async uploadProfilePicture({state, dispatch}, params) {
            const formData = new FormData();
            formData.append("profilePicture", params);

            const response = await fetch(URL + "user/@me/picture", {
                method: "POST",
                body: formData,
                cors: "cors",
                headers: {
                    "x-auth-token": state.token
                },
            })

            // check if response is ok
            if(response.status !== 200) return false;

            dispatch("loadUser");

            return true;
        },
        async uploadPicture({state, dispatch}, params) {
            const formData = new FormData();
            formData.append("picture", params);

            const response = await fetch(URL + "user/@me/pictures", {
                method: "POST",
                body: formData,
                cors: "cors",
                headers: {
                    "x-auth-token": state.token
                },
            })

            // check if response is ok
            if(response.status !== 200) return false;

            dispatch("loadUser");

            return true;
        },
        async deletePicture({state, dispatch}, params) {
            const response = await fetch(URL + "user/@me/pictures/" + params, {
                method: "DELETE",
                cors: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": state.token
                },
            })

            // check if response is ok
            if(response.status !== 200) return false;

            dispatch("loadUser");

            return true;
        },

        logOut({ commit }) {
            commit('setToken', undefined);
            commit('setUser', undefined);
        },


        async register({state, dispatch}, params) {
            const formData = new FormData();

            formData.append("photo", params.photo);
            formData.append("data", JSON.stringify(params.data))

            const response = await fetch(URL + "user", {
                method: "POST",
                body: formData,
                cors: "cors"
            }).catch(error => {
                console.log(error);
            })

            return response.json();

            /* check if response is ok
            if (response.status !== 200) 
                return false;

            return true; */
        },

        async activate({}, token) {
            const response = await fetch(URL + "user/activate/" + token, {
                method: "POST",
                cors: "cors"
            })

            // check if response is ok
            if(response.status !== 200) return false;

            return true;
        },

        async getMySearches({state, commit}) {
            const response = await fetch(URL + "search/@me", {
                method: "GET",
                cors: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": state.token
                },
            })

            const data = (await response.json()).data;

            commit('setSearches', data.searches);
        },

        async patchSearch({state, dispatch}, data) {
            const response = await fetch(URL + "search/" + data.id, {
                method: "PATCH",
                body: JSON.stringify({
                    active: data.active
                }),
                cors: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": state.token
                },
            })

            // check if response is ok
            if(response.status !== 200) return false;

            dispatch("getMySearches");

            return true;
        },
        async deleteSearch({state, dispatch}, data) {
            const response = await fetch(URL + "search/" + data, {
                method: "DELETE",
                cors: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": state.token
                },
            })

            // check if response is ok
            if(response.status !== 200) return false;

            dispatch("getMySearches");

            return true;
        },

        async getStats({state, commit}) {
            const response = await fetch(URL + "stats", {
                method: "GET",
                cors: "cors",
                headers: {
                    "Content-Type": "application/json"
                },
            })

            const data = (await response.json()).data;

            commit('setStats', data);
        },

        async getUserStats({state, commit}) {
            const response = await fetch(URL + "stats/users", {
                method: "GET",
                cors: "cors",
                headers: {
                    "Content-Type": "application/json"
                },
            })

            const data = (await response.json()).data;

            return data;
        },

        async reportUser({state, commit}, data) {
            const response = await fetch(URL + `user/${data.reported}/report`, {
                method: "POST",
                cors: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": state.token
                },
                body: JSON.stringify({
                    details: data.details
                })
            })

            const json = (await response.json());
            const result = json.data;

            if(response.status !== 200 || json.status !== "success") return false;

            return true;
        }
    },
    getters: {
        facebookLoginUrl: () => {
            return URL + "auth/facebook";
        },
    },
    modules: {
    }
})
