import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import cs from 'vuetify/es5/locale/cs'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { cs: cs },
        current: 'cs',
    },
    icons: {
      iconfont: 'fa',
    },
    theme: {
      themes: {
        light: {
          green: '#91A913',
          blue: '#3F84D8'
        }
      }
    }
});
