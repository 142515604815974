import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Contact from '../views/static/Contact.vue'
import Terms from '../views/static/Terms.vue'

import Register from '../views/auth/Register.vue'
import Activation from '../views/auth/Activation.vue'
import Profile from '../views/profile/Profile.vue'
import ProfileEdit from '../views/profile/Edit.vue'

import ChatList from '../views/chat/ChatList.vue'
import SearchNew from '../views/search/SearchNew.vue'
import SearchList from '../views/search/SearchList.vue'
import History from '../views/history/History.vue'
import Settings from '../views/Settings.vue'

import ActivationResult from '../views/auth/ActivationResult.vue'
import Deletion from '../views/auth/Deletion.vue'

import SearchListLimited from '../views/search/SearchListLimited.vue'
import OAuth from '../views/auth/OAuth.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/kontakt',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/vseobecne-podminky',
    name: 'Terms',
    component: Terms
  },

  {
    path: '/registrovat-se',
    name: 'Register',
    component: Register
  },
  {
    path: '/aktivace',
    name: 'Activation',
    component: Activation
  },
  {
    path: '/aktivace/:token',
    name: 'ActivationResult',
    component: ActivationResult
  },

  {
    path: '/profil',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/profil/editace',
    name: 'ProfileEdit',
    component: ProfileEdit
  },
  {
    path: '/smazani/:token',
    name: 'Deletion',
    component: Deletion
  },

  {
    path: '/chaty',
    name: 'ChatList',
    component: ChatList
  },

  {
    path: '/hledani/nove',
    name: 'SearchNew',
    component: SearchNew
  },
  {
    path: '/hledani/list',
    name: 'SearchList',
    component: SearchList
  },
  {
    path: '/hledani/bez-prihlaseni',
    name: 'SearchListLimited',
    component: SearchListLimited
  },

  {
    path: '/historie',
    name: 'History',
    component: History
  },

  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },

  

  {
    path: '/auth/:token',
    component: OAuth
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
