import Vue from 'vue';
import VueI18n from 'vue-i18n';
import cs from './cs.json';
import en from './en.json';

Vue.use(VueI18n);

const messages = {
  en,
  cs
};

const i18n = new VueI18n({
    silentTranslationWarn: true,
    locale: 'cs', // Výchozí jazyk
    fallbackLocale: 'cs', // Jazyk pro fallback (pokud není dostupný překlad)
    messages
});

export default i18n;