<template>
    <v-container>
        <v-card class="text-center">
            <v-card-text>
                <div
                    class="
                        text-h4
                        mb-3
                        font-weight-bold
                        text-center
                        black--text
                    "
                >
                    {{ $t('Aktivate uživatelského účtu') }}
                </div>
                <p class="mb-0">
                    {{ $t('Na zadaný e-mail byl odeslán aktivační odkaz, na který stačí kliknout. Tím se Vám účet aktivuje a budete přihlášeni.') }}
                </p>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    mounted() {
    }
};
</script>