<template>
    <v-container>
        <v-card class="text-center">
            <v-card-text v-if="success">
                <div
                    class="
                        text-h4
                        mb-3
                        font-weight-bold
                        text-center
                        black--text
                    "
                >
                    {{ t('Smazání bylo úspěšné') }}
                </div>
                <p class="mb-0">
                    {{ t('Váš účet byl úspěšně smazán. Děkujeme za používání našich služeb.') }}
                </p>
            </v-card-text>
            <v-card-text v-else>
                <div
                    class="
                        text-h4
                        mb-3
                        font-weight-bold
                        text-center
                        black--text
                    "
                >
                    {{ t( 'Smazání se nezdařilo' ) }}
                </div>
                <p class="mb-0">
                    {{ t( 'Zadaný kód není platný nebo již byl použit, zkontrolujte odkaz v e-mailu.' ) }}
                </p>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    data: () => ({
        success: false,
    }),
    async mounted() {
        const token = this.$route.params.token;

        const response = await this.$store.dispatch('deleteUser', token);

        if(response) {
            this.$store.dispatch("logOut");
            this.$router.push({ name: "Home" });
        }

        this.success = response;
    }
};
</script>