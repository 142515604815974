<template>
    <v-container>
        <v-card class="text-center">
            <v-card-text v-if="success">
                <div
                    class="
                        text-h4
                        mb-3
                        font-weight-bold
                        text-center
                        black--text
                    "
                >
                    {{ $t('Aktivace byla úspěšná') }}
                </div>
                <p class="mb-0">
                    {{ $t('Aktivace byla úspěšná, nyní se můžete přihlásit.') }}
                </p>
            </v-card-text>
            <v-card-text v-else>
                <div
                    class="
                        text-h4
                        mb-3
                        font-weight-bold
                        text-center
                        black--text
                    "
                >
                {{ $t('Aktivace se nezdařila') }}
                </div>
                <p class="mb-0">
                    {{ $t('Zadaný kód není platný nebo již byl použit, zkontrolujte odkaz v e-mailu.') }}
                </p>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    data: () => ({
        success: false,
    }),
    async mounted() {
        const token = this.$route.params.token;

        const response = await this.$store.dispatch('activate', token);

        if(response) {
            this.$root.$emit("openLoginDialog");
        }

        this.success = response;
    }
};
</script>