<template>
    <v-app>
        <v-app-bar id="esy-appbar" app flat dense clipped-left clipped-right color="white">
            <!--
            <v-btn style="margin-left: 0;" icon small @click="nav = true"
                   v-if="$vuetify.breakpoint.smAndDown && user != undefined">
                <v-icon small style="font-size: 24px;">fal fa-bars</v-icon>
            </v-btn> -->

            <router-link class="text-subtitle-1 text-decoration-none black--text flex"
                         :to="{ name: 'Home' }">
                <img src="/img/logo.png" alt="" style="height: 42px; width: auto;" />
            </router-link>

            <v-app-bar-title class="d-none d-sm-inline"></v-app-bar-title>

            <v-spacer></v-spacer>

            <!--v-btn icon small class="mr-1">
                <v-icon small>fal fa-flag</v-icon>
            </v-btn-->

            <v-btn v-if="$vuetify.breakpoint.mdAndUp" plain :to="{ name: 'Home' }" exact>
                <v-img src="/img/icon/uvod.png" class="mr-0 mr-sm-0" max-height="24px" contain></v-img>

                <span class="d-none d-sm-inline">
                    {{ $t('Úvod') }}
                </span>
            </v-btn>

            <log-in/>
            <log-out v-show="$vuetify.breakpoint.mdAndUp"/>
            <profile-button/>
        </v-app-bar>

        <v-navigation-drawer v-if="user" :permanent="$vuetify.breakpoint.mdAndUp" app v-model="nav" clipped>
            <div class="d-flex align-center" style="height: 100%; width: 100%">
                <v-list dense style="width: 100%" dark color="#000000cc" class="nav-list">
                    <v-list-item link exact :to="{ name: 'SearchNew' }" class="py-2">
                        <v-list-item-content>
                            <v-list-item-title><span style="line-height: 1;">{{ $t('Přidat hledání') }}</span></v-list-item-title>
                            <v-list-item-subtitle>
                                {{ $t('Zadat nové hledání') }}
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-icon>
                            <img src="/img/icon/lupa.png" alt="">
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item link exact :to="{ name: 'SearchList' }" class="py-2">
                        <v-list-item-content>
                            <v-list-item-title><span style="line-height: 1;">{{ $t('Kdo koho hledá') }}</span></v-list-item-title>
                            <v-list-item-subtitle>
                                {{ $t('Přehled zadaných hledání') }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <img src="/img/icon/palec.png" alt="">
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item link exact :to="{ name: 'History' }" class="py-2">
                        <v-list-item-content>
                            <v-list-item-title><span style="line-height: 1;">{{ $t('Historie') }}</span></v-list-item-title>
                            <v-list-item-subtitle>
                                {{ $t('Historie mých hledání') }}
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-icon>
                            <img src="/img/icon/hodiny.png" alt="">
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item link exact :to="{ name: 'ChatList' }" class="py-2">
                        <v-list-item-content>
                            <v-list-item-title><span style="line-height: 1;">{{ $t('Vzkazy') }}</span></v-list-item-title>
                            <v-list-item-subtitle>
                                {{ $t('Prohlížení mé pošty') }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-badge color="green" :content="'' + user.totalUnread" :value="user.totalUnread" overlap>
                                <img src="/img/icon/obalka.png" alt="">
                            </v-badge>
                        </v-list-item-icon>
                    </v-list-item>

                    <v-list-item v-if="$vuetify.breakpoint.xsAndUp" link exact :to="{ name: 'Settings' }" class="py-2">
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('Nastavení') }}</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ $t('Úprava chování aplikace') }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
    
                        <v-list-item-icon>
                            <img src="/img/icon/kolo.png" alt="">
                        </v-list-item-icon>
                    </v-list-item>
                </v-list>
            </div>
        </v-navigation-drawer>

        <v-main id="main-wrapper">
            <router-view class="main"/>
        </v-main>

        <Notifications/>

        <v-footer class="pa-0 main d-none d-sm-flex">
            <v-card flat dark tile width="100%" class="text-center">
                <v-card-text>
                    <v-row align-content="center" justify-content="space-between">
                        <v-col class="
                                d-flex
                                flex-column flex-sm-row
                                align-center
                                text-center text-sm-left
                                mb-0
                                py-0
                            " style="gap: 10px" cols="12" sm="7">
                            <img src="/img/fb-footer.png" alt="" class="fb">
                            <p class="mb-0">{{ $t('Uživatelů celkem') }}: <b>{{ stats.totalUsers }}</b></p>
                            <p class="mb-0">{{ $t('Uživatelů online') }}: <b>{{ stats.activeUsers }}</b></p>
                            <p class="mb-0">
                                {{ $t('Zadaných požadavků') }}: <b>{{ stats.totalSearches }}</b>
                            </p>
                        </v-col>
                        <v-col class="
                                justify-center justify-md-end
                                d-flex
                                mb-0
                                flex-wrap
                                py-0
                            " style="gap: 5px" cols="12" sm="5">
                            <img :src="`/img/flags/${languages[currentLanguage == languages.length - 1 ? 0 : currentLanguage + 1]}-alt.svg`" class="lang-picker"
                                 style="height: 24px; width: 24px;" @click="changeLanguage()"
                                 alt="">
                            <!-- <v-btn x-small text plain disabled>{{ $t('Nápověda') }}</v-btn> -->
                            <v-btn x-small text plain :to="{ name: 'Terms' }">{{ $t('Všeobecné podmínky') }}</v-btn>
                            <v-btn x-small text plain :to="{ name: 'Contact' }">{{ $t('Kontakt') }}</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-footer>
    </v-app>
</template>

<script>
import LogIn from "./components/layout/LogIn.vue";
import LogOut from "./components/layout/LogOut.vue";
import ProfileButton from "./components/layout/ProfileButton.vue";
import Notifications from "./components/notifications/Notifications.vue";
import {SafeArea} from 'capacitor-plugin-safe-area';
import {StatusBar, Style} from '@capacitor/status-bar';
import {Device} from '@capacitor/device';
import { App } from '@capacitor/app';

export default {
    components: {LogIn, LogOut, ProfileButton, Notifications},
    name: "App",

    watch: {
        "$vuetify.breakpoint.smAndUp": function (smAndUp) {
            if (smAndUp && !this.nav) this.nav = true;
        },
        "$route": function () {
            this.checkRoute();
        },
    },

    computed: {
        user() {
            return this.$store.state.user;
        },

        stats() {
            return this.$store.state.stats;
        },
    },

    data: () => ({
        nav: false,
        languages: [
            "cs", "en"
        ],
        currentLanguage: 0
    }),

    async created() {
        App.addListener('backButton', () => {
            console.log('Back button from APP!');
            if (this.$route.name == 'ChatList')
                return;

            console.log('Vracím se zpátky na HOME z APP!, routa je: ', this.$route.name);
            window.history.back();
        });

        // Při načtení komponenty zkontrolujte, zda je v localStorage uložený jazyk
        const savedLanguage = localStorage.getItem('language');

        if (savedLanguage) {
            this.$i18n.locale = savedLanguage;
            this.currentLanguage = this.languages.indexOf(savedLanguage);
        } else {
            const deviceLang = await Device.getLanguageCode();
            this.currentLanguage = this.languages.indexOf(deviceLang.value) != -1 ? this.languages.indexOf(deviceLang.value) : 'en';
        }
    },

    async mounted() {
        try {
            await StatusBar.setStyle({style: Style.Light});
        } catch (err) {
            console.warn('Status bar not supported. ' + err)
        }

        function setInsets(insets) {
            console.log(insets);
            const body = document.querySelector('body');
            body.style.marginTop = `${insets.top}px`;

            if (insets.top > 0)
                body.classList.add('mobile-insets');

            const wrap = document.querySelector('.v-main');
            wrap.style.height = `calc(100vh - ${insets.top}px)`;

            const esyAppbar = document.getElementById('esy-appbar');
            esyAppbar.style.marginTop = `${insets.top}px`;

            var css = document.createElement('style');
            css.innerHTML = `.v-dialog--fullscreen { top: ${insets.top}px; height: calc(100% - ${insets.top}px); }`;
            document.head.appendChild(css);
        }

        //let insets = {"top":47,"right":0,"bottom":34,"left":0};
        //setInsets(insets);
    
        const os = await Device.getInfo();

        if (os.platform == 'ios')
            SafeArea.getSafeAreaInsets().then(({ insets }) => {
                setInsets(insets);
            });

        this.$store.dispatch("loadUser");
        this.checkRoute();

        this.$store.dispatch("getStats");
        setInterval(() => {
            this.$store.dispatch("getStats");
            this.$store.dispatch("loadUser");
        }, 10000);
    },

    methods: {
        // Funkce pro získání jazyka zařízení
        async getDeviceLanguage() {
            const deviceInfo = await Device.getInfo();
            const {language} = deviceInfo;
            return language;
        },
        checkRoute() {
            const allowedRoutes = ["Home", "Register", "Activation", "ActivationResult", "SearchListLimited", "Contact", "Terms"];
            const name = this.$route.name;

            const token = this.$store.state.token;

            // TODO: check if token is valid

            if (!token && !allowedRoutes.includes(name)) {
                this.$router.push({name: "Home"});
            }
        },
        changeLanguage() {
            this.currentLanguage = this.currentLanguage == this.languages.length - 1 ? 0 : this.currentLanguage + 1;
            let selected = this.languages[this.currentLanguage];

            this.$i18n.locale = selected;
            localStorage.setItem('language', selected);
        }
    }
};
</script>

<style lang="scss">
.response-button span {
    color: white !important;
}

html {
    margin-top: var(--safe-area-top);
}

@media only screen and (max-width: 992px) {
    .v-application--wrap {
        min-height: 0 !important;
    }

    html, body {
        overflow-y: hidden;
    }

    .v-application--wrap {
        display: block !important;
    }

    /*
    #main-wrapper {
        height: calc(100vh); // ZMENA: calc(100vh - 48px)
    } */
}


a[href^="http://maps.google.com/maps"] {
    display: none !important
}

a[href^="https://maps.google.com/maps"] {
    display: none !important
}

.esy-expansion-panels {
    box-shadow: none;
    border: 1px solid #2C3032;

    .v-expansion-panel::before {
        box-shadow: none;
    }

    .v-expansion-panel button {
        text-transform: none;
    }

    .v-expansion-panel button span {
        font-weight: bold;
    }
}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display: none;
}

.gmnoprint div {
    background: none !important;
}

.theme--light.v-footer.main {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #000000c7;

    > .v-card {
        background-color: transparent;
    }

    .fb {
        height: 20px;
    }

    .v-btn {
        text-transform: none;
        font-size: 13px
    }

    .v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content {
        opacity: 0.95 !important;
    }

    .v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):hover .v-btn__content {
        color: #e7e7e7 !important;
    }

    .theme--dark.v-card > .v-card__text {
        color: white !important;
    }
}

:not(.v-footer).main {
    min-height: calc(100vh - 48px);
}

.v-footer.main {
    z-index: 3000;
}

/*
body {
    background: #E0DC90, url("/img/bg.png") !important;
    background-size: cover !important;
    background-position: center;
    background-repeat: no-repeat;
} */

.v-main__wrap {
    padding-left: 0px !important;
    //padding-bottom: 50px;
}

.v-application {
    background: transparent !important;
}

.v-toolbar__content a {
    display: flex;
    align-items: center;
}

.v-toolbar__content a img {
    width: 170px;
    height: auto;
}

.nav-list {
    padding: 0 !important;
}

.nav-list .v-list-item {
    padding-right: 24px !important;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.nav-list.v-list--dense .v-list-item .v-list-item__icon {
    align-self: center;
    height: auto;

    img {
        width: 39px;
        height: auto;
    }
}

.nav-list.v-list--dense .v-list-item .v-list-item__title {
    font-weight: bold;
    font-size: 1.3rem;
}

.nav-list.v-list .v-list-item--active {
    background-color: white;
    color: black;

    .v-list-item__icon img {
        filter: hue-rotate(90deg) brightness(10%) grayscale(10%);
    }
}

.nav-list .theme--dark.v-list-item--active::before {
    opacity: 0;
}

.nav-list .theme--dark.v-list-item--active:hover::before {
    opacity: 0.09;
}

.nav-list .theme--dark.v-list-item--active.v-list-item:not(.v-list-item--disabled) .v-list-item__subtitle {
    color: black;
}

.theme--light.v-navigation-drawer {
    background-color: transparent !important;
}

.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
    background-color: transparent !important;
}

@media (max-width: 992px) {
    .v-main__wrap {
        padding-left: 0 !important;
    }

    .theme--light.v-navigation-drawer {
        background-color: black !important;
    }

    body {
        background: white !important;
    }

    :not(.v-footer).main {
        min-height: auto !important;
    }
}


.v-text-field__slot {
    font-weight: 900;
}


.v-application .v-avatar.user.woman {
    background-color: rgb(40, 54, 80) !important;
    background-image: url("/img/woman.png");
    background-size: cover;
    background-position: center;
}


.v-application .v-avatar.user.man {
    background-color: rgb(40, 54, 80) !important;
    background-image: url("/img/man.png");
    background-size: cover;
    background-position: center;
}

#esy-appbar .v-toolbar__content {
    background: white;
}

.lang-picker:hover {
    cursor: pointer;
}
</style>