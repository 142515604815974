<template>
  <v-container class="homepage">
    <!-- Přihlášen, nová HP -->
    <div class="d-flex align-center" style="height: 100%; width: 100%; flex-direction: column; align-items: center; justify-content: center;" v-if="user && $vuetify.breakpoint.smAndDown">
        <router-link :to="{ name: 'Profile' }">
            <v-avatar
                style="width: 128px; height: 128px; margin-bottom: 12px; border: 2px solid white;"
                >
                <v-img
                    class="user"
                    :class="{
                    woman: user.sex === 'female',
                    man: user.sex !== 'female',
                    }"
                    :src="getPictureSource(user.profilePicture, user)"
                ></v-img>
            </v-avatar>
        </router-link>
        <router-link style="text-decoration: none;" :to="{ name: 'Profile' }"><div style="margin-bottom: 24px; font-size: 20px; font-weight: bold; color: black; text-decoration: none;">{{ getUserName() }}</div></router-link>
        
        <v-list dense style="width: 100%; border-radius: 4px;" dark color="#000000cc" class="nav-list">
            <v-list-item link exact :to="{ name: 'SearchNew' }" class="py-2">
                <v-list-item-content>
                    <v-list-item-title><span style="line-height: 1;">{{ $t('Přidat hledání') }}</span><!-- Přidat hledání --></v-list-item-title>
                    <v-list-item-subtitle>
                        {{ $t('Zadat nové hledání') }}
                    </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                    <img src="/img/icon/lupa.png" alt="">
                </v-list-item-icon>
            </v-list-item>
            <v-list-item link exact :to="{ name: 'SearchList' }" class="py-2">
                <v-list-item-content>
                    <v-list-item-title><span style="line-height: 1;">{{ $t('Kdo koho hledá') }}</span></v-list-item-title>
                    <v-list-item-subtitle>
                        {{ $t('Přehled zadaných hledání') }}
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                    <img src="/img/icon/palec.png" alt="">
                </v-list-item-icon>
            </v-list-item>
            <v-list-item link exact :to="{ name: 'History' }" class="py-2">
                <v-list-item-content>
                    <v-list-item-title><span style="line-height: 1;">{{ $t('Historie') }}</span></v-list-item-title>
                    <v-list-item-subtitle>
                        {{ $t('Historie mých hledání') }}
                    </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                    <img src="/img/icon/hodiny.png" alt="">
                </v-list-item-icon>
            </v-list-item>
            <v-list-item link exact :to="{ name: 'ChatList' }" class="py-2">
                <v-list-item-content>
                    <v-list-item-title><span style="line-height: 1;">{{ $t('Vzkazy') }}</span></v-list-item-title>
                    <v-list-item-subtitle>
                        {{ $t('Prohlížení mé pošty') }}
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                    <v-badge color="green" :content="'' + user.totalUnread" :value="user.totalUnread" overlap>
                        <img src="/img/icon/obalka.png" alt="">
                    </v-badge>
                </v-list-item-icon>
            </v-list-item>

            <v-list-item link exact @click="sheet = true" class="py-2">
                <v-list-item-content>
                    <v-list-item-title><span style="line-height: 1;">{{ $t('Nastavení') }}</span></v-list-item-title>
                    <v-list-item-subtitle>
                        {{ $t('Úprava chování aplikace') }}
                    </v-list-item-subtitle>
                </v-list-item-content>
 
                <v-list-item-icon>
                    <img src="/img/icon/kolo.png" alt="">
                </v-list-item-icon>
            </v-list-item>
        </v-list>

        <!-- Card -->
        <v-bottom-sheet v-model="sheet">
           <div style="width: 100%; height: 100%; background: white; padding: 24px;">
                <span style="line-height: 1;" class="text-h6 font-weight-black">{{ $t('Nastavení') }}</span>
            
                <v-select
                    class="mt-2"
                    v-model="selectedLanguage"
                    :items="languages"
                    item-text="text"
                    item-value="value"
                    :label="$t('Jazyk')"
                    @change="changeLanguage"
                ></v-select>
                <v-btn text style="width: 100%; border: 1px solid black; color: black; margin-top: 12px;" :to="{ name: 'Terms' }">{{ $t('Všeobecné podmínky') }}</v-btn>
                <v-btn text  style="width: 100%; border: 1px solid black; color: black; margin-top: 12px;" :to="{ name: 'Contact' }">{{ $t('Kontakt') }}</v-btn>
                <v-btn text  style="width: 100%; border: 1px solid red; color: red; margin-top: 12px;" @click="logOut">{{ $t('Odhlásit se') }}</v-btn>
           </div>
        </v-bottom-sheet>
    </div>


    <!-- Nepřihlášen, první homepage -->
    <div v-if="!user || !$vuetify.breakpoint.smAndDown">
        <p class="text-center font-weight-black esy-text-heading" style="font-size: 32px; margin: 24px 32px;">
            {{ $t('Zakoukali jste se do sebe v tramvaji, autobusu nebo na ulici, ale nebyla příležitost na seznámení?') }}
        </p>

        <p class="esy-text-heading text-center font-weight-black mt-5" style="font-size: 32px;">
            {{ $t('Zkuste se znovu najít tady...') }}
        </p>

        <v-row v-if="user === undefined" class="mb-3 buttons" style="font-size: 32px;">
        <v-col cols="12" class="d-flex justify-center">
            <v-btn class="red" :to="{ name: 'SearchListLimited' }"
            >{{ $t('Prohlížet bez registrace') }}</v-btn>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
            <v-btn class="green" :to="{ name: 'Register' }" dark
            >{{ $t('Registrovat se') }}</v-btn
            >
        </v-col>
        <v-col cols="12" class="text-center py-0 font-weight-bold"> {{ $t('nebo') }} </v-col>
        <v-col cols="12" class="d-flex justify-center">
            <v-btn color="#37639A" dark @click="facebook" :loading="loading"
            >{{ $t('Přihlásit se přes facebook') }}</v-btn
            >
        </v-col>
        </v-row>

        <p class="text-subtitle-1 font-weight-bold text-center" style="margin: 0 32px;">

            {{ $t('Pokud byly sympatie oboustranné, zde máte šanci znovu se potkat. Stačí se jen zaregistrovat a zadat požadavek na hledání, nebo zkontrolovat na mapě místo vašeho setkání, zda Vás již protějšek hledá.') }}

        </p>
        <p class="text-subtitle-1 font-weight-bold text-center">
            {{ $t('Funguje to vlastně jako inzerát...') }}
        </p>
        <p class="text-subtitle-1 font-weight-bold text-center" style="margin: 0 56px;">
            {{ $t('Už nemusíte jezdit metrem stále ve stejnou dobu, abyste opět potkali toho, kdo se vám tam již dlouho líbí.') }}
        </p>
        <p class="text-h6 font-weight-bold text-center" style="margin-top: 24px;">
            {{ $t('Zkuste ho kontaktovat zde!') }}
        </p>

        <v-row class="mt-5" no-gutters>
        <v-col cols="6" class="pa-1">
            <div
            class="image"
            :class="{ large: large == 'i-1' }"
            @click.capture="large = large != undefined ? undefined : 'i-1'"
            >
            <div class="close-holder">
                <v-img src="/img/komiks_park-1.png" contain></v-img>

                <v-btn
                    v-if="large == 'i-1'"
                    fab
                    dark
                    @click.capture="large = undefined"
                    class="close esy-close-button"
                    style="width: 42px; height: 42px;"
                >
                <v-icon>fal fa-times</v-icon>
                </v-btn>
            </div>
            </div>
        </v-col>
        <v-col cols="6" class="pa-1">
            <div
            class="image"
            :class="{ large: large == 'i-2' }"
            @click.capture="large = large != undefined ? undefined : 'i-2'"
            >
            <div class="close-holder">
                <v-img src="/img/komiks_tramvaj-1.png" contain></v-img>

                <v-btn
                v-if="large == 'i-2'"
                fab
                dark
                @click.capture="large = undefined"
                class="close esy-close-button"
                style="width: 42px; height: 42px;"
                >
                <v-icon>fal fa-times</v-icon>
                </v-btn>
            </div>
            </div>
        </v-col>
        </v-row>

        <div style="background: url('/img/video-1.png'); padding: 20px; margin-top: 32px; margin-bottom: 76px;">
            <iframe
                style="width: 100%; height: 300px;"
                src="https://www.youtube.com/embed/sboqbKBHNwQ?si=BEOgr0gdKymmcKur"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>
        </div>
  

        <!--
        <v-col cols="12" sm="6" class="pa-1">
            <div
            class="video v-2"
            :class="{ large: large == 'v-2' }"
            @click.capture="large = large != undefined ? undefined : 'v-2'"
            >
            <div class="close-holder">
                <iframe
                width="100%"
                height="auto"
                src="https://www.youtube.com/embed/XVO9CS8D4hQ"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                ></iframe>

                <v-btn
                v-if="large == 'v-2'"
                large
                fab
                dark
                @click.capture="large = undefined"
                class="close esy-close-button"
                style="width: 42px; height: 42px;"
                >
                <v-icon>fal fa-times</v-icon>
                </v-btn>
            </div>
            </div>
        </v-col> -->

        <searching />
    </div>
  </v-container>
</template>

<script>
import Searching from '../components/layout/Searching.vue';
import { URL } from "@/store/index";

export default {
  components: { Searching },
  name: "Home",

  data() {
    return {
      loading: false,
      sheet: false,
      large: undefined,

      languages: [
            { text: 'Čeština', value: 'cs' },
            { text: 'English', value: 'en' },
            // Add more languages as needed
        ],
        selectedLanguage: '',
        currentLanguage: 0
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  mounted() {
  },

  created() {
    // Set the initial value of selectedLanguage from localStorage
    const storedLanguage = localStorage.getItem('language');
    this.selectedLanguage = storedLanguage ? storedLanguage : this.languages[0].value;
  },

  methods: {
    facebook() {
      this.loading = true;
      window.location.href = this.$store.getters["facebookLoginUrl"];
    },
    getPictureSource(picture, user) {
        if (picture == undefined || picture.length == 0)
            return user.sex == 'female' ? '/img/woman.png' : '/img/man.png';

        return URL + picture;
    },
    changeLanguage() {
      this.$i18n.locale = this.selectedLanguage;
      localStorage.setItem('language', this.selectedLanguage);
    },
    logOut() {
        this.$store.dispatch("logOut");
        this.$router.push({ name: "Home" });
    },
    getUserName() {
        /*
        if (this.user.firstName != '-' && this.user.lastName != '-')
            return `${this.user.firstName} ${this.user.lastName}`;

        if (this.user.firstName != '-')
            return this.user.firstName;

        if (this.user.lastName != '-')
            return this.user.lastName; */

        return this.user.nickName;
    }
  },
};
</script>

<style>

body {
    background: url("/img/bg-home.png") no-repeat center top;
}


@media only screen and (max-width: 600px) {
    .esy-text-heading {
        font-size: 24px !important;
    }

    .homepage.container {
        width: 100% !important;
    }

    .v-1 {
        background-size: cover;
    }
}
</style>

<style lang="scss" >
.v-bottom-sheet {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}

@media only screen and (max-width: 992px) {
    .container.homepage.main {
        background: url("/img/bg-home.png") no-repeat center top;
    }

    .esy-close-button {
        width: 42px !important;
        height: 42px !important;
    }

    .esy-close-button i {
        font-size: 24px !important;
    }

    .homepage {
        overflow-y: scroll;
        height: calc(100vh - 48px) !important;

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background: #f3f3f3;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #7a9704;
            border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
}

.homepage {
  &.container {
    width: 600px;
  }
  
.buttons .v-btn {
  outline: 1px solid #fff;
  color: white;
  width: 250px;
  height: 50px !important;
}

.v-btn.close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;

  transform: translate(50%, -50%);
}

.close-holder {
  position: relative;
  width: 100%;
  height: 100%;
}

.image {
  cursor: pointer;

  &.large {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    > .close-holder {
      width: 80%;
      height: 80%;

      > .v-image {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.video {
  cursor: pointer;
  width: 100%;
  min-height: 180px;
  padding: 20px;

  &.large {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    > .close-holder {
      width: 80%;
      height: 80%;
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  &:not(.large).v-1 {
    background: url("/img/video-1.png") no-repeat center top;
    width: 100%;
    height: 100%;
    background-size: contain;
  }
  &:not(.large).v-2 {
    background: url("/img/video-2.png") no-repeat center top;
    width: 100%;
    height: 100%;
    background-size: contain;
  }
}
}
</style>