<template>
    <v-dialog v-if="user === undefined" v-model="dialog" transition="dialog-bottom-transition" max-width="500px">
        <template v-slot:activator="{ on, attrs }">
            <a v-bind="attrs" v-on.prevent="on" class="reset" style="margin-bottom: 8px; display: block;"> {{ $t('Zapomněl/a jsi heslo?') }}</a>
        </template>
        <v-card dark class="resetdialog">
            <v-card-title>{{ $t('Přihlášení') }}</v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="E-mail" v-model="email" outlined required :disabled="sent">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="sent">
                            <p>{{ $t('Na e-mail ti byl zaslán kód. Ten prosím opiš sem.') }}</p>
                            <v-text-field label="Kód z e-mailu" v-model="code" outlined required :disabled="reseted">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green" text @click="reset()" :loading="loading">
                    {{ $t('Odeslat') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
const { URL } = require("../../store/index");

export default {
    data() {
        return {
            dialog: false,
            sent: false,
            loading: false,
            reseted: false,

            email: "",
            code: "",
        };
    },

    computed: {
        user() {
            return this.$store.state.user;
        },
    },

    methods: {
        async reset() {
            this.loading = true;

            if (this.sent) {
                const response = await fetch(URL + "auth/reset", {
                    method: "POST",
                    cors: "cors",
                    body: JSON.stringify({
                        email: this.email,
                        code: this.code,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                })

                if (response.status === 200) {
                    this.reseted = true;
                    
                    this.$root.$emit('notification', {
                        message: "Heslo bylo úspěšně změněno. Na e-mail ti bylo zasláno nové heslo a můžeš se přihlásit.",
                        duration: 2
                    });
                    this.loading = false;
                }
            } else {
                const response = await fetch(URL + "auth/reset", {
                    method: "POST",
                    cors: "cors",
                    body: JSON.stringify({
                        email: this.email
                    }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                })

                if (response.status === 200) {
                    this.sent = true;
                    this.loading = false;
                }
            }
        },
    },
};
</script>

<style>
a.reset {
    color: #fff !important;
    text-decoration: underline;
}
.resetdialog {
    background-color: #1e1e1e !important;
}
</style>