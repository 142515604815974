<template>
    
</template>

<script>
export default {
    mounted() {
        // get parameter "token" and authenticate user
        const token = this.$route.params.token;

        if (token) {
            this.$store.dispatch("setToken", token);
            this.$store.dispatch("loadUser");

            this.$router.push({ name: 'Home' });
        }
    }
};
</script>